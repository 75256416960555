.Planner_timeline {
    padding: .5rem;
    color: var(--optiontext);
    transition: all var(--css-duration);

    display: flex;
    flex-direction: row;
    align-items: center;
}
.Planner_timeline .col1 {
    min-width: 10rem;
    max-width: 10rem;
}
.Planner_timeline .col2 {
    min-width: 8rem;
    max-width: 8rem;
}

.Planner_timeline .subScroll {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
}

.Planner_timeline .subScroll::-webkit-scrollbar {
    display: none;
}
