.form {
    padding: 10px;
    width: 100%;
    // height: 50%;
    overflow-y: auto;

    .tabList {
        background: rgba(0, 0, 0, 0.6);
        color: white;
    }

    .row {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        background: rgba(0, 0, 0, 0.2);
        padding: 5px;

        .name {
            font-size: 1.5rem;
        }
        input {
            width: 70%;
            padding: 5px;
        }
    }

    .readings {
        display: flex;
        flex-direction: column;
        .payloadFieldform {
            padding: 5px;
            display: flex;
            flex-direction: column;
            color: white;

            .row {
                align-items: center;
                background: chocolate;
                .name {
                    font-size: 1rem;
                }
                input {
                    font-size: 1rem;
                }
            }
        }
        .payloadField {
            padding: 5px;
            background: grey;
            color: white;
            cursor: pointer;
        }
    }
}