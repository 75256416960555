.doorcontact {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.doorcontact .icon {
    display: flex;
    align-content: center;
    justify-content: center;

    font-size: 4rem;
    opacity: 0.7;
    filter: drop-shadow(0 5px 3px rgba(0, 0, 0, 0.5));
}
.doorcontact .txtDisplay {
    width: 14rem;
    padding: 10px;
    border-radius: 10px;
    font-size: 1.5rem;
    letter-spacing: 2px;
    color: white;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    text-align: center;

    transition: all 0.5s;
}
.doorcontact .txtDisplay.open {
    background: chocolate;
}
.doorcontact .txtDisplay.close {
    background: rgb(0, 140, 255);
}