.Toilet_floor_plan_bin,
.Toilet_floor_plan_soap {
    position: absolute;
    transform: translate(-1.5rem, -1.5rem);
    width: 3rem;
    height: 3rem;
    background: white;
    color: rgb(135, 135, 135);
    filter: drop-shadow(0 10px 3px rgba(0, 0, 0, 0.3));
    border-radius: 0.3rem;
    font-size: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Toilet_floor_plan_bin .BinFull {
    background: linear-gradient(to bottom, transparent 5%, red 5%);
    min-width: 100%;
    min-height: 2rem;
    position: absolute;
    mix-blend-mode:overlay;
}
.Toilet_floor_plan_bin .binempty {
    background: linear-gradient(to bottom, rgb(120, 120, 120) 60%, rgb(1, 196, 118) 60%);
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    mix-blend-mode:overlay;
}
.Toilet_floor_plan_bin_detail {
    font-size: 1rem;
    text-align: center;
    white-space:normal;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    animation: HoverDetailAnimation 0.3s ease-in-out;

    display: flex;
    flex-direction: column;

    z-index: 100;
}
@keyframes HoverDetailAnimation {
    0% {
        opacity: 0;
        transform: translate(2rem, 0);
    }
    100% {
        opacity: 1;
    }
}

.Toilet_floor_plan_soap_wave {
    min-width: 100%;
    min-height: 2rem;
    position: absolute;
    mix-blend-mode:overlay;
}

.flushwater_position {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flushwater_position .fa {
    position: absolute;
}
.flushwater_position .fa.hover {
    font-size: 1.5rem;
    margin-left: 1rem;
    margin-top: 1rem;
}