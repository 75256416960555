.Demo3D_Container
{
    width: 100%;
    background: transparent;
    height: calc(100% - 0.5rem);
    margin-top: -2rem;
    border-radius: 0.5rem;
}

.viewerSelecter
{
    margin:auto;
    margin-left: 1rem;
    border-radius: 9999rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background: var(--colortheme1);
    color: white;
    opacity: 0.5;
}

.Demo3D_topGrad
{
    position: fixed;
    top: 2.5rem;
    width: 100%;
    height: 4rem;
    background: linear-gradient(to bottom, rgb(182, 182, 182), rgb(255, 255, 255));
}
.Demo3D_botGrad
{
    position: fixed;
    bottom: 0rem;
    width: 100%;
    height: 4rem;
    background: linear-gradient(to top, rgb(182, 182, 182), rgb(240, 240, 240));
}
.Demo3D_sideGrad
{
    position: fixed;
    right: 0rem;
    margin-top: -8rem;
    width: 100%;
    height: 4rem;
    background: linear-gradient(to top, rgb(240, 240, 240), transparent);
}
.Demo3D_rightGrad
{
    position: fixed;
    right: 0rem;
    margin-top: -12rem;
    width: 8rem;
    height: 8rem;
    background: rgb(202, 202, 202);
    font-size: 1rem;
    color:grey;
}
.Demo3D_row:hover
{
    color:var(--focuscardbackground);
    background: rgba(255, 255, 255, 0.9);
}
.Demo3D_row
{
    display: flex;
    flex-direction: row;
    padding: 0.4rem;
    transition: all var(--css-duration);
    flex-wrap: nowrap;
}
.Demo3D_txt
{
    margin-left: 0.5rem;
}
.Demo3D_icon
{
    width: auto;
    height: 1.2rem;
}