.wrap {
    display: flex;
    flex-direction: row;
    min-width: 10rem;
    max-width: 10rem;
}
.PRD_TableRow {
    padding: 1rem .5rem;
    color: var(--optiontext);
    transition: all var(--css-duration);
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
}
.PRD_TableRow:hover {
    box-shadow: 0 -1000px 0 0 rgba(0,0,0,.1) inset;
}
.PRD_TableRow .col1 {
    min-width: 8rem;
    max-width: 8rem;
    margin-left: .8rem;
    margin-right: .8rem; 
}
.PRD_TableRow .col2 {
    min-width: 12rem;
    max-width: 12rem;
    margin-left: .8rem;
    margin-right: .8rem;  
}
.PRD_TableRow .col3 {
    width: 100%; 
}
.loadingColor {
    background: transparent;
    color: transparent;
}

.PRD_TableRow .detailBtn{
    min-width: 1rem;
    max-width: 1rem;
    background: rgb(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
    transition: all var(--css-duration);
}
