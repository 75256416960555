.dragLayout2_container {
    width: 100%;

    .emptyWidget {
        background: grey;
        color: white;

        width: 100%;
        height: 100%;
    }
}

.lockBtn {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    background: grey;
    color: white;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
}