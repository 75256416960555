.OperOver_searchBar {
    display: flex;
    margin: .5rem;
    margin-top: 0;
    color: var(--optiontext);
    /* background: rgba(255, 255, 255, .2); */
}
.OperOver_searchBar .searchTxt {
    opacity: .7;
    margin-right: .5rem;
}
.OperOver_searchBar .inputfield {
    border-radius: .3rem;
    background: rgba(255, 255, 255, .4);
    width: 22rem;
}