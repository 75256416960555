.resetPwdContainer {
    position: fixed;
    top: 30vh;
    left: calc((100vw - 35rem)/2);
    width: 35rem;
    height: 40vh;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .2), 
    rgba(0, 0, 0, 0.1), rgba(255, 255, 255, .1));
    color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    transition: ease-out var(--css-duration);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    backdrop-filter: blur(20px);

    animation: .3s resetmainopen ease-out;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .3);
}
@keyframes resetmainopen {
    0% {
        opacity: 0;
        transform: translateY(-20px) scale(.7);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
.resetPwdCloseBtn {
    position: absolute;
    right: 0.5rem;
    top: 1rem;
    cursor: pointer;
    transition: all var(--css-duration);
    padding: 0.5rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    border-radius: 0.3rem;
}

.resetPwdHeader {
    font-size: 1.5rem;
    /*margin-top: 0.8rem;*/
    margin-left: 1rem;
}
.resetPwdInputContainer {
    display: flex;
    flex-direction: column;
    border-radius: 0.3rem;
    font-size: 1.3rem;
}

.resetPwdInput {
    margin-bottom: 1rem;
    padding: 0.5rem;
    padding-left: 1.5rem;
    border-radius: 0.5rem;
    background: rgba(255, 255, 255, .5);
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2) inset;
    color: black;
    transition: all var(--css-duration);
    opacity: 0.7;
}
.resetPwdInput:focus {
    /* box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 -50px 0 0 rgba(0, 0, 0, .3) inset; */
    opacity: 1;
}
.resetPwdBtnContainer {
    background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.resetPwdSubmitBtn {
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 0.5rem;
    color: white;
    background: var(--colortheme1);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
    font-size: 1.2rem;
    text-align: center;
    width: 10rem;
    transition: all var(--css-duration);
}
.resetPwdSubmitBtn:hover {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3), 0 -50px 0 0 var(--focuscardbackground) inset;
    border-color: var(--focuscardbackground);
}
.resetPwdCancelBtn {
    cursor: pointer;
    background: transparent;
    color: var(--colortheme1);
    border: solid 1px var(--colortheme1);
    padding: 0.5rem;
    width: 10rem;
    text-align: center;
    margin-left: 0.5rem;
    border-radius: 0.5rem;
    transition: var(--css-duration);
    /* border-width: 1px;
    border-color: rgba(255, 255, 255, 0.5); */
    font-size: 1.2rem;
}
.resetPwdCancelBtn:hover {
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
    border-color: rgba(255, 255, 255, 0);
    color: white;
}

.hideResetPwdPopup {
    opacity: 0;
    top: -20vh
}

.resetPwd_bg {
    display: fixed;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .3);
}

/* Small size screen force resize css */
@media only screen and (max-height: 790px) { 
    .resetPwdContainer {
        left: 2rem;
        height: auto;
    }
}