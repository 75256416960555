.ExportCsvBtn {
    background: var(--colortheme1);
    font-size: 1rem;
    align-self: center;
    color: white;
    padding: .5rem;
    width: 3rem;
    text-align: center;
    border-radius: .3rem;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.18));
    cursor: pointer;
    transition: all .5s;
}
.ExportCsvBtn:hover {
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}
.ExportCsvWindow {
    position: absolute;
    width: calc(100% - 1rem);
    background: var(--colortheme2);
    color: var(--optiontext);
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.18));
    font-size: 1.2rem;
    z-index: 100;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem;
    align-items: center;

    z-index: 100;
}
.ExportCsv_CancelBtn {
    background: var(--colortheme1);
    align-self: center;
    color: white;
    padding: .5rem;
    min-width: 8rem;
    font-size: 0.8rem;
    text-align: center;
    border-radius: .3rem;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.18));
    cursor: pointer;
    transition: all .5s;
}