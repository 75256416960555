.Coldbox_Manage {
    width: calc(100% - 4rem);
    height: calc(100% - 5rem);
    transition: all var(--css-duration);
    padding: 1rem;
    border-radius: .3rem;
    margin: auto auto;
    overflow-y: scroll;
    /* background: linear-gradient(180deg,var(--maindiv-color2),var(--maindiv-color));
    -webkit-filter: drop-shadow(0 10px 15px rgba(0,0,0,.3));
    filter: drop-shadow(0 10px 15px rgba(0,0,0,.3)); */
    -webkit-animation: draglayoutOnload 0.5s ease-out;
    animation: draglayoutOnload 0.5s ease-out;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}
.Coldbox_Manage_Title {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    font-size: 1.5rem;
    letter-spacing: 2px;

    min-width: 100%;
}
.Coldbox_Manage_backBtn {
    font-size: 1rem;
    padding: .3rem;
    width: 3rem;
    border-radius: .3rem;
    text-align: center;
    background: var(--colortheme1);
    color: white;
    box-shadow: 0 3px 3px 0 rgba(0,0,0,.4);
    margin-right: 1rem;
    cursor: pointer;
    opacity: .7;
    transition: all var(--css-duration);
}
.Coldbox_Manage_backBtn:hover {
    opacity: 1;
}
.Coldbox_Manage_groupBtn {
    margin-right: 1rem;
    margin-left: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: calc((15rem - 1.8rem - 3rem)/2);      /* (height of this DIV - height of Text) / 2 */
    border-radius: .3rem;
    text-align: center;
    margin-top: 1rem;
    font-size: 1.8rem;
    letter-spacing: 1px;
    width: calc(25% - 2rem);
    min-height: 15rem;
    opacity: .8;
    background: var(--colortheme1);
    color: white;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,.4);
    transition: all var(--css-duration);
    cursor: pointer;

    /* animation: OptionOnload 0.5s 5s ease-out; */
    animation-name: ClearStatus, OptionOnload;
    animation-timing-function: ease-out;
}
.Coldbox_Manage_groupBtn:nth-child(1) {
    animation-duration: 0s, 0.5s;
    animation-delay: 0s, 0s;
}
.Coldbox_Manage_groupBtn:nth-child(2) {
    animation-duration: 0s, 0.5s;
    animation-delay: 0s, 0s;
}
.Coldbox_Manage_groupBtn:nth-child(3) {
    animation-duration: 0.1s, 0.5s;
    animation-delay: 0s, 0.1s;
}
.Coldbox_Manage_groupBtn:nth-child(4) {
    animation-duration: 0.1s, 0.5s;
    animation-delay: 0s, 0.1s;
}
.Coldbox_Manage_groupBtn:nth-child(5) {
    animation-duration: 0.2s, 0.5s;
    animation-delay: 0s, 0.2s;
}
.Coldbox_Manage_groupBtn:nth-child(6) {
    animation-duration: 0.3s, 0.5s;
    animation-delay: 0s, 0.3s;
}
.Coldbox_Manage_groupBtn:nth-child(7) {
    animation-duration: 0.3s, 0.5s;
    animation-delay: 0s, 0.3s;
}
.Coldbox_Manage_groupBtn:nth-child(8) {
    animation-duration: 0.4s, 0.5s;
    animation-delay: 0s, 0.4s;
}
.Coldbox_Manage_groupBtn:nth-child(9) {
    animation-duration: 0.5s, 0.5s;
    animation-delay: 0s, 0.5s;
}
.Coldbox_Manage_groupBtn:nth-child(10) {
    animation-duration: 0.5s, 0.5s;
    animation-delay: 0s, 0.5s;
}
.Coldbox_Manage_groupBtn:nth-child(11) {
    animation-duration: 0.6s, 0.5s;
    animation-delay: 0s, 0.6s;
}
.Coldbox_Manage_groupBtn:nth-child(12) {
    animation-duration: 0.6s, 0.5s;
    animation-delay: 0s, 0.6s;
}
.Coldbox_Manage_groupBtn:nth-child(13) {
    animation-duration: 0.7s, 0.5s;
    animation-delay: 0s, 0.7s;
}
.Coldbox_Manage_groupBtn:nth-child(14) {
    animation-duration: 0.7s, 0.5s;
    animation-delay: 0s, 0.7s;
}
@keyframes OptionOnload {
    0% {
        opacity: 0;
        transform: translateY(-3px);
    }
    100% {
        opacity: 0.8;
    }
}
@keyframes ClearStatus {
    0% {
        opacity: 0;
        box-shadow: 0 3px 3px 0 rgba(0,0,0,.2), 0 -15rem 0 0 var(--focuscardbackground) inset;
    }
    100% {
        opacity: 0;
        box-shadow: 0 3px 3px 0 rgba(0,0,0,.2), 0 -15rem 0 0 var(--focuscardbackground) inset;
    }
}
.Coldbox_Manage_groupBtn:hover, .Coldbox_Manage_IndividualBtn:hover, .Coldbox_Manage_setpointBtn:hover {
    /* opacity: 1; */
    box-shadow: 0 3px 3px 0 rgba(0,0,0,.2), 0 -15rem 0 0 var(--focuscardbackground) inset;
}
.Coldbox_Manage_groupBtn .fa, .Coldbox_Manage_IndividualBtn .fa, .Coldbox_Manage_setpointBtn .fa {
    font-size: 4rem;
    background: -webkit-linear-gradient(0deg, rgb(198, 102, 0), #e84025);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    filter: drop-shadow( 5px 10px 5px rgba(0,0,0,1));
}
.Coldbox_Manage_LogoDisplay {
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: calc((15rem - 1.8rem)/2);      /* (height of this DIV - height of Text) / 2 */
    width: calc(25% - 2rem);
    min-height: 15rem;
    border-radius: .3rem;
    text-align: center;
    font-size: 1.8rem;
    letter-spacing: 1px;
    opacity: .8;
    background: url('../../assets//images/CPSL/CPSL_hor.png') var(--focuscardbackground);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,.4);

    transition: all var(--css-duration);
}
/* .Coldbox_Manage_LogoDisplay:hover {
    opacity: 1;
    background: url('../../assets//images/CPSL/CPSL_hor.png') #e84025;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
} */
.Coldbox_Manage_infoBtn .fa {
    position: absolute;
    margin-left: -3.5rem;
    margin-top: 1.5rem;
    padding-top: .2rem;
    font-size: 1.5rem;
    text-align: center;
    width: 2rem;
    height: 2rem;
    border-radius: 9999rem;
    color: white;
    background: #e84025;

    cursor: pointer;
}
.Coldbox_Manage_popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,.2);

    display: flex;
    flex-direction: column;
    justify-content: center;
}
.Coldbox_Manage_popupDesc {
    text-align: center;
    padding: 2rem;
    margin: auto;
    font-size: 2rem;
    border-radius: .5rem;
    color: white;
    background: #e84025;
    box-shadow: 0 5px 10px 0 rgba(0,0,0, 1);

    animation: tipsshow .5s ease-in-out;
}
@keyframes tipsshow {
    0% {
        opacity: 0;
    }
}
.Coldbox_Manage_popuptips {
    color: white;
    font-size: 1rem;
}