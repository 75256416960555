

.overlay_div2_levelbox_div
{
	border-width: 0.5px;
	border-radius: 0.2rem;
	min-width: 2.2rem;
	height: 2rem;
	/*min-height: 1.8rem;*/
	margin: 0.1rem;
	display: inline-block;
	padding-top: 0.47rem;
	padding-bottom: 0.47rem;
	outline:none;
    transition: all 0.5s;
    
    border-width: 1px;
    border-color: var(--colortheme1);
    /* color: var(--optiontext); */
    background: rgba(255, 255, 255, .3);
}

.overlay_div2_levelbox_div:hover
{
	color: white;
	background-color: var(--focuscardbackground);
}

.overlay_div2_levelbox_div:focus
{
	color: white;
	background-color: var(--focuscardbackground);
	outline:none;
}

.currentFloor
{
	background: var(--colortheme1);
	color: white;
}

.autoWidthItem {
	width: auto;
	padding-left: 0.3rem;
	padding-right: 0.3rem;
}