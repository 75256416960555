.AT_container
{
    width: 88vw;
    height: calc(100vh - 2.5vw - 1.4rem - 1.2rem);
    /*display: flex;
    flex-wrap: wrap;*/
    background: linear-gradient(180deg, rgb(255, 255, 255, 0.4), rgb(255, 255, 255, 0.7));
    /* middle */
    margin: auto;
    --css_duration: var(--css-duration);

    position: absolute;
    left: 6vw;
    bottom:0;
}

.AT_title
{
    font-size: 2rem;
    margin-left: 1.5rem;
    margin-top: 1.5rem;
    color: var(--optiontext);
}

.AT_addPopupBtn
{
    cursor: pointer;
    width: 70%;
    text-align: center;
}

.AT_popup
{
    position: absolute;
    top: 5vh;
    height: 90vh;
    left: 15vw;
    width: 70vw;
    background: rgba(105, 105, 105, 0.9);
    /* transition: all var(--css-duration); */
    opacity: 1;
    z-index: 1;
    border-radius: 0.5rem;
    padding: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    animation: AlarmTriggerPopup .5s ease-out;
}
@keyframes AlarmTriggerPopup {
    0% {
        opacity: 0;
        transform: translateY(-10px) scale(.7);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
.AT_popupBg
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    z-index: 0;
    transition: all var(--css-duration);
}
.AT_popupCloseBtn
{
    position: absolute;
    right: 1rem;
    cursor: pointer;
    transition: all var(--css-duration);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: white;
}
.AT_popupCloseBtn:hover
{
    /*box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
    color: white;*/
    transform: rotate(90deg);
}

.AT_popupTitle
{
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-left: 0.5rem;
    color: white;
}
.AT_popupInputContainer
{
    background: rgba(0, 0, 0, 0);
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.AT_popupInputContainer *
{
    transition: all var(--css_duration);
}
.AT_popupInputContainer *:focus
{
    box-shadow: -900px 0px 0 0 rgba(0,0,0,0.5) inset;
    box-shadow: 0 -50px 0 0 rgba(255, 255, 255, 0.3) inset;
    color: white;
}
.AT_popupAlarmTypePicker
{
    padding: 0.5rem;
    padding-left: 1rem;
    font-size: 1rem;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    border-radius: 0.5rem;
    width: 10rem;
    height: 3rem;
    transition: all var(--css-duration);
}
.AT_popupSitePicker
{
    padding: 0.5rem;
    padding-left: 1rem;
    font-size: 1rem;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    border-radius: 0.5rem;
    width: 15rem;
    height: 3rem;
    transition: all var(--css-duration);
}
.AT_popupPickFloor
{
    padding: 0.5rem;
    padding-left: 1rem;
    font-size: 1rem;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    border-radius: 0.5rem;
    width: 10rem;
    height: 3rem;
    transition: all var(--css-duration);
}
.AT_popupAlarmTypePicker:focus
{
    /*box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;*/
    box-shadow: 0 -80px 0 0 rgba(255, 255, 255, 0.3) inset;
}
.AT_popupSitePicker:focus
{
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
    box-shadow: 0 -50px 0 0 rgba(255, 255, 255, 0.3) inset;
}
.AT_popupPickFloor:focus
{
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
    box-shadow: 0 -50px 0 0 rgba(255, 255, 255, 0.3) inset;
}
.AT_popupInput
{
    padding: 0.5rem;
    font-size: 1rem;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    border-radius: 0.5rem;
    width: 30rem;
    height: 3rem;
    transition: all var(--css-duration);
}
.AT_popupInput:focus
{
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
    box-shadow: 0 -50px 0 0 rgba(255, 255, 255, 0.3) inset;
}
.AT_popupInput_priorityTxt
{
    color: white;
}
.AT_popupTriggerType
{
    padding: 0.5rem;
    font-size: 1rem;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    border-radius: 0.5rem;
    width: 10rem;
    height: 3rem;

    transition: all var(--css-duration);
}
.AT_popupTriggerValue
{
    padding: 0.5rem;
    font-size: 1rem;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    border-radius: 0.5rem;
    width: 15rem;
    height: 3rem;
    margin-left: 1rem;
    transition: all var(--css-duration);
}
.AT_popupTriggerContainer
{
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
    color: white;
}
.AT_popupTriggerContainer *
{
    padding: 0.5rem;
}
.AT_popupPriority
{
    padding: 0.5rem;
    font-size: 1rem;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    border-radius: 0.5rem;
    width: 10rem;
    height: 3rem;
    transition: all var(--css-duration);
}
.AT_popupPriority:focus
{
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
    box-shadow: 0 -50px 0 0 rgba(255, 255, 255, 0.3) inset;
}
.AT_popupEnableContainer
{
    padding: 0.5rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    color: white;
    margin:auto;

    height: 3rem;
}
.AT_popupEnableContainer *
{    
    cursor: pointer;
}
.AT_popupBtnContainer
{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    height: 2.3rem;
    padding-right: 0.5rem;
    margin-bottom: 1rem;
}
.AT_popupSubBtn
{
    transition: all var(--css-duration);
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background: var(--colortheme1);
    color: white;
    border-radius: 0.4rem;

    cursor: pointer;
}
.AT_popupSubBtn:hover
{
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}
.AT_popupCancelBtn
{
    transition: all var(--css-duration);
    padding: 0.5rem;
    margin-left: 0.5rem;
    border-width: 1px;
    /*border-color: var(--colortheme1);
    color: var(--colortheme1);*/
    border-color: white;
    color: white;
    border-radius: 0.5rem;
    cursor: pointer;
}
.AT_popupCancelBtn:hover
{
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
    color: white;
    border-color: var(--focuscardbackground);
}

.hideATPopup
{
    z-index: -10;
    opacity: 0;
    display: none;
}



/* Small size screen force resize css */
@media only screen and (max-height: 790px) {
    .AT_popup
    {
        position: absolute;
        top: 0vh;
        height: 100vh;
        left: 5vw;
        width: 90vw;
    }
}