.ColdboxSetPt_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem;
    padding-left: 1rem;

    max-width: 55rem;
}
.ColdboxSetPt_rowtxt {
    min-width: 25rem;
    max-width: 25rem;

    color: var(--optiontext);
    opacity: .8;
}
.ColdboxSetPt_rowselect, .ColdboxSetPt_rowsinput {
    min-width: 7rem;
    max-width: 7rem;
    margin-right: 1rem;
    padding-left: .5rem;
    border-radius: .3rem;
}

.ColdboxSetPt_rowselect option {
    background: rgba(0, 0, 0, 0.4);
}


/* Temperature Payload Display Row */
.cdbxsetPt_temp {
    display: flex;
    justify-content: space-between;
    gap: 5px;

    min-width: 20rem;
    padding: 5px 1rem 5px 1rem;
    border-radius: 0.3rem;

    background: rgb(0, 0, 0, 0.3);
    color: white;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
}
.cdbxsetPt_temp .title {
    letter-spacing: 1px;
}

/* previous value part */
.CdbxSetPt_preValue {
    min-width: 6rem;
    padding: 0 0.5rem 0 0.5rem;
    margin-right: 2rem;
    letter-spacing: 1px;
    display: flex;
    justify-content: space-between;
    opacity: 0.7;
}