.ReactModalPortal {
    z-index: 20;
}
.LeftHandSide_popup_menu {
    position: fixed;
    top: 3.6rem;
    left:0;
    background: var(--colortheme2);
    background: linear-gradient(to right, var(--slideBG_1),
    var(--slideBG_2), var(--slideBG_3));
    height: calc(100vh);
    width: 20rem;
    padding: 2rem;
    z-index: 11;
    opacity: 1;
    overflow-y: auto;

    transition: all var(--css-duration);
    animation-name: modalRightSlideFade;
    animation-duration: 0.5s;

    /* opacity: .5; */
    backdrop-filter: blur(20px);

    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .3);
}

@keyframes modalRightSlideFade {
    from {transform: translateX(-50%);opacity: 1;backdrop-filter: blur(10px);}
    to {transform: translateX(0);opacity: 1;backdrop-filter: blur(10px);}
}

.Navbar_Pick_Floor {
    /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.6));
    margin-top: 1rem;
    border-radius: 0.5rem;
    padding:0.5rem;
    padding-bottom: 2rem;

    border-width: 1px;
    border-color: black; */
    position: absolute;
    margin-top: calc(20vh - 5rem);
    height: 5rem;
    width: calc(100% - 4rem);
    padding: 0.5rem;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(5px);
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;

    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    overflow: auto;
}

.Navbar_PickSite_Maindiv {
    /* background: linear-gradient(to left, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.6)); */
    padding: 0rem;
    border-radius: 0.5rem;
    text-align: center;
    display:flex;
    margin-top: 2rem;
    margin-bottom: 0.5rem;

    border-width: 1px;
    border-color: var(--optiontext);
    color: var(--optiontext);

    transition: all var(--css-duration);
}

.Navbar_PickSite_img {
    width: auto;
    border-radius: 0.5rem;
}

.Navbar_PickSite_Btn {
    background: var(--colortheme1);
    color: white;
    width: 2em;
    text-align: center;
    border-radius: 0.5rem;

    transition: all var(--css-duration);
    cursor: pointer;
    padding:0.5rem;

    position: absolute;
    /*top:calc(50% - 2rem);*/
    left: 2rem;

    opacity: 1;
}

.Navbar_PickSite_Btn2
{
    background: var(--colortheme1);
    color: white;
    width: 2rem;
    text-align: center;
    border-radius: 0.5rem;

    transition: all var(--css-duration);
    cursor: pointer;
    padding:0.5rem;

    position: absolute;
    /*top:calc(50% - 2rem);*/
    right: 2rem;
    opacity: 1;
}

.Navbar_PickSite_BuildingName
{
    padding:0.5rem;
    margin:auto;
}

.Navbar_PickSite_Btn:hover
{
    /*background: var(--focuscardbackground);
    opacity: 1;*/

    background: transparent;
    box-shadow: -50px 0px 0 0 var(--focuscardbackground) inset;
}

.Navbar_PickSite_Btn2:hover
{
    /*background: var(--focuscardbackground);
    opacity: 1;*/

    background: transparent;
    box-shadow: 50px 0px 0 0 var(--focuscardbackground) inset;
}

.SideNavRowItem
{
    width: 100%;
    background: linear-gradient(to bottom, rgba(171, 171, 171, .2), transparent);
    /* background: rgba(255, 255, 255, .05); */
    color: var(--optiontext);

    border-radius: 0.5rem;
    padding:0.5rem;
    font-size: 1.2rem;

    margin-top: 0.6rem;
    margin-bottom: 0rem;

    display: flex;
    flex-direction: row;

    /* border-width: 0.5px;
    border-color: rgba(0, 0, 0, 0); */
    
    cursor: pointer;

    transition: all 0.8s;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.05);
}

.SideNavRowItem:hover
{
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.05), 0px -100px 0 0 var(--focuscardbackground) inset;
    color: white;
}

.SideNavRowItem div
{
    margin-left: 1.3rem;
}

.SideNavRowItem .fa
{
    margin-left: 1rem;
    width: 2rem;
    text-align: center;
}

.Navbar_PickSite_img2
{
    border-radius: 0.5rem;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.5);

    object-fit: cover;
    width: 15vw;
    height: 20vh;
    transition: all var(--css-duration);

    /*animation: in 0.5s 0s;*/
}

.disabledSlideItem {
    background: rgb(222, 222, 222);
    color: grey;
    opacity: .6;
    cursor: auto;
}
.disabledSlideItem:hover {
    box-shadow: 0 0 0 0 black;
    color: grey;
}

@keyframes in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .sideIcon
  {
      width: 1.5rem;
      text-align: start;
  }


  /* Small size screen force resize css */
@media only screen and (max-height: 790px) {
    /* Left Slide */
    .SideNavRowItem {
        margin-top: 0.3rem;
    }
    .Navbar_PickSite_Maindiv {
        margin-top: 0.5rem;
    }
    .Navbar_PickSite_img2 {
        width: 100%;
    }
    .Navbar_Pick_floor_title {
        display: flex;
        flex-direction: start;
        font-size: 1.3rem;
    }
    .Navbar_Pick_floor_title * {
        margin: 0;
        margin-left: 0.5rem;
    }
    .LeftHandSide_popup_menu {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
    }
}