.OperationOverview {
    width: calc(100% - 4rem);
    height: calc(100% - 5rem);
    transition: all var(--css-duration);
    padding: 1rem;
    border-radius: .3rem;
    margin: auto auto 0;
    /* background: linear-gradient(180deg,var(--maindiv-color2),var(--maindiv-color));
    filter: drop-shadow(0 10px 15px rgba(0,0,0,.3)); */
    -webkit-animation: draglayoutOnload 0.5s ease-out;
    animation: draglayoutOnload 0.5s ease-out;
    display: flex;
    flex-direction: column;
}
.OperOver_Main {
    height: 100%;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    margin-top: 0.5rem;
}
.OperOver_leftCol {
    height: 100%;
    width: calc(100% - 12rem);
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.OperOver_rightCol {
    width: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}