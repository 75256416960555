.AT_tableContainer
{
    /*position: absolute;
    top: 5rem;*/
    /*height: 82vh;*/

    box-shadow: 0 15px 30px 0 rgba(0,0,0,.11), 0 5px 15px 0 rgba(0,0,0,.08);
    margin: 0.5rem;
    width: calc(100% - 1rem);
}
.AT_tableContainer .row
{
    background: rgba(255, 255, 255, 0.384);
    background: linear-gradient(to right, rgba(255, 255, 255, 0.445), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.445));
    height: 2rem;
    transition: all var(--css-duration);
}
.AT_tableContainer .row:hover
{
    box-shadow: 0 -2000px 0 0 rgb(0, 0, 0, 0.05) inset;
}
.AT_tableContainer .row td
{
    padding: 0.5rem;
    padding-left: 1rem;
    background: transparent;
    font-size: 1.1rem;
}
.AT_tableContainer .header
{
    height: 2rem;
    background: var(--colortheme1);
    color: white;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.2);
}
.AT_tableContainer .header th
{
    border-width: 1px;
    border-color: rgb(255, 255, 255);
    text-align: start;
    padding: 0.5rem;
    padding-left: 1rem;
    font-size: 1.1rem;
}


.AT_tableEditBtn
{
    cursor: pointer;
    width: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.15);
    color: rgba(255, 255, 255, 1);
    padding: 0.3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.3rem;
    transition: all var(--css-duration);
}
.AT_tableEditBtn:hover
{
    box-shadow: 0 -30px 0 0 var(--focuscardbackground) inset;
    color: white;
}

.AT_addPopupBtn
{
    background: rgba(255, 255, 255, 0.4);
    width: 100%;
    border-radius: 0.3rem;
    padding: 0.2rem;
    transition: all var(--css-duration);
}
.AT_addPopupBtn:hover
{
    box-shadow: 0 -30px 0 0 var(--focuscardbackground) inset;
}


.AT_table_pageContainer
{
    margin-right: 1rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.AT_table_pageBtn
{
    padding: 0.5rem;
    border-width: 1px;
    border-color: transparent;
    border-radius: 0.3rem;
    width: 2rem;
    height: 2rem;
    margin-left: 0.3rem;
    text-align: center;
    transition: all var(--css-duration);
    cursor: pointer;
}
.AT_table_pageBtn:hover
{
    box-shadow:  0 -50px 0 0 var(--focuscardbackground) inset;
    color: white;
    border-color: var(--focuscardbackground);
}

.AT_pageHighlight
{
    box-shadow:  0 -50px 0 0 var(--focuscardbackground) inset;
    color: white;
    border-color: var(--focuscardbackground);
}

.ATTable_droplist
{
    background: rgba(0, 0, 0, 0.08);
    padding: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.3rem;
    transition: all var(--css-duration);
}
.ATTable_droplist:focus
{
    background: rgba(0, 0, 0, 0.6);
    color: white;
}

.AT_tableContainerscroll
{
    overflow: auto;
    height: 60vh;
}

.AT_table_name
{
    width: 15vw;
}
.AT_table_Type
{
    width: 10vw;
}
.AT_table_priority
{
    width: 5vw;
}
.AT_table_Devices
{
    width: 15vw;
}
.AT_table_location
{
    width: 15vw;
}
.AT_table_triggers
{
    width: 12vw;
}
.AT_table_message
{
    width: 20vw;
}
.AT_table_Enabled
{
    width: 5vw;
}

.AT_Table_moreThan3
{
    color: grey;
    background: rgba(255, 255, 255, 0.25);
    width: 50%;
    border-radius: 0.3rem;
    transition: all var(--css-duration);
    cursor: pointer;
}
.AT_Table_moreThan3:hover
{
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
    color: white;
}

.AT_more_bg
{
    background: rgb(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    font-size: 1.5rem;
    color: white;
    text-align: start;

    transition: all var(--css-duration);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.AT_more_popupContainer
{
    background: linear-gradient(to bottom, rgb(0, 0, 0, 0.2), rgb(0, 0, 0, 0.2));
    backdrop-filter: blur(10px);
    width: 70vw;
    padding: 1rem;
    border-radius: 0.3rem;
}
.AT_more_listContainer
{
    background: rgba(0, 0, 0, 0);
    font-size: 1.3rem;
    text-align: start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0.5rem;
    border-radius: 0.5rem;
}
.AT_seemore_listItem
{
    background: rgb(255, 255, 255, 0.3);
    padding: 0.3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: auto;
    margin-bottom: 0.3rem;
    margin-left: 1vw;
    width: 30vw;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    transition: all var(--css-duration);
}
.AT_seemore_listItem:hover
{
    box-shadow: 0 -30px 0 0 rgb(255, 255, 255, 0.3) inset;
}
.AT_more_closeBtn
{
    cursor: pointer;
    transition: all var(--css-duration);
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    font-size: 1.3rem;
}

.HideMorePopup
{
    opacity: 0;
    background: transparent;
    display: none;
}





.AT_deleteConfirmBG
{
    background: rgba(0, 0, 0, 0.4);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    /*display: none/* debug */

    transition: all var(--css-duration);
}

.AT_deleteBox
{
    position: fixed;
    top: 40vh;
    left: 40vw;
    width: 20vw;
    height: 10rem;
    background: rgb(0, 0, 0, 0.7);
    color: white;

    border-radius: 0.5rem;
    padding: 1rem;

    transition: all var(--css-duration);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    /*display: none;/* */
}

.AT_deleteCloseBtn
{
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    transition: all var(--css-duration);
}
.AT_deleteCloseBtn:hover
{
    transform: rotate(90deg);
}

.AT_deleteTitle
{
    text-align: center;
    font-size: 1.5rem;
    margin-top: 1rem;
}
.AT_btnContainer
{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.AT_deleteConfirmBtn
{
    background: rgb(0, 221, 0, 0.8);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border-radius: 0.2rem;

    cursor: pointer;
    transition: all var(--css-duration);
}
.AT_deleteConfirmBtn:hover
{
    background: transparent;
    box-shadow: 0 -50px 0 0 rgb(0, 255, 0) inset;
}

.AT_deleteCancelBtn
{
    background: grey;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border-radius: 0.2rem;

    cursor: pointer;
    transition: all var(--css-duration);
}
.AT_deleteCancelBtn:hover
{
    background: transparent;
    box-shadow: 0 -50px 0 0 rgba(255, 255, 255, 0.3) inset;
}
.alarmtriggertableLoading {
    height: 60vh;
}

.HideConfirmPopup
{
    /*display: none;*/
    z-index: -10;
    opacity: 0;
}


/* Small size screen force resize css */
@media only screen and (max-height: 790px) {
    /* Alarm Trigger Table */
    .AT_tableContainerscroll {
        height: 50vh;
    }
}



.hide {
    display: none;
}