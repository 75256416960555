.ReAuth_maindiv {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.4), rgba(255, 255, 255, 0.4),
  rgba(0, 0, 0, 0.7)), url(./assets/images/hong-kong-night.jpg) no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  top: 0;
  left:0;

  /*animation-name: redirectchangebackground;
	animation-duration: 0.5s;
	animation-delay: 0s;*/
}

/*@keyframes redirectchangebackground {
  from {background: linear-gradient(0deg, rgba(46, 56, 48, 1), rgba(243, 209, 116, 1), rgba(184, 154, 65, 1),
    rgba(0, 0, 0, 1))}
  to {background: linear-gradient(0deg, rgba(46, 56, 48, 1), rgba(243, 209, 116, 0.6), rgba(184, 154, 65, 0.4),
    rgba(0, 0, 0, 1)), url(./assets/images/loading_hk.jpg) no-repeat}
}*/

.loading1 {
  position: absolute;
  top: 57vh;
  left: 45vw;

  width: 10vw;
  display: flex;
  justify-content: space-between;

  z-index: 10;
}

.loading_background1
{
  /*position: absolute;
  top: 30vh;
  left: 20vw;*/
  color: white;
  /*background: rgba(255, 255, 255, 0.3);
  padding: 0.5rem;
  padding-top: 20vh;
  border-radius: 1.5rem;
  width: 60vw;
  height: 40vh;*/
  text-align: center;

  font-size: 1.5rem;
  margin-top: 1rem;
  /*box-shadow:
	0 0 10px 10px rgba(255, 255, 255, 0.1);*/
}

/*
.loading-bar2 {
  display: inline-block;
  width: 2px;
  height: 30px;
  border-radius: 4px;
  animation: loadingAuth 2.5s ease-in-out infinite;
  background-color: #ffffff;
}
.loading-bar2:nth-child(1) {
  animation-delay: 0;
}
.loading-bar2:nth-child(2) {
  animation-delay: 0.09s;
}
.loading-bar2:nth-child(3) {
  animation-delay: .18s;
}
.loading-bar2:nth-child(4) {
  animation-delay: .27s;
}
.loading-bar2:nth-child(5) {
    animation-delay: 0.36s;
  }
  .loading-bar2:nth-child(6) {
    animation-delay: 0.45s;
  }
  .loading-bar2:nth-child(7) {
    animation-delay: .64s;
  }
  .loading-bar2:nth-child(8) {
    animation-delay: .73s;
  }

@keyframes loadingAuth {
  0% {
    transform: scale(1, 1);
  }
  20% {
    transform: scale(1, 3);
  }
  40% {
    transform: scale(1, 1);
  }
}*/

.app_progressBar
{
  border-width: 0px;
  border-color: white;
  width: 50vw;
  height: 10px;

  position: absolute;
  left: 25vw;
  top: 60vh;

  border-radius: 1rem;
  background: rgb(196, 196, 196);
}

.progressItem
{
  width: 50%;
  height: 100%;
  background: linear-gradient(to right, #ffffff25, var(--focuscardbackground));

  border-radius: 1rem;
  transition: all var(--css-duration);

  box-shadow: 10px 0px 10px 0px var(--focuscardbackground);
}

.subDisplay
{
  color: white;
  position: absolute;
  top: 50vh;
  left: 70vw;
  font-size: 2rem;
  display:none;
}

.App_title
{
  color: white;
  font-size: 2.5rem;
  position: absolute;
  top: 45vh;
  width: 100vw;
  text-align: center;
}

.loading_redirectBtn
{
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 1rem;
  font-size: 2rem;
  border-radius: 0.5rem;
  position: fixed;
  top: 35vh;
  left: 25vw;

  transition: all var(--css-duration);
}
.loading_redirectBtn:hover
{
  box-shadow: -50px -50px 0 0 var(--focuscardbackground) inset;
}