/* .IEQV3_main {
    width: 100%;
    height: 100%;
    border-radius: 0.3rem;
    box-shadow: 0 15px 10px 0 rgb(0 0 0 / 5%), 0 5px 5px 0 rgb(0 0 0 / 10%);
    background: var(--cardbodybackground);
    animation: floor4readingcardsOnload 1s ease-out;
}
.IEQV3_main .head {
    height: 2rem;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;

    color: var(--cardtitletext);
    background: var(--cardtitle);
    box-shadow: 0 15px 10px 0 rgb(0 0 0 / 5%), 0 5px 5px 0 rgb(0 0 0 / 10%);

    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.IEQV3_main .head .icon {
    margin-left: 1rem;
}
.IEQV3_main .head .txt {
    letter-spacing: 2px;
}


.IEQV3_main .inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 6%;
}
.IEQV3_main .inner .rowItem {
    min-width: 46%;
    height: 6rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.IEQV3_main .inner .rowItem .innerRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 80%;
}
.IEQV3_main .inner .rowItem .innerRow .icon {
    text-align: center;
    width: 4rem;
    font-size: 3rem;
}
.IEQV3_main .inner .rowItem .innerRow .reading {
    font-size: 3.5rem;
    display: flex;
    justify-content: flex-end;
    width: 6rem;
}
.IEQV3_main .inner .rowItem .innerRow .unit {
    font-size: 1rem;
    margin-top: 0.4rem;
    width: 1rem;
}

.IEQV3_main .inner .rowItem .title {
    position: absolute;
    color: white;

    opacity: 0.7;
    letter-spacing: 1px;
    text-align: center;
    width: 4rem;
    font-size: 0.8rem;
} */

.IEQV3_main {
	width: 100%;
	height: 100%;
	border-radius: 0.3rem;
	box-shadow: 0 15px 10px 0 rgb(0 0 0 / 5%), 0 5px 5px 0 rgb(0 0 0 / 10%);
	background: var(--cardbodybackground);
	animation: floor4readingcardsOnload 1s ease-out;
}
.IEQV3_main .head {
	height: 2rem;
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
	color: var(--cardtitletext);
	background: var(--cardtitle);
	box-shadow: 0 15px 10px 0 rgb(0 0 0 / 5%), 0 5px 5px 0 rgb(0 0 0 / 10%);
	display: flex;
	align-items: center;
	gap: 0.5rem;
}
.IEQV3_main .head .icon {
	margin-left: 1rem;
}
.IEQV3_main .head .txt {
	letter-spacing: 2px;
}
.IEQV3_main .inner {
	width: 100%;
	height: calc(100% - 26px);
	display: flex;
	flex-wrap: wrap;
}
.IEQV3_main .inner .rowItem {
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.IEQV3_main .inner .rowItem .innerRow {
	display: flex;
	align-items: center;
	justify-content: space-between;
	grid-gap: 1rem;
	gap: 1rem;
	width: 80%;
}
.IEQV3_main .inner .rowItem .innerRow .icon {
	text-align: center;
	width: 3rem;
	font-size: 3rem;
}
.IEQV3_main .inner .rowItem .innerRow .reading {
	font-size: 1.5rem;
	display: flex;
	width: 9rem;
}
.IEQV3_main .inner .rowItem .innerRow .unit {
}
.IEQV3_main .inner .rowItem .title {
	position: absolute;
	margin-top: 0.3rem;
	color: #fff;
	opacity: 0.7;
	text-align: center;
	width: 3rem;
	font-size: 0.8rem;
}