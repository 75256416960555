.CB_BLE_title {
    font-size: 2rem;
}

.CB_BLE {
    width: calc(100% - 4rem);
    height: calc(100% - 5rem);
    transition: all var(--css-duration);
    padding: 1rem;
    border-radius: .3rem;
    margin: auto auto 0;
    /* background: linear-gradient(180deg,var(--maindiv-color2),var(--maindiv-color));
    -webkit-filter: drop-shadow(0 10px 15px rgba(0,0,0,.3));
    filter: drop-shadow(0 10px 15px rgba(0,0,0,.3)); */
    -webkit-animation: draglayoutOnload 0.5s ease-out;
    animation: draglayoutOnload 0.5s ease-out;
    display: flex;
    flex-direction: column;
}

.CB_BLE_table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

.CB_BLE_bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.EditPopupCloseBtn
{
    position: absolute;
    top: 1rem;
    right: 0.8rem;
    padding: 0.5rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    cursor: pointer;
    color: white;
    transition: all var(--css-duration);

    font-size: 1.5rem;
}
.EditPopupBg
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;

    transition: all var(--css-duration);
}

.EditBtn
{
    background: rgb(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
    transition: all var(--css-duration);
}
