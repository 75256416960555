.Lift_door_main {
    background: var(--cardbodybackground);
    /* background: linear-gradient(90deg,hsla(0,0%,100%,.329),hsla(0,0%,100%,.425)); */
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
    color: white;
}
.Lift_graphTitle {
    margin: 0.5rem;
}

/* Lift Door Status Start */
.Lift_graphStatusBackground{
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    background:url('./../../../assets/images/LiftBody.jpg');
    background-size: cover;
    background-position: 0, 20vh;
    
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);

    display: flex;
    flex-direction: column;
}
.Lift_graphStatusBackground {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Lift_graphStatusDoorItem_L {
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, grey, rgba(128, 128, 128, 0.3));
    margin: 0;
    transition: all var(--css-duration);
    border-right-width: 1px;
    border-color: white;
}
.Lift_graphStatusDoorItem_R {
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, grey, rgba(128, 128, 128, 0.3));
    margin: 0;
    transition: all var(--css-duration);
    border-left-width: 1px;
    border-color: white;
}
.LiftDoorOpenStatus {
    width: 3rem;
}
/* .Lift_graphStatusDoorDisplay {
    position: absolute;
    left: 1rem;
    font-size: 0.8rem;
    padding: 1rem;
    letter-spacing: 2px;
    backdrop-filter: blur(5px);
} */
/* Lift Door Status End */

.LiftDr_switch {
    position: absolute;
    margin-top: 20px;
    margin-left: 10px;

    display: flex;
    gap: 10px;
}
.LiftDr_switch .open,
.LiftDr_switch .close {
    background: rgba(0, 0, 0, 0.3);
    opacity: 0.7;
    width: 5rem;
    height: 2rem;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.5s;
}
.LiftDr_switch .current {
    background: var(--focuscardbackground);
    box-shadow: 0 10px 25px -10px var(--focuscardbackground);
    opacity: 1;
}

@media only screen and (max-height: 790px) {
    .Lift_graphStatusBackground {
        height: 8rem;
        width: 17rem;
        margin: auto;
    }
    .Lift_graphDirection2 * {
        margin-top: 0.7rem;
    }
    .Lift_graphStatusDoorItem_L {
        margin: 0;
    }
    .Lift_graphStatusDoorItem_R {
        margin: 0;
    }
    /* .Lift_graphStatusDoorDisplay {
        background: rgba(0, 0, 0, 0.3);
        align-self: center;
    } */
}