/* .ColdboxIndi_DeviceIDList {
    width: 30rem;
    max-height: 10rem;
    background:  rgba(0, 0, 0, 0.3);
    padding: 0.3rem;
    border-radius: 0.3rem;
    overflow-y: auto;

    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
}
.ColdboxIndi_DeviceRow {
    padding: 0.5rem;
    font-size: 1.5rem;
    width: 10rem;
    height: 2.5rem;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 0.3rem;
    text-align: center;

    cursor: pointer;
    transition: all var(--css-duration);
}
.ColdboxIndi_DeviceRow:hover {
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}
.ColdboxIndi_DeviceIDContainer {

} */