/* popup css style */
.Coldbox_GD_popupBG {
    position: absolute;
    background: rgb(0, 0, 0, .2);
    width: 100vw;
    height: 100vh;

    animation: .6s ease-out 0s 1 GDBG_PopupAppear;
}
.Coldbox_GD_popupMain {
    position: fixed;
    width: 30rem;
    height: 23rem;
    left: calc((100vw - 30rem)/2);
    top: calc((100vh - 30rem)/2);
    border-radius: .5rem;

    animation: .6s ease-out 0s 1 GD_PopupAppear;

    background: var(--cardbodybackground);
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@keyframes GDBG_PopupAppear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes GD_PopupAppear {
    0% {
        opacity: 0;
        transform: translateY(-10px) scale(1);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
.header {
    background: var(--divheader);
    color: var(--divheadertext);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
    width: 100%;
    padding: .5rem;
    text-align: center;
    font-size: 2rem;

    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
}

.Coldbox_GD_popupMain .main {
    height: 100%;
    width: 100%;
    padding: .5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Coldbox_GD_popupMain .main .zone {
    color: rgb(0, 157, 255);
    text-align: center;

    font-size: 2.5rem;
}
.Coldbox_GD_popupMain .main .Hours {
    color: var(--optiontext);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 20rem;

    margin-left: auto;
    margin-right: auto;
}
.Coldbox_GD_popupMain .main .Hours .hourreading {
    font-size: 2.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    width: calc(50% - 2rem);
}
.Coldbox_GD_popupMain .main .Hours .hourreading .hourunit {
    font-size: 1.5rem;
    align-self: flex-end;
}
.Coldbox_GD_popupMain .main .Hours .hourStatus {
    font-size: 1.5rem;
    align-self: flex-end;
    text-align: center;
    width: calc(50% - 2rem);
}
.Coldbox_GD_popupMain .main .temp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 20rem;
    
    margin-left: auto;
    margin-right: auto;

    color: red;
}
.Coldbox_GD_popupMain .main .temp .Left, .Coldbox_GD_popupMain .main .temp .Right {
    width: calc(50% - 2rem);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Coldbox_GD_popupMain .main .temp .Left .top, .Coldbox_GD_popupMain .main .temp .Right .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.Coldbox_GD_popupMain .main .temp .Left .top .tempReading, .Coldbox_GD_popupMain .main .temp .Right .top .tempReading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-size: 3rem;
}
.Coldbox_GD_popupMain .main .temp .Left .top .tempReading .tempUnit, .Coldbox_GD_popupMain .main .temp .Right .top .tempReading .tempUnit {
    font-size: 1rem;
}
.tempIcon {
    font-size: 3rem;
}
.Coldbox_GD_popupMain .main .temp .Left .bottom, .Coldbox_GD_popupMain .main .temp .Right .bottom {
    color: var(--optiontext);
    text-align: center;
    font-size: 1rem;
    letter-spacing: 2px;
}
.Coldbox_GD_popupMain .main .humid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 20rem;
    
    margin-left: auto;
    margin-right: auto;

    color: rgb(0, 157, 255);
}
.Coldbox_GD_popupMain .main .humid .humidReading {
    width: calc(50% - 2rem);
    font-size: 3rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
}
.Coldbox_GD_popupMain .main .humid .humidReading .humidUnit {
    font-size: 1rem;
}
.Coldbox_GD_popupMain .main .humid .humidIcon {
    width: calc(50% - 2rem);
    font-size: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}