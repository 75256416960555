.ColdboxList {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.ColdboxList_header {
    width: 100%;
    font-size: 1rem;
    background: var(--divheader);
    color: var(--divheadertext);
    padding: .5rem;
    height: 2rem;
    --css_duration: var(--css-duration);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.18), 0 5px 5px 0 rgba(0,0,0,.1);
    border-radius: .3rem;

    display: flex;
    justify-content: space-between;
}

.ColdboxList_header .detail {
    display: flex;
    gap: 5px;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.7);
}
.ColdboxList_header .detail i {
    width: 1rem;
    height: 1rem;
    border-radius: 99px;

    background: white;
    color: grey;

    display: flex;
    justify-content: center;
    align-items: center;
}
/* .ColdboxList_CardList {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow-x: hidden; 
    overflow-y: hidden;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    height: 100%;

    background: red;
} */
.ColdboxList_CardListXXX {
    margin-top: 1rem;
}
.ColdboxList_card {
    min-width: 13.55rem;
    height: calc(100% - 0rem);
    margin-right: .5rem;
    /* margin-top: .5rem; */
    padding: .5rem;
    border-radius: .3rem;
    transition: all var(--css-duration);
    background: var(--cardbodybackground);
    color: var(--optiontext);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.1), 0 3px 5px 0 rgba(0,0,0,.05);

    cursor: pointer;
}
.ColdboxList_card.highlight {
    background: white;
    color: black;
}
.ColdboxList_card .location {
    font-size: 1.5rem;
    text-align: center;
    opacity: .7;
}
.ColdboxList_card .zone {
    font-size: 1.1rem;
    text-align: center;
    margin-top: .5rem;
    color: rgb(0, 157, 255);
}
.ColdboxList_card .commonStatus {
    display: flex;
    flex-direction: row;
    margin-top: .5rem;
}
.ColdboxList_card .commonStatus .Left {
    width: 50%;
    text-align: center;
}
.ColdboxList_card .commonStatus .Left .top {
    font-size: 1.1rem;
}
.ColdboxList_card .commonStatus .Left .bottom {
    font-size: .8rem;
}
.ColdboxList_card .commonStatus .Right {
    width: 50%;
    text-align: center;
}
.ColdboxList_card .commonStatus .Right .top {
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
}
.ColdboxList_card .commonStatus .Right .top .unit {
    font-size: .7rem;
    align-self:flex-end;
}
.ColdboxList_card .commonStatus .Right .bottom {
    font-size: .8rem;
}
.ColdboxList_card .tempStatus {
    display: flex;
}
.ColdboxList_card .tempStatus .Left, .ColdboxList_card .tempStatus .Right {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: .5rem;
}
.ColdboxList_card .tempStatus .Left .top, .ColdboxList_card .tempStatus .Right .top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1.5rem;
    color: red;
}
.ColdboxList_card .tempStatus .Left .top .tempValue, .ColdboxList_card .tempStatus .Right .top .tempValue {
    display: flex;
    flex-direction: row;
}
.ColdboxList_card .tempStatus .Left .top .tempValue .tempUnit, .ColdboxList_card .tempStatus .Right .top .tempValue .tempUnit {
    margin-right: 1rem;
    font-size: .7rem;
}
.ColdboxList_card .tempStatus .Left .bottom, .ColdboxList_card .tempStatus .Right .bottom {
    font-size: .8rem;
}

.ColdboxList_card .humidStatus {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 7rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: .5rem;
    color: #00d0ff;
    font-size: 1.5rem;
}
.ColdboxList_card .humidStatus .humidValue {
    display: flex;
}
.ColdboxList_card .humidStatus .humidValue .humidUnit {
    font-size: .7rem;
}


.ColdboxList_card .hourStatus {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 10rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: .5rem;
    color: #000000;
    font-size: 1.5rem;
}
.ColdboxList_card .hourStatus .hourValue {
    display: flex;
}
.ColdboxList_card .hourStatus .hourValue .hourUnit {
    font-size: .7rem;
}
.ColdboxList_card .hourStatus .Right .statusValue {
    margin-left: .3rem;
    font-size: 1rem;
}