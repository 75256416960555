.Lift_graphImage {
    background: var(--cardbodybackground);
    color: var(--optiontext);
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Lift_graphImage .title {
    font-size: 1.4rem;
    margin: 0.5rem;
    margin-bottom: 1rem;
}

.Lift_graphImage .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.Lift_graphImage .row p {
    width: 70%;
}
.Lift_graphImage .row .value {
    text-align: center;
    width: 30%;
}
.Lift_graphImage .row .alarmvalue {
    color: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    width: 30%;
    text-align: center;
    background: rgb(0, 151, 25);
    transition: all var(--css-duration);
    box-shadow: 0 10px 25px -10px rgb(0, 151, 25, 30%);
}
.Lift_graphImage .row .alarmvalue.on {
    /* background: var(--focuscardbackground);
    box-shadow: 0 10px 25px -10px var(--focuscardbackground); */
    background: red;
    box-shadow: 0 10px 25px -10px red;
}

.Lift_graphImage .row .lastupdate {
    width: 30%;
    display: flex;
    justify-content: center;
}