.pumpSummary {
    width: 100%;
    height: 100%;
    box-shadow: 0 15px 10px 0 rgb(0 0 0 / 5%), 0 5px 5px 0 rgb(0 0 0 / 10%);
    background: var(--cardbodybackground);
    color: var(--optiontext);
    border-radius: 0.3rem;
    animation: floor4readingcardsOnload 1s ease-out;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .head {
        width: 100%;
        padding: 0.5rem;
        padding-left: 1rem;
        font-size: 1rem;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        letter-spacing: 0.5px;
        color: var(--cardtitletext);
        background: var(--cardtitle);
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.174));
    }
    .inner {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .row {
            height: 50%;
            display: flex;
            justify-content: space-between;
            padding: 0.5rem;
            .left {
                width: 50%;
                height: 100%;
                min-width: 4rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                .img {
                    height: 100%;
                    width: 100%;
                    background: url(../../../../assets/images/icons/water-pump.png);
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                .device {
                    font-size: 1rem;
                }
            }
            .right {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-self: center;
                width: 9rem;
                .reading {
                    font-size: 2rem;
                }
                .unit {
                    font-size: 0.8rem;
                }
            }
        }
    }
}