// Shared the scss with valueCard.scss

$cardBackground: var(--cardbodybackground);
$textColor: var(--optiontext);
$transitionTime: var(--css-duration);

%CardColors {
    background: $cardBackground;
    color: $textColor;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.05);
}
%CardAnimation {
    animation: .5s cardOnload linear;
    transition: all $transitionTime;
}

.BtmCard {
    @extend %CardColors;
    @extend %CardAnimation;

    // width: 13.55rem;
    height: 10.5rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.3rem;
    background: var(--cardbodybackground);
    color: var(--optiontext);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
        min-width: 10rem;
    }
    .subTitle {
        min-width: 10rem;
        max-width: 13.55rem;
        margin: 5px;
        font-size: 12px;
        margin-top: 6px;
        opacity: 0.7;

        // word-break: break-all;
        word-wrap: break-word;
        white-space: normal;
    }
    .valueList {
        // width: 10rem;
        min-height: 4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 6px 0 6px;
           .name {
                font-size: 1.5rem;
                font-weight: 600;
                opacity: 0.7;
           }
           .value {
               margin-left: 4px;
                font-size: 1.5rem;
                // letter-spacing: 1px;
                display: flex;
                align-items: center;

                .unit {
                    opacity: 0.8;
                    margin-left: 2px;
                    // width: 50px;
                }
           }
        }
    }

    .lastUpdate {
        display: flex;
        justify-content: space-between;
        margin: 0 6px 0 6px;
        .text,
        .datetime {
            opacity: 0.5;
        }
        .datetime {
            min-width: 5rem;
        }
    }
}