.LiftDrStat_Highchart_daily {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
}
.Lift_highchartDaily {
    
}

.LiftDrStat_chart_daily_option {
    display: flex;
    flex-direction: column;
    gap: 5px;
    min-width: 8rem;
}
.LiftDrStat_chart_daily_option select {
    height: 2rem;
    min-width: 100%;
    background: rgba(0, 0, 0, 0.18);
    color: var(--optiontext);
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%), 0 5px 5px 0 rgb(0 0 0 / 5%);
    border-radius: 0.3rem;
    letter-spacing: 2px;
    font-size: 0.8rem;
    cursor: pointer;
    transition: all .5s;
    display: flex;
    justify-content: center;
    align-items: center;
}
.LiftDrStat_chart_daily_option select option {
    background: rgba(0, 0, 0, 0.3);
}

.LiftDrStat_chart_daily_tab {
    width: 100%;
    height: 2rem;

    color: white;
    text-align: center;
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%), 0 5px 5px 0 rgb(0 0 0 / 5%);
    border-radius: 0.3rem;
    letter-spacing: 2px;
    font-size: 0.8rem;
    cursor: pointer;
    transition: all .5s;
    display: flex;
    justify-content: center;
    align-items: center;
}