.LiftSum_LinechartBtn {
    background: var(--colortheme1);
    font-size: 1rem;
    align-self: center;
    color: white;
    padding: .5rem;
    width: 3rem;
    text-align: center;
    border-radius: .3rem;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.18));
    cursor: pointer;
    transition: all .5s;
}
.LiftSum_LinechartBtn:hover {
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}