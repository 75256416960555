.Al_Trig_DevicePick_Container
{
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    padding: 1rem;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;

    height: 8rem;
    width: 68vw;
    overflow-y: auto;
}

.AL_Trig_DevicePick_checkContainer
{
    color: white;
    background: rgb(0, 0, 0, 0.2);
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    margin-right: 0.5rem;
    padding: 0.3rem;
    cursor: pointer;
    border-radius: 0.3rem;
    transition: all var(--css-duration);
    width: 10rem;

    display: flex;
    justify-content: space-between;
}

.AL_Trig_DevicePick_checkContainer:hover
{
    box-shadow: 0 -80px 0 0 black inset;
}

.Al_Trig_DevicePicked_Container
{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.Al_Trig_DevicePicked_OutsideContainer
{
    background: rgba(255, 255, 255, 0.171);
    margin-top: 0.5rem;
    border-radius: 0.3rem;
    padding: 0.5rem;

    height: 10rem;
    overflow-y: auto;
}
.Al_Trig_pickedTItle
{
    color: white;
    margin-top: 0.3rem;
}

.AL_Trig_CloseBtn
{
    margin-left: auto;
    margin-left: 0.5rem;
    padding-right: 0.125rem;
    transition: all 0.5s;

    height: 1rem;
}
.AL_Trig_CloseBtn:hover
{
    transform: rotate(90deg);
}