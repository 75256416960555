.button {
    background-color: #b10c0c; /* Green */
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    margin: 2px 2px;
    cursor: pointer;
    border-radius: 12px;
  }