.Lift_headerContainer
{
    background: var(--divheader);
    color: white;
    height: 100%;   
    height: 2.3rem;
    border-radius: 0.5rem;
    margin-top: 0.2rem;
    display: flex;
    align-items: center;

    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.4);
}

.LiftHeaderIcon
{
    font-size: 1rem;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
}
.LiftHeaderIcon2
{
    /* position: absolute;
    left: 2.8rem;
    top: 0.7rem; */
    margin-left: 0.5rem;
}

.LiftHeaderTitle
{
    min-width: 10rem;
    text-align: start;
    font-size: 1rem;
    margin-left: 1.5rem;
    cursor: pointer;

    transition: all var(--css-duration);
}

.LiftHeaderTitle:hover
{
    /* box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset; */
}

.LiftHideSitePopup
{
    display: none;
}

.Lift_HeaderPiskSiteItem
{
    background: rgba(255, 255, 255, 0.356);
    color: var(--optiontext);
    width: 10vw;
    text-align: center;
    padding: 0.5rem;
    margin-bottom: 0.1rem;
    margin-left: 1.5rem;
    border-radius: 0.5rem;
    transition: all var(--css-duration);
    cursor: pointer;
    backdrop-filter: blur(4px);
}
.Lift_HeaderPiskSiteItem:hover
{
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
    color: white;
}
/* .Lift_SiteCloseBtn
{
    padding: 0.5rem;
    cursor: pointer;
    transition: all var(--css-duration);
} */
.Lift_SiteCloseBtn:hover
{
    /* box-shadow: 0 -30px 0 0 var(--focuscardbackground) inset; */
}

.LiftHeaderLevelIcon
{
    /* position: absolute;
    right: 8rem;
    top: 0.7rem; */
}

.LiftHeaderLevelTitle
{
    /* position: absolute;
    right: 1rem;
    top: 0.7rem; */
    width: 7rem;
    text-align: center;
    transition: all var(--css-duration);
    cursor: pointer;
}

.LiftHeaderLevelTitle:hover
{
    /* box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset; */
}
.Lift_Sitewidth {
    width: 100%;
}
.Lift_HeaderPiskLevelItem
{
    position: absolute;
    right: 1rem;
    width: 20rem;
    padding: 0.5rem;
    padding-top: 2rem;
    border-radius: 0.5rem;
    background: rgba(255, 255, 255, 0.7);
    text-align: end;
    backdrop-filter: blur(4px);
}

.PickLevelPopupCloseBtn
{
    color: black;
    position: absolute;
    top: 0.3rem;
    right: 0rem;
    cursor: pointer;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.3rem;
    transition: all var(--css-duration);
}

.PickLevelPopupCloseBtn:hover
{
    /* box-shadow: 0 -20px 0 0 var(--focuscardbackground) inset;
    color: white; */
}