.forgetpassword_maindiv
{
    background: rgb(243, 219, 219);

    position: fixed;
    bottom: 0vh;
    width: 100vw;
    height: 100vh;
    left: 0vw;

    padding: 1rem;
    border-radius: 0rem;

    
	background: url(./assets/images/hong-kong-night.jpg);
    background-size: cover;
    background-position: center;
    transition: all 1s;
}

.forgetpassword_header
{
    font-size: 3rem;
    text-align: center;

    margin-top: 20vh;
    color: white;
}

.forgetpassword_description
{
    font-size: 1.5rem;
    text-align: center;

    margin-top: 2rem;
    color: white;
}

.forgetpassword_inputdiv
{
    background: rgba(255, 255, 255, 0.4);
    padding: 1rem;
    border-radius: 9999px;

    display: flex;
    flex-direction: row;
    width: 25vw;

    margin: auto;
    margin-top: 2rem;

    color: rgb(255, 255, 255);
    font-size: 1.5rem;
}

.forgetpassword_inputIcon
{
    padding-left: 1.5rem;
}

.forgetpasswordInput
{
    margin:auto;
    margin-left: 2rem;
    width: 20vw;
    outline: 0;
    background: transparent;
    font-size: 1.5rem;
    color: white;
}

.forgetpassword_btn
{
    background: rgba(255, 255, 255, 0.2);
    color: white;
    backdrop-filter: blur(5px);
    padding: 1rem;
    border-radius: 9999px;

    margin-top: 1rem;
    width: 25vw;

    margin: auto;
    margin-top: 2rem;
    font-size: 1.5rem;

    text-align: center;
    cursor: pointer;

    transition: all var(--css-duration);
}

.forgetpassword_btn:hover
{
    background: var(--focuscardbackground);
}

.forgetpassword_msgbox
{
    position: fixed;
    top: 30vh;
    left: 32vw;
    width: 35vw;
    height: 30vh;

    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    color: white;

    border-radius: 0.5rem;
    animation: .5s forgetpopup linear;
}
@keyframes forgetpopup {
    0% {
        transform: translateY(10px);
        opacity: .5;
    }
    100% {

    }
}

.forgetpassword_backBtn
{
    padding:1rem;
    background: rgb(204, 204, 204);
    border-radius: 9999px;
    position: absolute;
    top: 1rem;

    cursor: pointer;

    transition: all var(--css-duration);
}

.forgetpassword_backBtn:hover
{
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 -60px 0 0 rgba(255, 255, 255, 0.4) inset;
    color: white;
}

.forgetpassword_msgbox_closeBtn
{
    position: absolute;
    top: 1rem;
    right: 1rem;

    cursor: pointer;
}

.forgetpassword_msgbox_Msg
{
    margin-top: 10vh;
    text-align: center;
    font-size: 1.5rem;
}

.forgetpassword_acceptBtn
{
    background: var(--focuscardbackground);
    padding: 0.5rem;
    border-radius: 0.3rem;
    padding-left: 1rem;
    padding-right: 1rem;

    position: absolute;
    left: 1rem;
    bottom: 1rem;

    cursor: pointer;
    transition: all var(--css-duration);

    opacity: 0.7;
    width: calc(31vw/2);
    text-align: center;
}

.forgetpassword_acceptBtn:hover
{
    opacity: 1;
}

.forgetpassword_cancelBtn
{
    background: rgba(255, 255, 255, 0.438);
    padding: 0.5rem;
    border-radius: 0.3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    position: absolute;
    right: 1rem;
    bottom: 1rem;

    cursor: pointer;
    transition: all var(--css-duration);

    width: calc(31vw/2);
    text-align: center;
}

.forgetpassword_cancelBtn:hover
{
    background: rgba(255, 255, 255, 0.8);
    color: black;
}

.hideForgetBox
{
    opacity: 0;
    display: none;
}

.hideForgetBtns:hover
{
    opacity: 0;
    display: none;
}

.hideForgetBtns
{
    opacity: 0;
    display: none;
}