.Planner_pagewidget {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;

    max-width: 28rem;
}
.Planner_pageBtn {
    color: var(--optiontext);
    opacity: .8;
    padding: .3rem;
    border-radius: .3rem;
    min-width: 2rem;
    font-size: .8rem;
    text-align: center;
    cursor: pointer;
    transition: all var(--css-duration);
}
.Planner_pageBtn:hover {
    box-shadow: 0 -30px 0 0 var(--focuscardbackground) inset,0 4px 3px 0 rgba(0,0,0,.1);
    color: #fff;
    opacity: 1;
}
.Planner_pageBtn.current {
    box-shadow: 0 -30px 0 0 var(--focuscardbackground) inset,0 4px 3px 0 rgba(0,0,0,.1);
    color: #fff;
    opacity: 1;
}