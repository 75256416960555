.SettingPage1Container
{
    height: calc(100vh - 14rem);
    /* background: rgba(255, 255, 255, 0.8); */
    background: var(--cardbodybackground);
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 1rem;
    padding-top: 2rem;
    overflow-y: scroll;
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(200px);
}

.emptyspaceMaker
{
    height: 80vh;
    background: grey;
    width: 100%;
}