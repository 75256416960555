.Lift_DrStat_optBtn {
    background: var(--colortheme1);
    font-size: 1rem;
    align-self: center;
    color: white;
    padding: .5rem;
    width: 3rem;
    text-align: center;
    border-radius: .3rem;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.18));
    cursor: pointer;
    transition: all .5s;
}
.Lift_DrStat_optBtn:hover {
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}


.Lift_DrStat_optPopup {
    position: absolute;
    top: 0;
    background: var(--colortheme2);
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.18));
    width: 30rem;
    height: 15rem;
    padding: 1rem;
    font-size: 1.2rem;
    z-index: 100;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.LiftDrSt_pophead {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.LiftDrSt_popheadtxt {
    font-size: 1.5rem;
}
.LiftDrSt_popheadbtn {
    cursor: pointer;
}


.LiftDrSt_popbody {
    height: 100%;

    display: flex;
}
.LiftDrSt_popbodyL {
    width: 50%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}
.LiftDrSt_popbodyR {
    width: 50%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
}


.LiftDrSt_popCheckbox1, .LiftDrSt_popCheckbox2 {
    align-self: center;
    color: white;
    padding: .5rem;
    width: 8rem;
    text-align: center;
    border-radius: .3rem;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.18));
    cursor: pointer;
    transition: all .3s ease-in-out;
}
.LiftDrSt_popCheckbox1 {
    background: linear-gradient(to right, rgb(0, 123, 254), rgb(0, 123, 254) calc(50% - 5px), white calc(50% - 5px), white calc(50% + 5px), rgb(0, 184, 150) calc(50% + 5px), rgb(0, 184, 150));
    background-size: 200% 100%;
}
.LiftDrSt_popCheckbox2 {
    background: linear-gradient(to right, rgb(254, 174, 0), rgb(254, 174, 0) calc(50% - 5px), white calc(50% - 5px), white calc(50% + 5px), rgb(184, 0, 163) calc(50% + 5px), rgb(184, 0, 163));
    background-size: 200% 100%;
}
.LiftDrSt_popCheckbox1.Daily {
    background-position: 90%;
    animation: TextUpdate 0.3s ease-in-out;
}
.LiftDrSt_popCheckbox1.Month {
    background-position: 10%;
    animation: TextUpdate2 0.3s ease-in;
}
.LiftDrSt_popCheckbox2.Table {
    background-position: 90%;
    animation: TextUpdate 0.3s ease-in;
}
.LiftDrSt_popCheckbox2.Chart {
    background-position: 10%;
    animation: TextUpdate2 0.3s ease-in;
}
@keyframes TextUpdate {
    0% {
        color: white;
    }
    49% {
        padding: 0.5rem;
        padding-right: 7rem;
        color: rgba(255, 255, 255, 0);
    }
    50% {
        padding: 0.5rem;
        padding-left: 7rem;
        color: rgba(255, 255, 255, 0);
    }
    51% {
        padding: 0.5rem;
        padding-left: 7rem;
        color: rgba(255, 255, 255, 0);
    }
    100% {
        color: white;
        padding: 0.5rem;
    }
}
@keyframes TextUpdate2 {
    0% {
        color: white;
    }
    49% {
        padding: 0.5rem;
        padding-left: 7rem;
        color: rgba(255, 255, 255, 0);
    }
    50% {
        padding: 0.5rem;
        padding-right: 7rem;
        color: rgba(255, 255, 255, 0);
    }
    51% {
        padding: 0.5rem;
        padding-right: 7rem;
        color: rgba(255, 255, 255, 0);
    }
    100% {
        color: white;
        padding: 0.5rem;
    }
}

.LiftDrSt_popType {
    align-self: center;
    color: white;
    background: var(--colortheme1);
    padding: .5rem;
    width: 10rem;
    /* text-align: center; */
    border-radius: .3rem;
    filter: drop-shadow(0 5px 3px rgba(0, 0, 0, 0.3));
    cursor: pointer;
    transition: color .5s ease-in-out;
    opacity: 0.5;
}
.LiftDrSt_popType.Door {
    background: rgb(0, 175, 255, 1);
}
.LiftDrSt_popType.Startup {
    background: rgb(255, 171, 0, 1);
}
.LiftDrSt_popType.Direct {
    background: rgb(169, 0, 97, 1);
}
.LiftDrSt_popType.highlight {
    opacity: 1;
}