.AT_header_Container
{
    background: rgba(0, 0, 0, 0.3);
    width: calc(100% - 1rem);
    margin-left: 0.5rem;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    font-size: 1.1rem;
}

.AT_header_TxtInput
{
    padding: 0.4rem;
    padding-left: 1.5rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0.3rem;
    border-radius: 999rem;
    width: 20vw;
}

.AT_header_selectOption
{
    margin-left: 1rem;
    padding: 0.1rem;
    padding-left: 1rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0.3rem;
    border-radius: 999rem;
}

.AT_header_searchBtn
{
    margin-left: 1rem;
    padding: 0.5rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    border-radius: 0.4rem;
    background: var(--colortheme1);
    color: white;
    transition: all var(--css-duration);
    cursor: pointer;
}
.AT_header_searchBtn:hover
{
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}


/* Small size screen force resize css */
@media only screen and (max-height: 790px) {
    /* Alarm Trigger Header */
    .AT_header_TxtInput {
        width: 13rem;
    }
    .AT_header_Container {
        margin-top: 0.5rem;
    }
}