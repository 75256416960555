.TestP {
    width: 100vw;
    height: 100vh;
    background: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.TestP_head {
    font-size: 3rem;
    letter-spacing: 2px;
    color: grey;
    min-width: 100%;
    text-align: center;
}

.TestP_settings {
    width: calc(100% - 2rem);
    min-height: 15rem;
    padding: 1rem;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
}
.TestP_setRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    font-size: 1.5rem;
    letter-spacing: 2px;
}
.TestP_setRowtxt {
    width: 15rem;
}
.TestP_setRowvalue {
    width: 10rem;
}
.testP_points {
    width: 20rem;
    background: transparent;
}

.TestP_displayWidget {
    width: calc(100% - 2rem);
    height: 100%;
    padding: 1rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.TestP_widgetItem {
    background: rgb(0, 0, 0, 0.1);
    border-radius: 0.5rem;
}