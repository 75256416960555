.WLeak_summary {
    width: 100%;
    height: 100%;

    box-shadow: 0 15px 10px 0 rgba(0, 0, 0, 0.05), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    background: var(--cardbodybackground);

    border-radius: 0.3rem;

    animation: floor4readingcardsOnload 1s ease-out;
}
.WLeak_Summary_head {
    width: 100%;
    padding: .5rem;
    padding-left: 1rem;
    font-size: 1rem;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;

    letter-spacing: 0.5px;
    
    color: var(--cardtitletext);
    background: var(--cardtitle);
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.174));
}
.WLeak_Summary_inner {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    height: calc(100% - 2rem);
}
.WLeak_Summary_Display {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-left: 1rem;
    margin-right: 1rem;
    padding: 1rem;
    width: calc(100% - 2rem);

    font-size: 1.8rem;
    border-radius: .5rem;

    color: white;
    background:rgb(0, 208, 255);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.174);

    transition: all var(--css-duration);
}

.WLeak_Summary_normal {
    letter-spacing: 1px;
    text-align: center;    
}
.WLeak_Summary_Leakage {
    background: red;
    font-size: 1.5rem;
    box-shadow: 0 5px 15px 0 rgba(255, 0, 0, .5);

    animation: Alarm_On 1s infinite;
}

@keyframes Alarm_On {
    0% {
    }
    50% {
    }
    100% {
    }
}