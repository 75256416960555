.botCarbonCard {
    height: 10.5rem;
    aspect-ratio: 1.5;

    margin-right: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.3rem;
    padding: 0.8rem;

    background: var(--cardbodybackground);
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%), 0 3px 5px 0 rgb(0 0 0 / 5%);
    color: var(--optiontext);

    animation: cardOnload .5s linear;
    transition: all var(--css-duration);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
        font-size: 1.2rem;
        letter-spacing: 1px;
    }
    .status {
        height: 3rem;
        color: white;
        box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        font-size: 1.7rem;
        letter-spacing: 2px;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bot {
        letter-spacing: 1px;
    }
}