.powerCardReadingDiv
{
   background: transparent;
   position: absolute;
   bottom: 0rem;
   width: 100%;
   height: 35%;
   text-align: center;

   display:flex;
   flex-direction: row;
}

.powerCardReadingTxt
{
   font-size: 2.5rem;
   margin:auto;
   margin-right: 0.5rem;
   color: var(--optiontext);
}
.powerCardReadingUnit
{
   margin:auto;
   margin-left:0;
   font-size:0.8rem;
   height: 100%;
   margin-top: 2rem;
   color: var(--optiontext);
}

.powerCardChart
{
   width: 100%;
   height: 100%;
}

.SummaryCardACPower_Loading {
   display: flex;
   width: 100%;
   height: 100%;
}
.width0 {
   width: 0;
}

.TimeoutText {
   text-align: center;
   align-self: center;
   margin: auto;
   letter-spacing: 2px;
   font-size: 1.5rem;
   padding-top: 3rem;
}