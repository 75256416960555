.Toilet_admin_occ_chartMain {
    width: 100%;
    height: 10rem;

    display: flex;
    flex-direction: column;
}

/* .Toilet_admin_occ_barlist {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.Toilet_admin_occ_bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 0.7rem;
    height: calc(100% - 1rem);
}

.Toilet_admin_occ_bar .reading {
    font-size: 10px;
    letter-spacing: -1px;
    width: 1rem;
    text-align: center;
}
.Toilet_admin_occ_bar .reading.moveup {
    transform: translateY(-10px);
}
.Toilet_admin_occ_bar .timeline {
    width: 100%;
    height: 2px;
    background: grey;
}
.Toilet_admin_occ_bar .bar {
    width: 0.7rem;
    height: 100%;
    filter: drop-shadow(4px 7px 2px rgb(0, 0, 0, 0.2));

    transition: 0.4s all ease-in-out;
    animation: ToiletAdminBar 0.5s ease-in-out;
}
@keyframes ToiletAdminBar {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
    }
}

.Toilet_admin_occ_bar .timeValue {
    font-size: 0.8rem;
    margin-top: 5px;
    opacity: 0.6;
} */