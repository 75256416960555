.Toilet_Cond_main {
    width: 100%;
    height: 100%;
    background: white;
    color: rgba(0,0,0,0.7);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.3);
    border-radius: 0.5rem;

    padding: 1.5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
}

.Toilet_Cond_head {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 2px;

    display: flex;
    justify-content: space-between;
}

.Toilet_Cond_inner {
    width: 100%;
    height: 100%;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.Toilet_Cond_item {
    width: calc(33% - 1rem);
    height: 50%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    gap: 0.5rem;
}
.Toilet_Cond_card {
    background: grey;
    border: solid white 5px;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,0.18), 0 5px 5px 0 rgba(0,0,0,0.1) inset;
    height: 100%;
    border-radius: 1rem;

    font-size: 3.5rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Toilet_Cond_card sup {
    font-size: 1.3rem;
}


.Toilet_progresscircle {
    background: rgb(67, 67, 67);
    /* background: conic-gradient(from 0deg, #fff, #fff 60%, rgba(0, 0, 0, 0.2) 60%); */
    border-radius: 999px;
    height: 7rem;
    width: 7rem;
    
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
    transition: all 0.5s;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Toilet_progresscircle .detail {
    position: absolute;
    font-size: 2rem;
    background: rgb(255, 255, 255, 0.4);
    color: grey;
    border-radius: 999px;
    backdrop-filter: blur(2px);
    
    height: calc(7rem - 20px);
    width: calc(7rem - 20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    animation: 0.3s ease-in-out ProgressPercentShow;
}
.Toilet_progresscircle .detail_tick {
    position: absolute;
    font-size: 3rem;
    background: rgb(255, 255, 255, 0);
    color: rgb(0, 255, 34, 0.6);
    border-radius: 999px;
    
    height: calc(7rem - 20px);
    width: calc(7rem - 20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    animation: 0.3s ease-in-out ProgressPercentShow;
}
@keyframes ProgressPercentShow {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
    }
}
.Toilet_Cond_card .detail2 {
    position: absolute;
    font-size: 2rem;
    background: rgb(255, 255, 255, 0.7);
    color: grey;
    backdrop-filter: blur(2px);
    
    height: 9rem;
    width: 9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    animation: 0.3s ease-in-out ProgressPercentShow;
}
.Toilet_Cond_card .detail2 .row {
    display: flex;
    align-items: center;
    width: 100%;
}
.Toilet_progressIcon {
    background: grey;
    color: white;
    border-radius: 999px;
    font-size: 3rem;
    box-shadow:  0 0 5px 0 rgba(0,0,0,0.5) inset;

    height: calc(7rem - 10px);
    width: calc(7rem - 10px);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.Toilet_Cond_card.first {
    background: rgb(255, 238, 197);
    color: grey;
}
.Toilet_Cond_card.second {
    background: rgb(253, 206, 139);
    color: grey;
}
.Toilet_Cond_card.third,
.Toilet_Cond_card.sixth {
    background: rgb(171, 224, 249);
    color: white;
}
.Toilet_Cond_card.fourth {
    background: rgb(2, 177, 72);
    color: grey;
}
.Toilet_Cond_card.fourth .Toilet_progressIcon {
    background: rgb(2, 177, 72);
}
.Toilet_Cond_card.fifth {
    background: rgb(232, 90, 1);
    color: grey;
}
.Toilet_Cond_card.fifth .Toilet_progressIcon {
    background: rgb(232, 90, 1);
}

.Toilet_Cond_card.third .airQ_1,
.Toilet_Cond_card.third .airQ_2,
.Toilet_Cond_card.third .airQ_3,
.Toilet_Cond_card.sixth .Clean_1,
.Toilet_Cond_card.sixth .Clean_2,
.Toilet_Cond_card.sixth .Clean_3 {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.Toilet_Cond_card.third .airQ_1,
.Toilet_Cond_info_airQ_1 {
    background-image: url(../../../assets/images/toilet/wind_1.jpg);
}
.Toilet_Cond_card.third .airQ_2,
.Toilet_Cond_info_airQ_2 {
    background-image: url(../../../assets/images/toilet/wind_2.jpg);
}
.Toilet_Cond_card.third .airQ_3,
.Toilet_Cond_info_airQ_3 {
    background-image: url(../../../assets/images/toilet/wind_3.jpg);    
}

.Toilet_Cond_card.sixth .Clean_1,
.Toilet_Cond_info_clean_1 {
    background-image: url(../../../assets/images/toilet/Clean_1.jpg);
}
.Toilet_Cond_card.sixth .Clean_2,
.Toilet_Cond_info_clean_2 {
    background-image: url(../../../assets/images/toilet/Clean_2.jpg);
}
.Toilet_Cond_card.sixth .Clean_3,
.Toilet_Cond_info_clean_3 {
    background-image: url(../../../assets/images/toilet/Clean_3.jpg);
}

.Toilet_Cond_info_clean_1,
.Toilet_Cond_info_clean_2,
.Toilet_Cond_info_clean_3,
.Toilet_Cond_info_airQ_1,
.Toilet_Cond_info_airQ_2,
.Toilet_Cond_info_airQ_3 {
    width: 3rem;
    height: 3rem;
    background-size: contain;
}

.Toilet_Cond_infoBtn {
    width: 2rem;
    height: 2rem;
    font-size: 2rem;
    border-radius: 999px;
    box-shadow: 0 3px 5px rgba(0,0,0,0.3);

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
}
.Toilet_Cond_info {
    align-self: flex-end;
    position: absolute;
    height: 25rem;
    width: 31rem;
    padding: 1rem;
    overflow-y: auto;

    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    border: solid rgba(255, 255, 255, 0.4) 5px;
    border-radius: 0.5rem;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.3);

    z-index: 3;

    animation: Toilet_Cond_infoOnload 0.5s cubic-bezier(0.4,-0.18, 0.32, 1.43);
}
@keyframes Toilet_Cond_infoOnload {
    0% {
        opacity: 0;
        transform: translateX(3rem);
    }
    100% {

    }
}

.Toilet_Cond_info legend {
    color: rgb(191, 0, 191);
    text-shadow: 3px 5px 5px rgba(0, 0, 0, 0.3);
    opacity: 1;
    letter-spacing: 2px;
    font-weight: 900;
}
.Toilet_Cond_info .level {
    color: grey;
    padding: 1rem;
    letter-spacing: 3px;
    font-weight: 900;
}
.Toilet_Cond_info .level .desc {
    color: grey;
    font-weight: 100;
    letter-spacing: 1px;
}
.Toilet_Cond_closeBtn {
    position: fixed;
    background: transparent;
    color: purple;
    filter: drop-shadow(3px 5px 3px rgba(0, 0, 0, 0.3));
    font-size: 1.5rem;
    letter-spacing: 2px;
    font-weight: 900;
    margin-left: 28rem;
    margin-top: 1rem;

    cursor: pointer;
    z-index: 100;
}