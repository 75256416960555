.Toilet_ad_floorplanMain .head {
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    align-items: center;

    font-size: 1.2rem;
}

.Toilet_ad_floorplanMain .head .headitem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.Toilet_ad_floorplanMain .head .headitem .headitem_color {
    width: 1.9rem;
    height: 1.9rem;

    background: white;
    border: solid 1px rgba(0, 0, 0, 0.4);
}

.Toilet_ad_floorplanMain .head .headitem .headitem_txt { 
    min-width: 4rem;
 }

.Toilet_ad_floorplan {
    min-width: 42rem;
    min-height: 23rem;
    max-width: 42rem;
    max-height: 23rem;
    background: grey;
    /* background: url('../../../../assets/images/toilet/PY_male.jpg'); */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    align-self: center;
}

.Toilet_ad_floorplan_Pos {
    position: absolute;
    opacity: 0.8;

    display: flex;
    justify-content: center;
    align-items: center;
}


.Toilet_ad_floorplan_site {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    font-size: 1.2rem;

    cursor: pointer;
}
.Toilet_ad_floorplan_site .icon {
    font-size: 2rem;
    display: none;
}
.Toilet_ad_floorplan_site .title {
    font-weight: 900;
    min-width: 20rem;
    max-width: 20rem;
    padding: 5px;
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 0.3rem;

    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
}
.Toilet_ad_floorplan_site .level {
    color: black;
    text-align: center;
    width: 5rem;
    padding: 5px;
    border-radius: 0.3rem;
    
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
}


@media only screen and (max-width: 1280px) {
    .Toilet_ad_floorplan {
      max-width: 30rem;
      min-width: 30rem;
      max-height: 16.5rem;
      min-height: 16.5rem;
    }
}