.ColdboxSetPt_TableHead {
    padding: .5rem .5rem .5rem 1rem;
    letter-spacing: 1px;
    background: hsla(0,0%,50.2%,.3);
    color: var(--optiontext);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.CdbxSetPt_prevTitle {
    margin-left: 42rem;
}