.ColdboxLineChart_outside {
    height: 100%;
    border-radius: .5rem;
    background: var(--cardbodybackground);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2), 0 5px 15px 0 rgba(0, 0, 0, .15);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.Coldboxlinechart_header {
    width: 100%;
    padding: .5rem;
    background: var(--cardtitle);
    color: var(--divheadertext);
    border-radius: .3rem;
    box-shadow: 0 10px 10px 0 rgba(0,0,0,.18), 0 5px 5px 0 rgba(0,0,0,.1);
}
.Coldboxlinechart_header .headericon {
/*  */
}
.Coldbox_Linechart_div {
    height: 100%;
}
.Coldbox_searchbar_div {
    background: rgba(0, 0, 0, .1);
    padding: .5rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Coldbox_searchbar_div .react-datepicker-wrapper .react-datepicker__input-container input {
    color: white;
}
.Coldbox_searchbar_title {
    color: var(--optiontext);
}
.ColdboxLineChartOption {
    margin-left: 1rem;
    background: rgba(0,0,0,.432);
    color: #fff;
    padding: .3rem;
    border-radius: .3rem;
    transition: all var(--css-duration);
}
.ColdboxLinechart_searchbutton {
    background: var(--colortheme1);
    border-radius: .5rem;
    padding: .5rem 1rem;
    font-size: 1.2rem;
    letter-spacing: 0;
    outline: 0;
    transition: all var(--css-duration);
    color: #fff;
    margin-left: 1rem;
}
.ColdboxLinechart_maindiv {
    height: calc(100% - 5rem);
}
.ColdboxlineChartLoading {
    position: absolute;
    top: calc((100% - 2rem) / 2);
    left: calc((100% - 2rem) / 2);
    font-size: 2rem;
}
.ColdboxlineChartLoadingIcon {
    color: rgb(118, 118, 118);
}

.Coldbox_linechart_Downloadcsv {
    background: var(--colortheme1);
    border-radius: .5rem;
    padding: .3rem 1rem;
    font-size: 1.2rem;
    letter-spacing: 0;
    outline: 0;
    transition: all var(--css-duration);
    color: #fff;
    margin-left: 1rem;

    cursor: pointer;
}