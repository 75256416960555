@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/font/Nunito-Light.ttf'); /* IE9 Compat Modes */
  src: local('Open Sans'), local('OpenSans'),
       url('./assets/font/Nunito-Light.ttf') format('truetype'); /* Safari, Android, iOS */
}

body {
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Segoe UI";
  font-family: 'Quicksand', sans-serif;
  font-family: 'Nunito';

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  min-height: 768px;
  min-width: 1024px;
  background-image: radial-gradient(ellipse at bottom, transparent, var(--colortheme2) 90%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* Prevent Highlight the text in this website */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

body, html {
  margin: 0;
  scroll-behavior: smooth;

  min-height: 768px;
  min-width: 1024px;
}

@media (max-width: 2100px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  html {
    font-size: 13px;
  }
}

#root {
  height: 100vh;
  min-height: 768px;
  min-width: 1024px;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

:root {
  --colortheme1: #123342;
  --colortheme2: #e6dbc9;
  --colortheme2: #FFFFFF;
  --loginbg1: #2e3830;
  --loginbg2: rgba(3, 114, 51, 0);
  --loginbg3: #2e3830;
  --maindiv-color: #e6dbc9;
  --maindiv-color2: #e6dbc985;
  --focuscardbackground: #01655b;

  --divheader: #878683;
  --divheadertext: white;
  --cardtitle: #AAA9A7;
  --cardtitletext: white;/* was black */
  --cardbodybackground: #ffffff87; /* some transparent on card background */
  --cardbodytext: black;
  --focuscardtext: white;

  --css-duration: .5s;
  --polygoncolor1: #ff00ff26;
  --polygoncolor2: #2fe3d026;
  --polygoncolor3: #07ff0026;
  --polygoncolor4: #ff8a0026;
  --polygoncolor5: #8200ff26;
  --polygoncolor6: #00bbff26;
  --polygoncolor7: #2fe3d026;
  --polygoncolor8: #07ff0026;
  --polygoncolor9: #ff8a0026;
  --polygoncolor10: #8200ff26;
  --polygoncolord: #00bbff26;

  --dragcolor: rgba(120, 255, 179, 0);

  --optiontext: black;

  --slideBG_1: rgba(255,255,255,1);
  --slideBG_2: rgba(255,255,255,.7);
  --slideBG_3: rgba(255,255,255,0.5);
}

.DarkMode {
  --colortheme1: #4e4e4e;
  --colortheme2: #2e2e2e;
  --maindiv-color: rgb(69, 69, 69);
  --maindiv-color2: rgb(84, 84, 84);
  /*--focuscardbackground: #505050;*/
  --divheader: #505050;
  /*--divheadertext: white;*/
  --cardtitle: #676767;
  /*--cardtitletext: white;*/
  --cardbodybackground: rgb(255, 255, 255, .05);
  /*--cardbodytext: black;
  --focuscardtext: white;*/

  --optiontext: rgb(220, 220, 220);
}

/* scroll bar start */
/* set width, oolor */
/* set width, oolor */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* scroll bar background color */
::-webkit-scrollbar-track {
  border-radius: 10px;
  border-radius: 10px;
  background: linear-gradient(rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.336));
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
/* bar color (not using) */
::-webkit-scrollbar-thumb {
  /*background: linear-gradient(to top, rgba(0, 0, 0, 0.2), var(--focuscardbackground), rgba(0, 0, 0, 0.2));*/
  background:var(--focuscardbackground);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
  border-radius: 10px;
}
/* scroll bar end */


/* inputText highlight color */
::selection {
  color: white;
  background: var(--focuscardbackground);
}




/* Small size screen force resize css */
@media only screen and (max-height: 790px) {
  /* Since need to overwrite the css on existing css file, cannot put all the response style here */
}