.RealTimeAlertContainer {
    color: rgb(255, 255, 255);
    padding: 5px;
    padding-left: 1.5rem;
    width: 100vw;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    z-index: 500;
    cursor: pointer;
    font-size: 1.3rem;

    transition: all var(--css-duration);
    animation: .5s debugalertPopup linear;

    font-size: 15px;
}
@keyframes debugalertPopup {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.blackBottomAlert {
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3px);
}
.RealTimeAlertIcon {
    margin-right: 1rem;
}
.RealTimeAlert_newAlertContainer {
    position: fixed;
    top: 5rem;
    right: 1rem;
    background: rgba(255, 255, 255, 0.8);
    z-index: 100;
    padding: 1rem;
    border-color: var(--colortheme1);
    border-width: 1px;
    width: 20rem;
    border-radius: 0.3rem;
    cursor: pointer;

    transition: all var(--css-duration);
}
.RealTimeAlert_newAlertTitle {
    color: var(--focuscardbackground);
    font-size: 1.3rem;
}
.RealTimeAlert_newAlertMsg {
    font-size: 1rem;
    margin-top: 0.5rem;
}
.RealTimeAlert_newAlertDatetime {
    margin-top: 0.5rem;
    font-size: 0.8rem;
}
.hideBottomAlert {
    left: -50vw;
    display:none;
}
.hideNewAlertPopup {
    top: -10rem;
    opacity: 0;
}
.Urgent {
    background: rgb(255, 0, 0);
    color: white;
}