.ExportExcelBtn {
    background: var(--colortheme1);
    font-size: 1rem;
    align-self: center;
    color: white;
    padding: .5rem;
    width: 3rem;
    text-align: center;
    border-radius: .3rem;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.18));
    cursor: pointer;
    transition: all .5s;
}

.ExportExcelBtn:hover {
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}





.ExportExcelWindow {
    position: absolute;
    width: 100%;
    background: var(--colortheme2);
    color: var(--optiontext);
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.18));
    font-size: 1.2rem;
    z-index: 100;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem;
    align-items: center;

    z-index: 100;
}
.ExportExcel_CancelBtn {
    background: var(--colortheme1);
    align-self: center;
    color: white;
    padding: .5rem;
    min-width: 8rem;
    font-size: 0.8rem;
    text-align: center;
    border-radius: .3rem;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.18));
    cursor: pointer;
    transition: all .5s;
}
/* .ExportExcel_img {
    min-width: 5rem;
    height: 100%;
    border-radius: 0.3rem 0 0 0.3rem;
    background: url('../../../../../assets/images/hong-kong-night.jpg'), linear-gradient(to right, var(--focuscardbackground), var(--focuscardbackground));
	background-size: cover;
	background-position: center;
    background-blend-mode:luminosity;
} */