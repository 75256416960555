.Notification_maindiv {
    background: linear-gradient(180deg, var(--maindiv-color2), var(--maindiv-color));
    width: 88vw;
    height: calc(90vh);
    min-width: 1024px;
    min-height: 768px;
    margin: auto;
    margin-bottom: 1rem;
    margin-top: 1rem;

    border-radius: 0.5rem;
    padding: 1.5rem;

    display: flex;
    flex-direction: column;
    justify-content: start;
}

.Notification_header {
    width: 100%;
    background: linear-gradient(to right, var(--divheader), var(--divheader));
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding:0.5rem;

    border-radius:0.3rem;
    color:white;
    background: var(--cardtitle);
}

.Notification_icon
{
    margin:auto;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 1rem;
}

.Notification_searchdiv {
    background: white;
    color: rgb(63, 63, 63);
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.24) inset;
    border-radius: 9999px;
    padding:0rem;

    margin:auto;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 1rem;
}

.Notification_searchinput {
    outline: 0;
    margin-left: 1rem;
    background: transparent;
}

.Notification_searchicon
{
    background: transparent;
    cursor: pointer;
    padding: 0.3rem;
    padding-right: 0.4rem;
    border-radius: 9999px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-right: 0;
    margin-left: 0rem;

    transition: all var(--css-duration);
}

.Notification_searchicon:hover
{
    box-shadow: 100px 0 0 0 var(--focuscardbackground) inset;
    color: white;
}

.Notification_searchDaterange
{
    display:flex;
    flex-direction: row;
    margin:auto;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 1rem;
}


.Notification_txt
{
    margin:auto;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    font-size: 1rem;    
}

.Notification_searchOptiondiv {
    display:flex;
    flex-direction: row;
    align-items: center;
}

.Notification_startdatetimepicker
{
    background: rgb(255, 255, 255);
    border-radius: 9999rem;
    box-shadow: 3px 5px 5px 0 rgba(0, 0, 0, 0.2) inset;
}

.Notification_enddatetimepicker
{
    background:white;
    border-radius: 9999rem;
    box-shadow: 3px 5px 5px 0 rgba(0, 0, 0, 0.2) inset;
}
.react-datepicker__input-container input
{
    width: 15rem;
}
.Notification_startdatetimepicker .react-datepicker-wrapper .react-datepicker__input-container input
{
    background: transparent;
    border-width: 0;
}

.Notification_enddatetimepicker .react-datepicker-wrapper .react-datepicker__input-container input
{
    background: transparent;
    border-width:0;
}

.Notification_searchDaterangeBtn
{
    background: var(--focuscardbackground);
    color:white;
    margin:auto;
    margin-left: 0.5rem;
    padding: 0.4rem;
    padding-left:0.7rem;
    padding-right: 0.7rem;
    border-radius: 0.4rem;

    cursor: pointer;
    opacity: 0.7;

    transition: all var(--css-duration);
}

.Notification_searchDaterangeBtn:hover
{
    opacity: 1;
}

.NotificationRefreshBtn {
    margin-left: 0.5rem;
    width: 3rem;
    height: 2rem;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--focuscardbackground);
    color:white;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3);

    transition: all var(--css-duration);
    cursor: pointer;
}

.NotificationRefreshBtn:hover {
    transform: translateY(-5px);
}

.Notification_searchStatus
{
    display: flex;
    flex-direction: row;
}
.Notification_searchStatus_title
{
    margin: auto;
}
.Notification_searchStatusOption,
.Notification_searchOption {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    max-height: 1.5rem;

    border-radius: 99px;
    padding-left: 0.5rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}
.no_recordRow {
    text-align: center;
    background: rgba(255, 255, 255, 0.381);
    align-self: center;
    padding: 1rem;
    font-size: 1rem;
    letter-spacing: 2px;
}

.Notification_backBtn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    left: 3rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 99px;

    background: var(--colortheme1);
    color: white;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.4);

    transition: all var(--css-duration);
    cursor: pointer;
}

/* Small size screen force resize css */
@media only screen and (max-height: 790px) {
    .Notification_header {
        justify-content: start;   
    }
    
    .Notification_searchDaterange {
        justify-content: start;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 0.5rem;
    }
}
@media only screen and (max-width: 1000px) {
    .Notification_backBtn {
        display: none;
    }
}