.SetDarkModeContainer
{
    background: var(--cardbodybackground);
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    width: 10vw;
    padding:0.5rem;

    box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.05);
    color: var(--optiontext);
}

.SettingOnOffControl
{
  margin: 1rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.493);
  width: 5rem;
  border-radius: 9999px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0);
  transition: all var(--css-duration);
  cursor: pointer;

  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.445) inset;
}
.OnOffState
{
  background: rgb(0, 255, 149);
}

.SettingOnOffPointer
{
  border-radius: 9999px;
  width: 2rem;
  height: 2rem;
  background: linear-gradient(to bottom left, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.9));
  position: absolute;
  margin-top: -1rem;
  margin-left: -0.9rem;

  transition: all 0.2s;
}
.OnOffMarginLeft
{
  margin-left: 1.7rem;
}