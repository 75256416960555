.ProgressWidget,
.ProgressCir_R,
.ProgressCir_L,
.ProgressCir_LBlock,
.ProgressCir_Center {
    width: 10rem;
    height: 10rem;
    border-radius: 999px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: all 1s ease-in-out;
}

.ProgressCir_R,
.ProgressCir_L,
.ProgressCir_LBlock,
.ProgressCir_Center {
    position: absolute;
}

.ProgressWidget,
.ProgressCir_LBlock {
    width: 10.1rem;
    height: 10.1rem;
}

.ProgressWidget {
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
}
.ProgressCir_LBlock {
    z-index: 2;
}

.ProgressCir_Center {
    z-index: 3;
    width: calc(10rem - 10px);
    height: calc(10rem - 10px);
    font-size: 3rem;
    box-shadow: inset 0 0 10px 0 rgb(0 0 0 / 20%);
    text-align: center;
}



.ProgressCir_Center .desc {
    font-size: 1.2rem;
}

.ProgressCir_Center .Reading sub {
    font-size: 1.2rem;
}