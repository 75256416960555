.SingleDatePickerClass {
    max-width: 7rem;
}
.SingleDatePickerClass * {
    max-width: 100%;
}
.SingleDateLiftSummary_input {
    padding: .5rem;
    padding-left: .7rem;
    width: 6rem;

    background: var(--cardbodybackground);
    color: white;
    opacity: .9;
    border-radius: .5rem;
}