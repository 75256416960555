.DeviceList_main {
    position: absolute;
    background: rgba(255, 0, 0, 0);
    position: absolute;
    top: 5.5rem;
    right: 2rem;
    height: 15rem;

    display: flex;
    flex-direction: column;
}

.DeviceList {
    /* color: grey; */
    background: rgb(100, 100, 100, 0);
    /* position: absolute; */
    top: 5.5rem;
    left: 4rem;
    min-height: 15rem;

    display: flex;
    flex-direction: column;
    overflow: auto;
}
.deviceItemDiv {
    background: rgb(255, 255, 255, 1);
    /* background: var(--cardbodybackground); */
    color: var(--toptiontext);
    margin-bottom: 0.3rem;
    padding: 0.3rem;
    cursor: pointer;
    transition: all var(--css-duration);
    border-radius: 0.5rem;
    text-align: center;

    animation: deviceOnload .5s linear;
}
@keyframes deviceOnload {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }
    100% {
        opacity: 1;
    }
}
.deviceItemDiv:hover {
    box-shadow: 0 -50px 0 0 rgb(255, 255, 255, 0.4) inset;
}
.DeviceList_hideBtn {
    margin-top: .5rem;
    padding: .5rem;
    text-align: center;
    background: var(--colortheme1);
    color: white;
    border-radius: .3rem;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all var(--css-duration);
    animation: dswhoBtnOnload .5s alternate;
}
.DeviceList_hideBtn:hover {
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}
.DeviceList_ShowBtn {
    position: absolute;
    top: 5.5rem;
    right: 2rem;
    margin-top: .5rem;
    padding: .5rem;
    text-align: center;
    background: var(--colortheme1);
    color: white;
    border-radius: .3rem;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all var(--css-duration);

    animation: dswhoBtnOnload .5s alternate;
}
@keyframes dswhoBtnOnload {
    0% {
        opacity: 0;
        transform: scale(.5);
    }
    100% {
        opacity: 1;
    }
}
.DeviceList_ShowBtn:hover {
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}

.YAxis_option {
    position: absolute;
    top: 5.5rem;
    left: 4rem;

    animation: YAxisOption .5s alternate;
}
@keyframes YAxisOption {
    0% {
        opacity: 0;
        transform: scale(.5);
    }
    100% {
        opacity: 1;
    }
}
.YAxis_auto {
    padding: .5rem;
    text-align: center;
    background: var(--colortheme1);
    color: white;
    border-radius: .3rem;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all var(--css-duration);
    animation: dswhoBtnOnload .5s alternate;
}
.YAxis_default {
    margin-top: .5rem;
    padding: .5rem;
    text-align: center;
    background: var(--colortheme1);
    color: white;
    border-radius: .3rem;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all var(--css-duration);
    animation: dswhoBtnOnload .5s alternate;
}

.YAxisHighlight {
    box-shadow: 0 -40px 0 0 var(--focuscardbackground) inset;
}
.hideenDevice {
    background: rgb(210, 210, 210);
    color: black;
    text-decoration: line-through;
}