.scrollIconList {
    display: flex;
    gap: 5px;

    .cardIcon {
        min-width: 30px;
        min-height: 10px;
        cursor: pointer;

        transition: all 0.5s;
    }
}