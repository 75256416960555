.SetColorThemeContainer
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: var(--cardbodybackground);
    color: var(--optiontext);
    border-radius: 1rem;

    box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.05);
    /* box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.18), 0 5px 5px 0 rgba(0, 0, 0, 0.1); */
    padding-bottom: 10px;
}

.SetColorThemeTitle
{
    font-size: 1.5rem;
    position: absolute;
    margin-top: -2rem;
    /* background: rgba(255, 255, 255, 0.5); */
}

.SettingColor
{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: auto;
    margin-left: 0.5rem;
    
    transition: all var(--css-duration);
    opacity: 0.5;
    cursor: pointer;
    background: transparent;
    transform: translateY(10px);
}

.SettingColorRow
{
    width: 5vw;
    height: 5vh;
    font-size: 0rem;
    border-radius: 0.5rem;
    margin-bottom: 0.1rem;
}

.SettingColor:hover
{
    opacity: 1;
    transform: translateY(0);
}

/*.SetColorThemeSaveBtn
{
    cursor: pointer;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background: transparent;
    color: black;
    border-radius: 0.5rem;
    margin: 0.2rem;
    height: 2.6rem;
    font-size: 1.5rem;
    transition: all var(--css-duration);
    border-width: 1px;
    border-color: black;
}

.SetColorThemeSaveBtn:hover
{
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
    color: white;
    border-color: white;
}*/