.feedbackRecord {
    background: #fff;
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 30%);
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    border-radius: .5rem;
    
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}
.feedbackRecord .left {
    display: flex;
    flex-direction: column;
}
.fdbk_record_title {
    font-size: 2rem;
    letter-spacing: 1px;
    opacity: .4;
}
.fdbk_record_inner {
    height: 100%;
    display: flex;
}


.fdbk_record_chart {
    height: 100%;
    display: flex;
    flex-direction: row;
}
.fdbk_record_chartinner {
    width: 3rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.fdbk_record_chartinner .bar {
    width: 2.5rem;
    height: 100%;
    filter: drop-shadow(4px 7px 2px rgb(0,0,0,.2));
    color: white;
    text-shadow: 3px 3px 5px black;
    text-align: center;
    font-size: 0.7rem;
    /* border-radius: 0.3rem; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.fdbk_record_chartinner .type {
    font-size: 1rem;
    text-align: center;
}



.fdbk_record_table {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    overflow-y: auto;
}
.fdbk_record_table .record {
    width: 100%;
    height: 2rem;
    padding: 1rem;
    border-radius: 0.3rem;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.3);

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.fdbk_record_table .record .time {
    letter-spacing: -1px;
    max-width: 8rem;
    min-width: 8rem;
}
.fdbk_record_table .record .desc {
    width: 100%;
    background: rgba(128, 128, 128, 0.1);
}



.fdbk_record_table .record.maxsize {
    height: 8rem;
    margin-bottom: 1rem;
}
.fdbk_record_table .record .desc.maxsize {
    overflow-y: auto;
    max-height: 5rem;
}