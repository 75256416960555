.wrap {
    display: flex;
    flex-direction: row;
    min-width: 10rem;
    max-width: 10rem;
}
.Planner_tableRow {
    padding: .5rem;
    color: var(--optiontext);
    transition: all var(--css-duration);
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
}
.Planner_tableRow:hover {
    box-shadow: 0 -1000px 0 0 rgba(0,0,0,.1) inset;
}
.Planner_tableRow .col1 {
    min-width: 10rem;
    max-width: 10rem;
}
.Planner_tableRow .col2 {
    min-width: 8rem;
    max-width: 8rem;
    word-wrap: break-word;
}
.Planner_tableRow .col3 {
    width: 100%;
}

.Planner_tableRow .subScroll {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
}

.Planner_tableRow .subScroll::-webkit-scrollbar {
    display: none;
}

.Planner_tableRow .planned_one {
    min-width: 8rem;
    max-width: 8rem;
    border-radius: 40px 40px 40px 40px;
    background: #73AD21;
}

.Planner_tableRow .planned_left {
    min-width: 8rem;
    max-width: 8rem;
    border-radius: 40px 10px 10px 40px;
    background: #73AD21;
}

.Planner_tableRow .planned_middle {
    min-width: 8rem;
    max-width: 8rem;
    background: #73AD21;
}

.Planner_tableRow .planned_right {
    min-width: 8rem;
    max-width: 8rem;
    border-radius: 10px 40px 40px 10px;
    background: #73AD21;
}

.popupalarm_ackBtn
{
    font-size: 1.3rem;
    letter-spacing: 2px;
    color: white;
    margin-top: 2rem;
    padding: 0.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background: grey;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;

    cursor: pointer;
    transition: all var(--css-duration);

    opacity: 0.7;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, .3);
    text-align: center;
}
.popupalarm_ackBtn .fa
{
    margin-right: 0.5rem;
}
.popupalarm_ackBtn:hover
{
    opacity: 1;
    /* box-shadow: 0 0px 8px 0px grey; */
}
.popupalarm_ackBtn:active
{
    opacity: 1;
    box-shadow: 0 0px 100px 0px rgb(175, 175, 175) inset;
}

.loadingColor {
    background: transparent;
    color: transparent;
}
