.Toilet_cln_row1 {
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.18);
    border-radius: 0.5rem;
    padding: 1rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    transition: all 0.5s ease-in-out;
    color: white;
}
/*.Toilet_cln_row1 {
    background: linear-gradient(to right, teal, rgb(0, 183, 183));
}
 .Toilet_cln_row1.female {
    background: linear-gradient(to right, rgb(233, 103, 130), rgb(235, 154, 142));
}
.Toilet_cln_row1.disable {
    background: linear-gradient(to right, grey, black);
}
.Toilet_cln_row2 {
    background: linear-gradient(to right, lightgreen, rgb(66, 255, 66));
    color: rgb(0, 0, 0);
}
.Toilet_cln_row2.female {
    background: linear-gradient(to right, rgb(255, 86, 202), rgb(233, 170, 246));
}
.Toilet_cln_row2.disable {
    background: linear-gradient(to right, grey, grey);
} */
.Toilet_cln_col1,
.Toilet_cln_col2,
.Toilet_cln_col3,
.Toilet_cln_col4,
.Toilet_cln_col5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
}
.Toilet_cln_col1 {
    align-self: center;
    min-width: 2rem;
    font-size: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;

    filter: drop-shadow(0 5px 3px rgba(0, 0, 0, 0.3));
}

.Toilet_cln_col2,
.Toilet_cln_col3,
.Toilet_cln_col4,
.Toilet_cln_col5 {
    min-width: 8rem;
}
.Toilet_cln_title {
    opacity: 0.7;
    letter-spacing: -1px;
}
.Toilet_cln_value {
    font-weight: 900;
    font-size: 1.2rem;
    letter-spacing: 1px;
}

.Toilet_cln_progressValue {
    text-align: center;
    font-weight: 900;
    width: 100%;

    font-size: 0.8rem;
}
.Toilet_cln_progress {
    width: 100%;
    height: 5px;

    background: rgba(255, 255, 255, 0.4);

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.Toilet_cln_progressInner {
    transition: all 0.5s ease-in-out;
    background: rgb(255, 255, 255);

    height: 100%;
}

.Toilet_cln_loading {
    width: auto;
    font-size: 2rem;
    margin: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    animation: rotateLoadingItem 2s linear infinite;
}
@keyframes rotateLoadingItem {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

.Toilet_cln_refresh {
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    margin-right: 1rem;
}