.UserMangemenetBackBtn
{
    background: rgb(0, 0, 0, 0.5);
    color: white;
    font-size: 1rem;
    padding: 1rem;
    border-radius: 50%;

    position: absolute;
    top: 5rem;
    left: 1rem;

    cursor: pointer;
    transition: all var(--css-duration);
}

.UserMangemenetBackBtn:hover
{
    box-shadow: 0 -60px 0 0 var(--focuscardbackground) inset;
    color: white;
}

.UserManagementContainer
{
    /*position: fixed;
    bottom: 0;
    left: 6vw;*/
    margin:auto;
    margin-top: 1.5rem;
    width: 88vw;
    height: calc(90vh);
    /*
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.685), rgba(255, 255, 255, 0.9));
    */
    /* background: linear-gradient(to right, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.5)); */
    background: linear-gradient(to top, var(--maindiv-color),var(--maindiv-color2));
    padding: 1rem;
    border-radius: 0.5rem;
    transition: all var(--css-duration);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.UserManageTable
{
    background: rgb(0, 0, 0, 0.1);
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
}

.UserManageTable td
{
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.2);
	color: black;
}

.UserManageTable th
{
    text-align: left;
    padding-left: 0.5rem;
}

.UserManageTable .header
{
    background: var(--colortheme1);
    background-size: cover;
    background-position: center;
    color: white;
}
.UserManageTable .header th
{
    color: white;
    border-width: 1px;
    border-color: white;
    border-radius: 0.3rem;
    font-size: 1rem;
}

.UserManageTable tr
{
    transition: all var(--css-duration);
}

.UserManageTable tr:hover
{
    /*background-color: rgba(250, 250, 250, 0.2);*/
    box-shadow: 0 -50px 0 0 rgba(0, 0, 0, 0.2) inset;
}

.UserManageTable td
{
    transition: all var(--css-duration);
    color: var(--optiontext);
}

.UserManageTable td:hover
{
    background-color: rgba(255, 255, 255, 0.2);
    /*color: white;*/
}

.UserManageAddBtn
{
    background: var(--focuscardbackground);
    border-radius: 0.5rem;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
    transition: all var(--css-duration);
}

.UserManageAddBtn:hover
{
    box-shadow: 0 -50px 0 0 rgba(255, 255, 255, 0.2) inset;
    /* background: transparent; */
}

.UserManageEditBtn
{
    background: rgb(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
    transition: all var(--css-duration);
}

.UserManageEditBtn:hover
{
    color: white;
}

.UserManagePopupBg
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;

    transition: all var(--css-duration);
}
.UserManagePopup
{
    position: fixed;
    width: 70vw;
    height: auto;
    top: 10vh;
    left: 15vw;
    border-radius: 1rem;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
    transition: all var(--css-duration);

    display: flex;
    flex-direction: column;

    backdrop-filter: blur(10px);

    animation: userpopup .3s ease-out;
}
@keyframes userpopup {
    0% {
        opacity: 0;
        transform: translateY(-10px) scale(.7);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.UserManagePopupCloseBtn
{
    position: absolute;
    top: 1rem;
    right: 0.8rem;
    padding: 0.5rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    cursor: pointer;
    color: white;
    transition: all var(--css-duration);

    font-size: 1.5rem;
}
.UserManagePopupCloseBtn:hover
{
    /*box-shadow: 0 -40px 0 0 var(--focuscardbackground) inset;*/
    transform: rotate(90deg);
}
/*.UserManagePopupHead
{
    height: 4.5rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background: transparent;
    
    background: linear-gradient(to bottom, var(--colortheme1), transparent);
    /*background: var(--colortheme1);
    background-size: cover;
    background-position: center;
	transition: all 2s;
}*/
.UserManagePopupTitle
{
    font-size: 2rem;
    color: black;
    margin-top: 1.3rem;
    margin-left: 1.3rem;
    color:white;
}

.UserManagePopupPhotoContainer
{
    margin-top: 2rem;
    margin-left: 1.5rem;
    width: 20rem;
    height: 20rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 0.5rem;
}

.UserManagePopupInnerContainer
{
    margin-top: 2rem;
    margin-left: 1rem;
    background: rgba(250, 193, 193, 0);
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: inherit;
    justify-content: start;
}

.UserManagePopupInput
{
    width: 20vw;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    padding: 0.5rem;
    padding-left: 1rem;
    border-radius: 0.5rem;
    transition: all var(--css-duration);
    background: transparent;
    border-width: 0.5px;
    border-color: rgba(0, 0, 0, 0.4);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.1),
     rgba(0, 0, 0, 0.2), rgb(0, 0, 0, 0.1));
    color: white;
}
.UserManagePopupInput:focus
{
    box-shadow: 0px -50px 40px 0 rgba(0, 0, 0, 0.5) inset;
}


.UserRolecheckboxesContainer
{
    background: rgba(255, 255, 255, 0.11);
    color: white;
    /* font-size: 1.2rem;*/
    /* padding: 0.1rem; */
    /* padding-left: 1rem;
    padding-right: 1rem; */
    width: 20vw;
    padding-bottom: 1rem; 
    margin-top: 0.5rem;
    /* margin-left: 0.5rem;  */
    border-radius: 0.5rem;
}


.UserRoleDescriptionContainer
{ 
    margin-left:1rem ;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.11);
    color: white; 
    width: 25vw;
    padding-bottom: 1rem; 
    margin-top: 0.5rem;
    /* margin-left: 0.5rem;  */
    border-radius: 0.5rem;
    font-size: small;
}

.UserRoleDescriptionTitle
{ 
    display: flex;
  flex-direction: row; 
  width: 9vw;
}
.UserRoleDescriptionInnerRow
{ 
    display: flex;
  flex-direction: row; 
  width: 30vw;
}

.UserRoleDescriptionInnerColumn
{ 
    display: flex;
    flex-direction:column;
    width: 20vw; 
    margin-left:1rem ;

}


.UserRolecheckboxInnerContainer
{
    /* background: rgba(255, 255, 255, 0.11); */
    color: white;
    font-size: 1.2rem;
    padding: 0.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    border-radius: 0.5rem;
}
 
.UserManagePopupColContainer
{
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
}

.UserManagePopupRowContainer
{
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
}
.UserManagePopupRole
{
    width: 20vw;
    height: 2.4rem;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    padding: 0.2rem;
    padding-left: 1rem;
    border-radius: 0.3rem;
    transition: all var(--css-duration);
    background: rgba(0, 0, 0, 0.4);
    color: white;
    border-width: 0.5px;
    border-color: rgba(0, 0, 0, 0.4);
}

.UserManagePopupRole:focus
{
    box-shadow: 0px 30px 30px 0 rgba(0, 0, 0, 0.5) inset;
}
.UserManagePopupCompany
{
    width: 20vw;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    padding: 0.2rem;
    padding-left: 1rem;
    border-radius: 999rem;
    transition: all var(--css-duration);
    background: rgba(0, 0, 0, 0.205);
    border-width: 0.5px;
    border-color: rgba(0, 0, 0, 0.4);
}

.UserManagePopupCompany:focus
{
    box-shadow: 0px 30px 30px 0 var(--focuscardbackground) inset;
    color: white;
}
.UserManageSubmit
{
    width: 10vw;
    height: 2.3rem;
    border-radius: 0.5rem;
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.3);
    text-align: center;
    background: var(--colortheme1);
    color: white;
    font-size: 1.2rem;
    padding-top: 0.4rem;
    cursor: pointer;
    transition: all var(--css-duration);
}
.UserManageSubmit:hover
{
    box-shadow: 0px -50px 30px 0 var(--focuscardbackground) inset;
    background: transparent;
    color: white;
}

.UserManageCancel
{
    width: 10vw;
    height: 2.3rem;
    border-radius: 0.5rem;
    text-align: center;
    background: transparent;
    border-width: 0.5px;
    border-color: rgba(255, 255, 255, 0.4);
    color: white;
    font-size: 1.2rem;
    padding-top: 0.4rem;
    cursor: pointer;
    transition: all var(--css-duration);
    margin-left: 0.5rem;
}
.UserManageCancel:hover
{
    box-shadow: 0px -50px 0px 0 var(--focuscardbackground) inset;
    border-color: var(--focuscardbackground);
    color: white;
}

.UserManageRemoveAcc
{
    margin-right: 3rem;
    color: white;
    background: linear-gradient(to right, rgba(255, 0, 0, 0.3), rgba(255, 0, 0, 0.3));
}

.UserManagebuttonsContainer
{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 2rem;
}

.UserManagePopupSitelist
{
    width: 50%;
    font-size: 1.3rem;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 1rem;
    border-radius: 1rem;
}

input[type="checkbox"] {
    display:none;
}

input[type="checkbox"] + label span {
    display:inline-block;
    width:19px;
    height:19px;
    margin:-2px 10px 0 0;
    vertical-align:middle;
    background:url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/210284/check_radio_sheet.png) left top no-repeat;
    cursor:pointer;
}

input[type="checkbox"]:checked + label span {
    background:url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/210284/check_radio_sheet.png) -19px top no-repeat;
}

.UsermanageSitelist
{
    display: flex;
    flex-direction: column;
}

.SitelistcheckboxInnerContainer
{
    background: rgba(255, 255, 255, 0.11);
    color: white;
    font-size: 1.2rem;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    border-radius: 0.5rem;
}

.UM_MsgPopupBg
{
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    width: 100vw;
    height: 100vh;
    /*display: none;*/
}
.UM_MsgPopup
{
    position: fixed;
    background: rgba(255, 255, 255, 0.8);
    width: 50vw;
    height: 50vh;
    left: 25vw;
    top: 25vh;
    border-radius: 0.5rem;
}
.UM_MsgPopupTtl
{
    display: flex;
    flex-direction: column;
    width: 20rem;
    margin-left: calc((50vw - 20rem)/2);
    margin-top: 3rem;
    font-size: 2.5rem;
}
.UM_MsgPopupTtlIcon
{
    width: 100%;
    text-align: center;
}
.UM_MsgPopupTtlTxt
{
    width: 100%;
    text-align: center;
    margin-top: 1rem;
}
.UM_MsgPopupMsg
{
    width: 100%;
    height: 100%;
    padding: 1rem;
    text-align: center;
    font-size: 1.5rem;
}
.UM_MsgPopupCloseBtn
{
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    cursor: pointer;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-radius: 0.3rem;
    transition: all var(--css-duration);
}
.UM_MsgPopupCloseBtn:hover
{
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
    color: white;
}
.UM_popupPhoto
{
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
}

.UM_MsgPopupOkBtn
{
    background: black;
    color: white;
    font-size: 1.7rem;
    position: absolute;
    bottom: 1rem;
    left: calc(25vw - 5rem);
    text-align: center;
    cursor: pointer;
    width: 10rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-radius: 0.5rem;
    transition: all var(--css-duration);
}
.UM_MsgPopupOkBtn:hover
{
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}

.UserManagePageContainer
{
    /*position: fixed;
    bottom: 1rem;
    right: 12vw;*/
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 1rem;
    margin-right: 1rem;
}
.UserManagePageBtn
{
    margin-left: 0.5rem;
    padding: 0.3rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    border-radius: 0.3rem;
    outline: 0;
    transition: all var(--css-duration);
    cursor: pointer;
}
.UserManagePageBtn:hover
{
    background: var(--focuscardbackground);
    color: white;
}

.usermanagehighlightPage
{
    background: var(--focuscardbackground);
    color: white;
}

.hidepopup2
{
    opacity: 0;
    display: none;
}
.hidePopup
{
    opacity: 0;
    display: none;
}

.RemoveBtnHide
{
    opacity: 0;
    z-index: -100;  /* hide it behind the popup container */
}


.UMDeletePopup_Container
{
    position: fixed;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(15px);
    color: white;
    top: 40vh;
    left: 40vw;
    width: 20vw;
    padding: 1rem;
    border-radius: 0.5rem;
    z-index: 1000;
}
.UNDeletePopup_Title
{
    font-size: 2.2rem;
    text-align: center;
}
.UMDeletePopupBtnContainer
{
    width: 10vw;
    margin:auto;
    margin-top: 1rem;
    transition: all var(--css-duration);
}
.UMDeletePopup_okBtn
{
    padding: 0.6rem;
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
    background: rgb(255, 0, 0, 1);
    border-radius: 0.5rem;
    border-width: 0.5px;
    border-color: white;
    transition: all var(--css-duration);
    cursor: pointer;
}
.UMDeletePopup_okBtn:hover
{
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
    background: transparent;
}
.UNDeletePopup_cancelBtn
{
    padding: 0.6rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: 2rem;
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 0.5rem;
    transition: all var(--css-duration);
    cursor: pointer;
}
.UNDeletePopup_cancelBtn:hover
{
    box-shadow: 0 -50px 0 0 rgb(199, 199, 199) inset;
    background: transparent;
}
.hideConfirmPopup
{
    display: none;
    opacity: 0;
}
.UMRefreshBtn
{
    background: var(--focuscardbackground);
    color: white;
    border-radius: 0.5rem;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
    transition: all var(--css-duration);
    margin-left: 1rem;
    width: 3rem;
    text-align: center;
}


.UserManagementLoadIcon {
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 3rem;
    color: var(--focuscardbackground);
}