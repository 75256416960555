.LvlPick_main {
    width: 100%;
    padding: 0.2rem;
    background: var(--divheader);
    color: white;
    border-radius: .5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 18%), 0 5px 5px 0 rgb(0 0 0 / 10%);
}
.LvlPick_main .siteInfo {
    display: flex;
    gap: 1rem;
    margin-left: 1rem;
}
.LvlPick_main .siteInfo .icon {
    font-size: 1.1rem;
}
.LvlPick_main .siteInfo .txt {
    letter-spacing: 1.5px;
}
.LvlPick_main .lvlCtrl {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    min-width: 10rem;
    margin-right: 1rem;
}
.LvlPick_main .lvlCtrl .btn {
    border-radius: 5px;
    font-size: 1.3rem;
    width: 2rem;
    height: 2rem;

    color: rgba(0, 0, 0, 0.7);
    background: rgb(201, 201, 201);

    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    cursor: pointer;
}
.LvlPick_main .lvlCtrl .btn:hover,
.LvlPick_main .lvlCtrl .btn.current {
    background: var(--colortheme1);
    color: white;
}