.highchart_maindiv
{
    margin:0;
    border-bottom-right-radius: 0.5rem;
}

.SpiderChart_inside {
    width: 100%;
    height:100%;
    margin-top: 0rem;
    /*background: var(--cardbodybackground);*/
    color: var(--cardbodytext);
    transition: all var(--css-duration);
    /*box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.1), 0 5px 7.5px 0 rgba(0, 0, 0, 0.2);*/
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.SpiderChart_header
{
    width: 100%;
    padding: 0.5rem;
    background: var(--cardtitle);
    color: var(--divheadertext);

    border-radius: 0.3rem;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.18), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.SpiderChart_innerChartdiv
{
    /* background: linear-gradient(to right, rgb(200,200,200,0.6), rgb(200,200,200,0.1)); */
    background: var(--cardbodybackground);
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.SpiderChart_maindiv
{
    /* background: rgba(255, 255, 255, 0.178); */
    height: 100%;
}


/* No Permission style */
.NoPerm3_main {
    width: 100%;
    height: calc(100%);

    background-image: url(../../../assets/images/BlueCity.jpg);
    background-position: center;
    background-size: cover;
    border-radius: .3rem;
}
.noPerm3_blur {
    backdrop-filter: blur(5px);
    background: rgb(0, 0, 0, .3);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: .3rem;
}
.NoPerm3_Txt {
    margin: auto;
    font-size: 1.5rem;
    color: white;
    letter-spacing: 3px;
}