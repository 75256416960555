.AlarmCount_div
{
    /* background: linear-gradient(to right, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.3)); */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;   
    border-radius: 0.3rem; 
     box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.18), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.AlarmCount_newalarms
{
    padding: 1rem;  
    width: 100%;
    height: 100%;
    background: rgba(255, 0, 0, 0.699);
    margin:auto;
    border-radius: 0.3rem;

    color: white;
    font-size: 1.5rem;

    cursor: pointer;
}

.AlarmCount_ackalarms
{
    padding: 1rem;
    width: 100%;
    height: 100%;
    background: rgba(4, 128, 0, 0.432);
    /* background: var(--cardbodybackground); */
    margin:auto;
    border-radius: 0.3rem;

    color:white;
    font-size: 1.5rem;
    /* cursor: pointer; */
}

.AlarmCount_newalarms_readingMaindiv
{
    display: flex;
    flex-direction: row;
    margin:auto;
    justify-content: space-between;
    margin-top: 0.5rem;
}

.AlarmCount_ackalarms_readingMaindiv
{
    display: flex;
    flex-direction: row;
    margin:auto;
    justify-content: space-between;
    margin-top: 0.5rem;
}

.AlarmCount_newalarms_reading_value
{
    font-size: 2.5rem;
    padding-right: 0.5rem;
}

.AlarmCount_ackalarms_reading_value
{
    font-size: 2.5rem;
    padding-right: 0.5rem;
}

.spacing
{
    width: 0.5rem;
}

.AlarmCount_newalarms_Btn
{
    color: white;
    display: flex;
    flex-direction: row;
    width: 2rem;
    padding: 0.3rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    font-size:1.3rem;
    margin-top: -1rem;

    border-radius: 0.3rem;
    cursor: pointer;
    border-width: 1px;
    border-color: white;

    transition: all var(--css-duration);
    background: rgba(255, 255, 255, 0.1);
}

.AlarmCount_newalarms_Btn *
{
    margin:auto;
}

.AlarmCount_newalarms_Btn:hover
{
    /*background: rgba(255, 255, 255, 0.5);*/
}

.AlarmCount_ackalarms_Btn
{
    color: white;
    display: flex;
    flex-direction: row;
    width: 2rem;
    padding: 0.3rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    font-size:1.3rem;
    margin-top: -1rem;

    border-radius: 0.3rem;
    cursor: pointer;
    border-width: 1px;
    border-color: white;

    transition: all var(--css-duration);
    background: rgba(255, 255, 255, 0.1);
}

.AlarmCount_ackalarms_Btn *
{
    margin:auto;
}

.AlarmCount_ackalarms_Btn:hover
{
    /*background: rgba(255, 255, 255, 0.5);*/
}

.AlarmCount_ackalarms_reading_icon
{
    margin:auto;
    margin-left: 0.3rem;
}

.AlarmCount_newalarms_reading_icon
{
    margin:auto;
    margin-left: 0.3rem;
}



/* Latest Alarm List for widget 2 */
.widget2_AlarmList {
    height: 100%;
    overflow: auto;
}
.widget2_alarmrow {
    margin-top: .3rem;
    /* background: linear-gradient(to right, rgba(255, 0, 0, 0.699), rgba(200, 0, 0, 0.699)); */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.widget2_alarmNumber {
    width: 2rem;
    text-align: center;
    background: var(--colortheme1);
    align-self: center;
}
.widget2_alarmName {
    padding: .5rem;
    font-size: 1.5rem;
    width: 100%;
    font-weight: 100;
    letter-spacing: 3px;
    /* color: var(--optiontext); */
}