.NotificationPopupList {
    position: fixed;
    top: 4rem;
    right: 1rem;
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    flex-wrap: wrap-reverse;
    max-height: 90vh;
    z-index: 1000;
}

.NotificationWordsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 18rem;

    transition: all 0.5s;
}

.NotificationPopupContainer{
    transition: all var(--css-duration);
    background-image: linear-gradient(
        to right bottom, rgba(0, 0, 0, .3), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4) 
    );
    backdrop-filter: blur(10px);
    color: white;
    padding: 1rem;

    min-width: 20rem;
    width: 25rem;
    min-height: 6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 0.5rem;

    box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0.3);
}
.NotificationPopup_Icon {
    margin-left: 0.5rem;
    margin-right: 1rem;
    align-self: center;
    font-size: 1.7rem;
}
.NotificationPopup_close {
    align-self: center;
    padding: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
    transition: all .4s;
}
.NotificationPopup_close:hover {
    transform: scale(1.2);
}
.NotificationPopup_close:active {
    transform: scale(0.9);
}
.NotificationPopupTitle {
    font-size: 1.3rem;
}
.NotificationPopupRow {
    margin-top: 0.3rem;
}

.showNotifiItem {
    opacity: 1;
}
.hideNotiItem {
    opacity: 0.1;
    margin-top: -5rem;
}
.removeAnimation {
    opacity: 0;
}

.NotificationPopup_ {
    color: rgb(137, 137, 137);
    text-align: end;
    font-size: 1.5rem;
}

.NotificationPopupLocationRow {
    display: flex;
    flex-direction: row;
    justify-content: start;
    background: linear-gradient(to right, rgb(200, 200, 200, 0.2), transparent);
}
.NotificationPopupLocationitem {
    margin-right: 1rem;
    color: rgb(255, 255, 255, 0.7);
}



.NotiPopup_clearAll {
    background: rgba(0, 0, 0, .3);
    color: white;
    box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.3);
    width: 8rem;
    padding: 0.5rem;
    border-radius: 0.3rem;
    text-align: center;

    cursor: pointer;
}
.haveItems {
    display: block;
}
.notiItemclosing {
    opacity: 0;
    transform: translateX(100px);
}