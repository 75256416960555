.LEDCard {
    width: 100%;
    height: 7rem;
    margin-left: 1rem;
    margin-right: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.LEDCard_status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    height: 100%;

    opacity: 0.7;

    margin-top: 0.5rem;
}

.LEDCard_controls {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}
.LEDCard_controls .control {
    font-size: 1.3rem;
    padding: 0.3rem;
    width: 3rem;
    text-align: center;
    border-radius: 0.3rem;

    cursor: pointer;
}
.LEDCard_controls .control.disable {
    color: white;
    background: rgb(126, 128, 0);
}

.LEDCard_loading {
    animation: LoadingLEDCard 1s infinite linear;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 3rem;
    height: 100%;
}
@keyframes LoadingLEDCard {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}