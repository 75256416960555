$cardBackground: var(--cardbodybackground);
$textColor: var(--optiontext);
$transitionTime: var(--css-duration);

%CardColors {
    background: $cardBackground;
    color: $textColor;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.05);
}
%CardAnimation {
    animation: .5s cardOnload linear;
    transition: all $transitionTime;
}

.IEQbtnCard {
    @extend %CardColors;
    @extend %CardAnimation;

    width: 40rem;
    height: 10.5rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .loc {
        font-size: 1.5rem;
    }
    .main {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        
        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 12rem;
            opacity: 0.9;
            font-size: 22px;

            margin: 0 2px 0 2px;

            .title {                
                font-weight: bold;
                opacity: 0.7;
            }
            .value {
                display: flex;
                gap: 3px;

                .unit {
                    width: 60px;
                    opacity: 0.8;
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .IEQbtnCard {
        .main {
            .row {
                font-size: 19px;

                .value {
                    .unit {
                        width: calc(60px / 22 * 20);
                    }
                }
            }
        }
    }
}