.LiftSltBtn {
    background: var(--colortheme1);
    font-size: 1rem;
    align-self: center;
    color: white;
    padding: .5rem;
    width: 3rem;
    text-align: center;
    border-radius: .3rem;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.18));
    cursor: pointer;
    transition: all .5s;
}
.LiftSlt_popup {
    position: absolute;
    top: 0;
    background: var(--colortheme2);
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.18));
    width: 30rem;
    height: 15rem;
    font-size: 1.2rem;
    z-index: 100;
    border-radius: 0.3rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.LiftSlt_popup_T {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
    font-size: 0.8rem;
}

.LiftSlt_popup_T select, .LiftSlt_popup_btn, .LiftSlt_selectallbtn {
    background: var(--colortheme1);
    align-self: center;
    color: white;
    padding: .5rem;
    width: 8rem;
    text-align: center;
    border-radius: .3rem;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.18));
    cursor: pointer;
    transition: all .5s;
}
.LiftSlt_selectallbtn:hover,  .LiftSlt_popup_btn:hover, .LiftSlt_popup_T select:hover {
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}


.LiftSlt_popup_B {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;

    font-size: 1rem;
}

.selectLift_popup_siteItem {
    width: 100%;
    height: 1.2rem;
    border-bottom: black solid 1px;
    margin: 0 1rem 0 1rem;
    opacity: 0.7;
}
.selectLift_popup_liftItem {
    height: 2rem;
    width: 4rem;
    background: rgba(0, 0, 0, 0.18);
    color: var(--optiontext);
    border-radius: 0.3rem;
    margin-left: 1rem;
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%), 0 5px 5px 0 rgb(0 0 0 / 5%);
    cursor: pointer;
    transition: all .5s;

    display: flex;
    justify-content: center;
    align-items: center;
}
.selectLift_popup_liftItem.highlight {
    background: var(--focuscardbackground);
    color: white;
}