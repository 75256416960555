.Toilet_occ_fallalarm {
    background: rgb(255, 0, 89);
    color: white;
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.4));
    min-width: 6rem;
    min-height: 6rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    letter-spacing: 1px;

    transform: translate(0, -50%);

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    animation: FallAlarmOnload 0.5s ease-in-out;
    transition: all 0.5s ease-out;
}
@keyframes FallAlarmOnload {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
    }
}

.Toilet_occ_fallalarm:before {
    content: '';
    width: 1rem;
    height: 1rem;
    background: linear-gradient(to bottom right, transparent 50%, rgb(255, 0, 89) 50%);
    transform: translate(0, 0.5rem)  scaleY(4) rotateZ(45deg);
    position: absolute;
}
.Toilet_occ_fallalarm img {
    width: 4rem;
    height: 4rem;

    filter: drop-shadow(0 10px 0px rgba(0, 0, 0, 0.5));

    animation: FallBlink 0.7s ease-in-out infinite;
}

@keyframes FallBlink{
    0%{     opacity: 1; }
    60%{    opacity: 0; }
    100%{   opacity: 1; }
}

.Toilet_occ_fallalarm_desc {
    font-size: 0.8rem;
    text-align: center;
    min-width: 12rem;
}