.requestRecord {
    background: rgb(236, 20, 90);
    color: white;
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 30%);
    width: 8rem;
    height: 8rem;
    margin: auto;
    padding: 1rem;
    border-radius: .5rem;
    font-size: 3rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    cursor: pointer;
    transition: all 0.3s ease-out;
}
.requestRecord:hover {
    color: white;
    background: rgb(255, 153, 0);
}
.requestRecord .fa {
    filter: drop-shadow(0 5px 3px rgba(0, 0, 0, 0.3))
}
.requestRecord .desc {
    font-size: 0.8rem;
}



.requestRecord .popup {
    position: absolute;
    width: 50rem;
    height: 30rem;
    margin-top: -20rem;

    padding: 1rem;
    background: rgba(255,255,255,0.8);
    backdrop-filter: blur(20px);
    color: black;
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 30%);
    border-radius: .5rem;

    display: flex;
    flex-direction: column;

    cursor:auto;
    animation: 0.5s ease-in-out RequestRecordPopup;
}
@keyframes RequestRecordPopup {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {

    }
}
.requestRecord .closebtn {
    cursor: pointer;
    align-self: flex-end;
    font-size: 1.5rem;

    opacity: 0.7;
}
.requestRecord .popup .Table {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;

    overflow-y: auto;
}
.requestRecord .popup .Table .header {
    display: flex;
    font-size: 1rem;
    padding: 0.5rem;
    justify-content: space-between;
    align-items: center;
}
.requestRecord .popup .Table .rows {
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 100%;
}
.requestRecord .popup .Table .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 0.3rem;
    box-shadow: 2px 3px 5px 0 rgba(0,0,0,0.2);
}
.requestRecord .popup .Table .col1 {
    min-width: 13rem;
}
.requestRecord .popup .Table .col2 {
    min-width: 4rem;
}
.requestRecord .popup .Table .col3 {
    min-width: 5rem;
}
.requestRecord .popup .Table .col4 {
    min-width: 7rem;
}
.requestRecord .popup .Table .col5 {
    min-width: 14rem;
}


@media only screen and (max-width: 1280px) {
    .requestRecord {
      max-width: 6rem;
      max-height: 7rem;
    }

    .requestRecord .popup {
        margin-left: 7rem;
    }
}