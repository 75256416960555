.LiftRuntime {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.LiftRuntime_search {
    padding: .5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: .8rem;
}
.LiftRuntime_searchTitle {
    align-self: center;
    color: var(--optiontext);
    opacity: .8;
    letter-spacing: 1px;
}
.LiftRuntime_rowheader {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background: var(--focuscardbackground);
    color: white;
    padding: .5rem;
    border-radius: .3rem;
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 18%), 0 0px 5px 0 rgb(0 0 0 / 10%);
    margin-left: 1rem;
    margin-right: 1rem;
}
.LiftRuntime_row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background: var(--cardbodybackground);
    padding: .5rem;
    color: var(--optiontext);
    margin-top: .3rem;
    border-radius: .3rem;
    transition: all .5s;
    filter: drop-shadow(0 3px 6px rgba(0, 0, 0, .2));
    margin-left: 1rem;
    margin-right: 1rem;
}
.LiftRuntime_row:hover {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
    z-index: 100;
}
.LiftRuntime_col {
    width: 25%;
    font-size: .9rem;
    align-self: center;
    text-align: center;
}
.LiftRuntime_table1_Container {
    height: 100%;
    overflow-y: auto;
}