.carpark {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.carpark .row {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.carpark .row .img {
    width: 50%;
    height: 4rem;
    margin-left: 1.5rem;

    background: grey;
    color: white;
    border-width: 4px;
    border-color: rgb(224, 146, 0);

    display: flex;
    justify-content: center;
    align-content: center;
}
.carpark .row .img.car {
    font-size: 4rem;
}
.carpark .row .txt {
    width: 50%;
    color: var(--optiontext);
    opacity: 0.7;
    text-align: center;
    font-size: 2.5rem;
    letter-spacing: 2px;
}