.occSummary {
    margin: 1rem;
    width: 100%;
    height: 4rem;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: all 0.5s;
}
.occSummary.normal {
    background: rgb(0, 208, 255);
}
.occSummary.abnormal {
    background: rgb(255, 0, 34);
    animation: carOccAlertOn 1s infinite;
}
.occSummary .icon {
    font-size: 2.5rem;
    width: 3rem;
    text-align: center;
}
.occSummary .txt {
    font-size: 1.8rem;

    transition: all 0.5s;
}
@keyframes carOccAlertOn {
    0% {
        box-shadow: 0 0 15px 0 rgba(255, 0, 0, 0);
        background: crimson;
    }
    50% {
        box-shadow: 0 0 15px 0 rgba(255, 0, 0, 1);
        background: red;
    }
    100% {
        box-shadow: 0 0 15px 0 rgba(255, 0, 0, 0);
        background: crimson;
    }
}