.Toilet_admin_occStat {
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 30%);
    padding: 1rem;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Toilet_admin_occStat .head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 1.5rem;
    color: rgba(0,0,0,0.7);
    letter-spacing: 2px;
}


.Toilet_admin_occ_AMPM {
    width: 10rem;
    height: 1.5rem;
    border-radius: 0.2rem;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    cursor: pointer;

    box-shadow: 0 -3px 5px 0 rgb(0, 0, 0, 0.3) inset;
}
.Toilet_admin_occ_AMPM .AM,
.Toilet_admin_occ_AMPM .PM {
    width: 5rem;
    min-width: 5rem;
    text-align: center;
    letter-spacing: 2px;
}

.Toilet_admin_occ_AMPM .Cover {
    position: absolute;
    width: 5rem;
    height: 1.5rem;
    border-radius: 0.2rem;
    transition: 0.8s all cubic-bezier(0.4,-0.18, 0.32, 1.43);
    box-shadow: 0 3px 2px 0 rgb(0, 0, 0, 0.2) inset;
    background: rgb(73, 191, 181, 0.6);
    backdrop-filter: blur(2px);
}