._4reading2row {
    background: var(--cardbodybackground);
    color: var(--optiontext);
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1), 0 0px 7.5px 0 rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;

    display: flex;
    flex-direction: column;
}
._4read2r_header {
    position: relative;
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 0;
    background: var(--cardtitle);
    color: var(--cardtitletext);
    box-shadow: 0 15px 30px 0 rgba(0,0,0,.11), 0 5px 15px 0 rgba(0,0,0,.08);
    padding: 0.5rem;
    padding-left: 1rem;
    --css_duration: var(--css-duration);
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}
._4read2r_headerIcon {
    margin-right: 1rem;
}
._4read2r_Row {
    height: calc((100% - 2rem)/2);

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    margin-top: .5rem;

    padding-left: 2rem;
    padding-right: 2rem;
}
._4read2r_Row:nth-child(3) {
    padding: 0; /* force the width of row increase (remove padding), so have enough space */ 
}
._4read2r_right {
    height: 4rem;
    width: 50%;
    max-width: 12rem;

    display: flex;
    flex-direction: row;
    align-items: center;
}
._4read2r_Left {
    height: 4rem;
    width: 50%;
    max-width: 12rem;

    display: flex;
    flex-direction: row;
    align-items: center;
}
._4read2r_Type {
    min-width: 4rem;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
._4read2r_Type * {
    filter: drop-shadow(0 8px 4px rgba(0, 0, 0, 0.15));
}
._4read2r_Type2 {
    min-width: 5rem;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 2.5rem;
    filter: drop-shadow(0 7px 2px rgba(0, 0, 0, .2));
}
._4read2r_Reading {
    width: 100%;
    height: 100%;

    font-size: 3rem;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
._4read2r_Unit {
    font-size: 1rem;
    align-self: start;
    margin-top: .5rem;
}
._4read2r_Unit2 {
    font-size: 1.3rem;
}


._4read2r_Left.temp {
    color: red;
    font-size: 4rem;
}
._4read2r_Left.co2 {
    color: rgb(248, 0, 248);
}
._4read2r_right.pm25 {
    opacity: .6;
}
._4read2r_right.humid {
    color: rgb(0, 145, 255);
    font-size: 4rem;
}

@media only screen and (max-width: 1300px) {
    ._4read2r_Left.temp {
        font-size: 3rem;
    }
    ._4read2r_right.humid {
        font-size: 3rem;
    }
    ._4read2r_Type2 {
        font-size: 2.2rem;
    }
    ._4read2r_Reading {
        font-size: 2.2rem;
    }
}

@media only screen and (max-height: 790px) {
    ._4read2r_Type2 {
        font-size: 1.5rem;
    }
    ._4read2r_Reading {
        font-size: 1.5rem;
    }
    ._4read2r_Unit {
        font-size: 0.7rem;
        margin-top: 1rem;
    }
    .bottom_outside {
        min-width: calc(100% - 13rem);
        max-width: calc(100% - 13rem);
    }
    .consolelist_outside {
        min-width: 13rem;
        max-width: 13rem;
    }
}