.Planner_downloadcsv {
    padding: .5rem 1rem;
    border-radius: .5rem;
    background: var(--colortheme1);
    color: #fff;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.224);
    transition: all var(--css-duration);
    cursor: pointer;
}
.Planner_downloadcsv:hover {
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.224),0 -50px 0 0 var(--focuscardbackground) inset;
}