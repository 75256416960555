.pickLevelSite_outside
{
    width: 100%;
    padding:0.5rem;
    background: var(--divheader);
    color: white;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    margin-top:0.2rem;
    margin-bottom:0.2rem;

    /* box-shadow: 0 15px 10px 0 rgba(0, 0, 0, 0.18), 0 5px 5px 0 rgba(0, 0, 0, 0.1); */
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.18), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.pickLevelSite_outside_head
{
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    width: 100%;
}

.pickLevelSite_SiteTxt
{
    width: 15vw;

    cursor: pointer;
    align-self: center;
}
.pickLevelSite_DescTxt
{
    width: 15vw;
    margin-left: 2vw;

    cursor: pointer;
    align-self: center;
}

.pickLevelSite_PickSiteBtn
{
    padding-left:0.5rem;
    padding-right:0.5rem;
    border-radius: 999rem;
    color: var(--cardtitletext);
    background: transparent;
    border-width: 1px;
    border-color: var(--cardtitletext);
    border-width: 1px;

    transition: all var(--css-duration);
    align-self: center;
}

.pickLevelSite_PickSiteBtn:hover
{
    color: var(--focuscardbackground);
    border-color: var(--focuscardbackground);
    /* background: var(--focuscardbackground); */
    outline: 0;
}

.pickLevelSite_LevelTxt
{
    width: 8vw;
    text-align: right;
    align-self: center;
    padding-right:0.5rem;

    cursor: pointer;
}

.pickLevelSite_PickLevelBtn
{
    padding-left:0.5rem;
    padding-right:0.5rem;
    border-radius: 999rem;
    color: var(--cardtitletext);
    background: transparent;
    border-width: 1px;
    border-color: var(--cardtitletext);
    border-width: 1px;
    margin-right: 2rem;

    transition: all var(--css-duration);
    align-self: center;
}

.pickLevelSite_PickLevelBtn:hover
{
    /* color: var(--cardbodytext);
    background: var(--colortheme2); */
    color: var(--focuscardbackground);
    border-color: var(--focuscardbackground);
    outline: 0;
}

.WidgetRow
{
    display:flex;
    flex-direction: row;
    align-self: center;
}