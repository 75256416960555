.WO_DETAIL {
    margin: auto;
    background-color: rgb(232, 228, 220);
    align-items: center;
}

.WO_DETAIL .heading {
    font-weight: bold;
    background-color: rgb(177, 172, 161);
    font-size: 18px;
    padding: 5px;
}

.WO_DETAIL .row {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    min-width: 50rem;
    max-width: 50rem;
    margin: 5px;
}

.WO_DETAIL .row1 {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    min-width: 50rem;
    max-width: 50rem;
    margin: 5px 20px;
}

.WO_DETAIL .col1 {
    min-width: 20rem;
    max-width: 20rem;
}

.WO_DETAIL .button {
    background-color: rgb(170, 169, 167);
    border: none;
    color: white;
    padding: 10px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
}

.WO_DETAIL .text
{
    border-color: rgb(185, 42, 42);
    background-color: white;
    border-radius: 55px;
    min-width: 25rem;
    max-width: 25rem;
}

.WO_DETAIL .textarea
{
    border-color: rgb(185, 42, 42);
    background-color: white;
    height: 10rem;
    min-width: 25rem;
    max-width: 25rem;
}

.WO_DETAIL .text_dim
{
    border-color: rgb(185, 42, 42);
    background-color: rgb(170, 169, 167);
    pointer-events: none;
    border-radius: 55px;
    min-width: 25rem;
    max-width: 25rem;
}