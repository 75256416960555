.InfoPl {
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.InfoPl_open {
    position: absolute;
    right: 0;
    margin-top: 18rem;
    z-index: 100;

    width: 10rem;
    height: 20rem;
    padding: 0.5rem;
    border-radius: 0.5rem;

    /* background: rgb(172, 172, 172); */
    backdrop-filter: blur(5px);

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    animation: InfoPanelIn 0.3s ease-out;
}
@keyframes InfoPanelIn {
    0% {
        opacity: 0;
        transform: translateY(-5px);
    }
    100% {
        opacity: 1;
    }
}
.InfoPl_open .icon {
    align-self: flex-end;
    cursor: pointer;
}
.InfoPl_open .scroll {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.InfoPl_open .scroll .row {
    background: rgba(255, 255, 255, 0.6);
    color: rgb(88, 88, 88);
    opacity: 0.8;
    width: 100%;
    padding: 0.3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: all 0.5s;
}
.InfoPl_open .scroll .row:hover {
    opacity: 1;
}
.InfoPl_open .scroll .row .pt {
    min-width: 1rem;
    min-height: 1rem;
    border-radius: 99px;

    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.6) inset;
}
.InfoPl_open .scroll .row .name {
    text-align: end;
}