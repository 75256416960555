/* .CCGPubliccontainer {
  width: 900px;
  height: 600px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid black;

  font-family: Arial;
} */
/* 
  #logo {
    min-width: 30%;
    max-width: 30%;
    height: 160px;
  }
  #logo > img {
    width: 100%;
  }
  #banner {
    min-width: 70%;
    height: 160px;
    padding: 20px;
    font-size: 40px;
    text-align: right;
    background: url(./image/image_1.jpg) center no-repeat;
    background-size: cover;
    color: white;
  }
  #banner > span {
    font-size: 60px;
  }
  #floor {
    width: 30%;
    height: 440px;
    line-height: 440px;
    font-size: 80px;
    text-align: center;
    background: #003329;
    color: #f8eabd;
  }
  #dashboard {
    width: 70%;
    height: 440px;
    color: #666;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  #dashboard > dt {
    width: 100%;
    padding: 45px;
    text-align: center;

    font-size: 30px;
  }
  #dashboard > dd {
    width: 36%;
    padding: 30px 30px 30px 90px;
    background-repeat: no-repeat;
    background-position: 30px center;
    background-size: 50px;
    
    font-size: 30px;
  }
  #dashboard > dd.temp {
    background-image: url(./image/icon_temp.png);
  }
  #dashboard > dd.hum {
    background-image: url(./image/icon_hum.png);
  }
  #dashboard > dd.co2 {
    background-image: url(./image/icon_co2.png);
  }
  #dashboard > dd.pm10 {
    background-image: url(./image/icon_pm10.png);
  }
  #dashboard > dd > div:last-child {
    font-size: 15px;
  }
   */
 .CCGPubliccontainer {
  font-family: Arial;
  font-size: 40px;
  color: #fff;
  width: 556px;
  height: 766px;
  margin: auto;
  position: relative;
  overflow: hidden;
  -webkit-text-size-adjust: none;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  justify-items: center;
  padding: 60px 0;
 }
  #dashboard {
    color: #666;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: space-around;
  }
  #dashboard > dt {
    flex-basis: 100%;
    text-align: center;
  }
  #dashboard > dt > label {
    display: block;
  }
  #dashboard > dd {
    flex-basis: 45%;
    padding-left: 110px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 100px;
  }
  #dashboard > dd.temp {
    background-image: url(./image/icon_temp.png);
  }
  #dashboard > dd.hum {
    background-image: url(./image/icon_hum.png);
  }
  #dashboard > dd.co2 {
    background-image: url(./image/icon_co2.png);
  }
  #dashboard > dd.pm10 {
    background-image: url(./image/icon_pm10.png);
  }
  #dashboard > dd > div > label {
    font-size: 60px;
  }
  #dashboard > dd > div:last-child {
    font-size: 25px;
  }