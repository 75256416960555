.Toilet_ad_selectMain {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    animation: 0.5s OnloadSelectList ease-in;
}

.Toilet_ad_site,
.Toilet_ad_level,
.Toilet_ad_toilet {
    padding: 0.5rem;
    border-radius: 0.5rem;
    background: white;
    color: grey;
    box-shadow: 3px 3px 7px 0px rgba(0,0,0,0.3);
    text-align: center;
    cursor: pointer;
    letter-spacing: 2px;

    transition: all 0.5s ease-in-out;

}
@keyframes OnloadSelectList {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }
    100% {

    }
}

.Toilet_ad_level_list {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-wrap: wrap;

    width: 100%;
    animation: 0.5s OnloadSelectList ease-in;
}

.Toilet_ad_site {
    width: 25rem;
}
.Toilet_ad_level {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;

    border: solid var(--focuscardbackground) 1px;
}
.Toilet_ad_toilet {
    width: 8rem;
}