.PRSearch_left {
    width: 100%;
    margin-top: .2rem;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.PRSearch_Main {
    border-radius: .5rem;
    background: var(--divheader);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.18), 0 5px 5px 0 rgba(0,0,0,.1);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.PRSearch_Block {
    color: #fff;
    display: block;
    padding: .5rem 1.5rem .5rem .5rem;
    animation: PRHideSearchAnimate 0.5s ease-in-out;
}
.PRSearch_middle {
    width: 100%;
    margin-top: .2rem;
    margin-bottom: .2rem;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.PRSearch_right {
    width: 100%;
    margin-top: .2rem;
    margin-bottom: .2rem;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.PRSearch_combine_line {
    color: #fff;
    display: flex;
    margin-top: .2rem;
    margin-bottom: .2rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.PRSearch_combine_text {
    width: 100%;
    text-align: center;
    margin-left: 1rem;
    color: #fff;
}

.PRSearch_combine_title {
    width: 5rem;
    text-align: center;
    margin-left: 1rem;
    color: #fff;
    animation: WOLHideSearchAnimate 0.5s ease-in-out;
}
.PRSearch_txt {
    margin-left: 1rem;
}
.PRSearch_input {
    margin-left: 1rem;
    padding-left: .5rem;
    background: #fff;
    border-radius: 9999rem;

    box-shadow: 3px 3px 5px 0 rgba(0,0,0,.3) inset;
}
.PRSearch_searchBtn:hover {
    opacity: 1;
}
.PRSearch_adSearchBtn {
    margin-left: .5rem;
    padding: .2rem .8rem;
    background: var(--focuscardbackground);
    box-shadow: 0 5px 5px 0 rgba(0,0,0,.3);
    color: #fff;
    border-radius: .5rem;
    transition: all var(--css-duration);
    opacity: .7;
    cursor: pointer;
}
.PRSearch_adSearchBtn:hover {
    opacity: 1;
}
.PRSearch_hiddenSearches {
    display: flex;
    align-items: center;
    animation: PRHideSearchAnimate 0.5s ease-in-out;
    margin-top: 1rem;
}
@keyframes PRHideSearchAnimate {
    0% {
        opacity: 0;
        transform: translateX(-5rem);
    }
}
.PRSearch_select {
    margin-left: 1rem;
    padding-left: .5rem;
    background: #fff;
    border-radius: 9999rem;  
    box-shadow: 3px 5px 5px 0 rgba(0,0,0,.18) inset;
}
.PRSearch_select option {
    background: rgba(0,0,0,.4);
}

.PRDatepicker input {
    background: white;
    box-shadow: 3px 5px 10px 0 rgba(0,0,0,.3) inset;
}