
.floor_plan_inside_body {
    position: relative;
    align-self: center;
}

/*floor image*/
#floor_image {
    transition: all var(--css_duration);

    background: rgba(0, 204, 255, 0);

    /* min-width: 50vw; */          /* Maybe useful, trying to show vertical long size image */
    max-height: 50vh;

    /* object-fit: contain; */
}
.map_editbutton {
    position: absolute;
    right: 0.5rem;
    top: 2rem;
    background: var(--focuscardbackground);
    color: white;
    padding: 0.2rem;
    border-radius: 5px;
    transition-duration: 0.5s;

    cursor: pointer;
}
.map_editbutton:hover {
    background: var(--colortheme2);
    color: black;
}
.editmap {
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(to top, var(--colortheme1), var(--colortheme2));
    color: white;
    height: auto;
    width: auto;
    /*padding-bottom: 1rem;*/

    display: flex;
    flex-direction: row;

    border-radius: 1rem;
    border-width: 1px;
    border-color: white;
}
.hidemap {
    /**/ display:none; /**/
}
.editarealistdiv {
    padding: 0.4rem;
    background: var(--colortheme1);
    color: white;

    display: flex;
    flex-direction: column;
}
.editarea_closebtn {
    color: white;
    cursor: pointer;

    width: 100%;
    background: rgba(255, 1, 1, 0.52);
    text-align: center;
    padding: 0.5rem;

    transition: all var(--css_duration);
}
.editarea_closebtn:hover {
    box-shadow: -40px 0px 0 0 rgb(255, 255, 255) inset;
    color: black;
}
.area_btn {
    background: rgb(184, 141, 2);
    padding: 0.5rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    color: white;
    transition-duration: .5s;

    margin-top: 1rem;
}
.area_btn:hover {
    box-shadow: -1000px 0 0 0 var(--colortheme2) inset;
    color: black;
}
.area_btn_2 {
    background: rgb(1, 194, 130);
    padding: 0.5rem;
    color: white;
    transition-duration: .5s;

    margin-top: 1rem;
}
.area_btn_2:hover {
    box-shadow: -1000px 0 0 0 var(--colortheme2) inset;
    color: black;
}
.area_btn_3 {
    background: rgb(0, 151, 189);
    padding: 0.5rem;
    color: white;
    transition-duration: .5s;

    margin-top: 1rem;
}
.area_btn_3:hover {
    box-shadow: -1000px 0 0 0 var(--colortheme2) inset;
    color: black;
}
.area_item {
    padding: 0.4rem;
    color: black;
    margin-left: 0.5rem;   
    border-width: 1px;
    border-color: black;
}
.a1 {
    background: var(--polygoncolor1);
}
.BuildingPickButton {
    padding: 0.1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.8rem;
    border-radius: 9999px;
    margin-left: 10vw;
    transition: all var(--css-duration);

    cursor: pointer;
}
.BuildingPickButton:hover {
    background: var(--colortheme2);
    color: black;
}
.BuildingPickButtonLevel {
    margin-left: 2rem;
}
.map_choose_building {
    padding: 1rem;
    background: black;
    color: white;
    z-index: 10;

    position: fixed;
    transition: all 0.5s;
}
.map_choose_level {
    padding: 1rem;
    background: black;
    color: white;
    z-index: 10;

    position: fixed;
    transition: all 0.5s;

    margin-top: 10vh;
}
.map_pickwindow_title {
    color:white;
    padding: 0.5rem;
}
.map_pick_level_button {
    border-width: 0.5px;
    min-width: 2.2rem;
    /* min-height: 1.8rem; */
    margin: 0.1rem;
    display: inline-block;
    padding-top: 0.47rem;
    padding-bottom: 0.47rem;
    outline: none;
    transition: all 0.5s;

    color:white;
}
.floor_plan_inside_head {
    position: relative;
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 0;
    background: var(--cardtitle);
    color: var(--cardtitletext);
    padding: 0.5rem;
    padding-left: 3.5rem;
    --css_duration: var(--css-duration);

    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;

    display:flex;
    align-items: center;
}
.map_pick_level_button:focus {
    background: white;
    color: black;
}
.floor_plan_inside {
    width: 100%;
    height: calc(100%);
    margin-top: 0rem;
    color: var(--cardbodytext);
    --css_duration: var(--css-duration);

    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1), 0 0px 7.5px 0 rgba(0, 0, 0, 0.2);

    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.polygonsvgbody {
    background: rgba(255, 0, 0, 0);
    transition: all var(--css_duration);
    width:100%;
}
.PolygonsTxt {
    margin-top: 2rem;
    background: rgba(255, 255, 255, 0.507);
    padding: 2px;
    font-size: 0.8rem;
}
.PolygonBtn {
    transition: all var(--css_duration);
    color: white;
    margin-left: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background: rgba(182, 0, 152, 0.664);

    width: 4rem;
}
.PolygonBtn:hover {
    background: rgb(47, 0, 40);
}
.PolygonBtnContainer {
    display: none;
}
.PolygonDelBtn {
    background: rgba(100, 250, 0, 0.521);
    padding: 0.3rem;
    margin-top: 0.5rem;
    cursor: pointer;
    transition: all var(--css-duration);
}
.PolygonDelBtn:hover {
    background: rgba(100, 250, 0, 1);
}
.ShowDeletePolygonList {
    display: flex;
    position: absolute;
    flex-direction: row;
    top: 0;
    right: 1rem;
}

circle {
    cursor: pointer;
    transition: all var(--css_duration);
}

.circleItem {
    animation: blink 1s infinite ease-in-out;
}
.circleItem2 {
    animation: blink2 2s infinite ease-in-out;
}

@keyframes blink {
    /* coldbox css overwrite this, ctrl shift F to find css file */
	/* 0% {
        stroke-width: 7px;
        stroke: rgba(255, 0, 0, 0.8);
    }
	100% {
        stroke-width: 3rem;
        stroke: rgba(255, 0, 0, 0);
	} */
}
@keyframes blink2 {
	0% {
        stroke-width: 0px;
        stroke: rgb(255, 0, 0);
    }
	100% {
        stroke-width: 17px;
        stroke: rgba(255, 0, 0, 0);
	}
}

text {
    fill: var(--focuscardbackground);
    font-size: 15px;
    font-weight: 800;
    letter-spacing: 2px;
}