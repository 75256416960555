.nav_popup_maindiv {
    position: fixed;
    right: 0;
    top: 3.6rem;
    width: 20rem;
    height: calc(100vh - 3.6rem);
    background: linear-gradient(to left, var(--slideBG_1),
    var(--slideBG_2), var(--slideBG_3));
    backdrop-filter: blur(20px);
    color: var(--optiontext);
    z-index: 10;
    opacity: 1;

    padding: 1.5rem;

    display: flex;
    flex-direction: column;
}

.NavPopUpTitle {
    font-size: 1.5rem;
    color: var(--optiontext);
    margin-bottom: 2rem;
}

.NavPopupCloseBtn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    padding: 0.5rem;
    transition: all var(--css-duration);

    cursor: pointer;
}

.NavPopupCloseBtn:hover {
    background: rgba(255, 255, 255, 0.7);
}

@keyframes modalSlideFade {
    from {transform: translateX(50%);opacity: 1;}
    to {transform: translateX(0);opacity: 1;}
  }
  
.nav_popup_maindiv {
    animation-name: modalSlideFade;
    animation-duration: 0.5s;
    box-shadow: 0 0 32px rgba(0,0,0,.3), 0 0 64px rgba(0,0,0,.3);
}

.NavpopupUserImage {
    width: 100%;
    min-height: 10rem;
    margin-top: 1rem;
    border-radius: 0.5rem;
    border-color: var(--colortheme1);
}

.NavpopupUserImage.noImage {
    background: linear-gradient(to bottom, rgba(0,0,0,0.05), rgba(0,0,0,0.15));
    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.1) inset;
}
.NavpopupUserImage.noImage * {
    font-size: 3.5rem;
    background: linear-gradient(to bottom, rgba(0,0,0,0.6), rgba(0,0,0,0.3));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.NavbarPopupUsername {
    font-size: 1.5rem;
    margin-top: 0.6rem;
}

.NavPopupPermissionLevel {
    display:flex;
    flex-direction: row;
    padding: 0.5rem;
    padding-left:0;
    justify-content: space-between;
    font-size: 1.2rem;
}

.NavbarPopupAccInfoMainDiv {
    height: 100%;
    margin-top: 2rem;
    border-radius: 0.5rem;
    background: rgba(0, 0, 0, 0.07);
    padding: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.NavbarPopupButton {
    background: var(--focuscardbackground);
    color: var(--optiontext);
    padding:0.5rem;
    margin:0rem;
    margin-top: 1rem;
    text-align: center;
    font-size: 1.3rem;
    cursor: pointer;
    border-radius: 0.5rem;
    
    transition: all var(--css-duration);

    background: rgba(0, 0, 0, 0);
    padding: 0.5rem;
    width: 100%;
    border-width: 1px;
    border-color: var(--optiontext);
    border-radius: 0.5rem;

    transition: all 0.5s;
    cursor: pointer;
}

.NavbarPopupButton:hover {
    box-shadow: 0 0 10px 100px rgba(0, 0, 0, 0.185) inset;
}

.NavPopUpDarkMode {
    background: rgba(216, 216, 216, 0.39);
    padding: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0.5rem;

    margin-top: 0.5rem;
    width: 3rem;
    font-size: 1.2rem;
    border-width: 1px;
    border-color: rgba(119, 119, 119, 0.507);

    cursor: pointer;
    outline: 0;

    transition: all var(--css-duration);
}

.NavPopUpDarkMode:hover
{
    box-shadow: 0px -50px 0 0 var(--focuscardbackground) inset;
}

.NavPopupPickColor {
    width: 100%;
    background: rgba(0, 0, 0, 0.15);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15), rgba(54, 54, 54, 0.1));
    margin-top: 1rem;
    border-radius: 1rem;
    padding: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;

    transition: all var(--css-duration);
}

.ColorThemeRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
    margin-top: 0.5rem;
    opacity: 0.4;
    transition: all var(--css-duration);
}
.ColorThemeRow:hover {
    opacity: 1;
    margin-left: -1rem;
}
.ColorThemeRow_colorBox {
    width: 25%;
    padding: 1rem;
    font-size: 0.8rem;
    color: transparent;
    border-radius: 0.5rem;
    margin-right: 0.1rem;
}

.SoundEffect {
    background: rgba(199, 199, 199, 0.418);
    background: linear-gradient(to bottom, rgba(216, 216, 216, 0.4), transparent);
    margin-top: 1rem;
    text-align: center;
    padding: 1rem;
    border-radius: 0.5rem;
    border-width: 1px;
    border-color: grey;

    cursor: pointer;
    transition: all var(--css-duration);
}

.SoundEffect:hover {
    box-shadow: 0 -80px 0 0 var(--focuscardbackground) inset;
    color: white;
}

.SideNavRowItemText {
    margin-left: 1rem;
}

.logout-most-bottom {
    width: 17rem;
    background: rgba(255, 255, 255, .05);
    color: var(--optiontext);
    border-radius: 0.5rem;
    padding: 0.5rem;
    font-size: 1.2rem;
    margin-top: 0.6rem;
    margin-bottom: 0rem;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    transition: all 0.8s;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.05);
}

.logout-most-bottom:hover {
    box-shadow:  0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.05),
    0px -50px 0 0 var(--focuscardbackground) inset;
    color: white;
}

.NavLogoutTxt {
    padding-left: 1rem;
}

.NavLogoutIcon {
    margin-left: 1rem;
    text-align: center;
    min-width: 2rem;
}

.NavKeyIcon {
    margin-right: -0.5rem;
    text-align: center;
    min-width: 2rem;
}
.NavPopupSubtitle {
    font-size: 0.8rem;
    margin-top: 0.3rem;
}

.NavPopupPermissionContainer {
    height: 100%;
    margin-top: 0.3rem;
    display: flex;
    flex-direction: column;
    /* background: rgba(0, 0, 0, 0.1); */
    padding: 1rem;
    border-radius: 0.3rem;
}
.NavPopupPermissionItem {
    background: var(--focuscardbackground);
    color: white;
    padding: 0.2rem;
    padding-left: 1rem;
    border-radius: 0.3rem;
    margin-top: 0.2rem;
}

.navpopup_accessTxt {
    color: white;
    background: rgba(0, 0, 0, 0.4);
    padding: 0.25rem;
    padding-left: 1rem;
    border-radius: 0.3rem;
}



.popup_notification_listContainer {
    width: 100%;
    height: calc(100vh - 20rem);
    overflow-y: auto;
}

/* Small size screen force resize css */
@media only screen and (max-height: 790px) {
    /* Left Slide */
    .NavPopUpTitle {
        margin-bottom: 0;
        margin-top: 0rem;
    }

    /* Right Slide */
    .nav_popup_maindiv {
        display: flex;
        flex-direction: column;
        max-height: 100%;
        overflow-y: scroll;
    }
    .NavbarPopupAccInfoMainDiv {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 0;
        height: 85%;
    }
    

    .popup_notification_listContainer {
        height: calc(100vh);
    }

    /* Right Slide, user info */
    .NavpopupUserImage {
        margin-top: 0;
    }
    .logout-most-bottom {
        margin-top: 0.5rem;
        position: relative;
        bottom: 0;
    }
}

.popup_notification_loading {
    width: 100%;
    height: 30rem;
    border-radius: 0.3rem;
    animation-duration: 10s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    /* background: linear-gradient(to right, rgba(255, 255, 255, 0.2) 8%, rgb(255, 255, 255) 24%, rgba(255, 255, 255, 0.2) 40%); */
    /* background-size: 800px 104px; */
    background: linear-gradient(to right, rgba(255, 255, 255, .1) 8%, rgb(255, 255, 255, .3) 24%, rgba(255, 255, 255, .1) 40%);
    background-size: 160rem 104px;
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -4000px 0
    }
    100%{
        background-position: 4000px 0
    }
}

.navpopup_accessList {
    max-height: 10rem;
    overflow-y: auto;
}