.ReportIssue_backBtn
{
    cursor: pointer;
    padding: 1rem;
    background: var(--colortheme1);
    color: white;
    position: absolute;
    left: 1rem;
    margin-top: 1rem;
    border-radius: 9999px;
    opacity: 0.7;
    transition: all var(--css-duration);
}
.ReportIssue_backBtn:hover
{
    /*transform: rotate(360deg);*/
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}

.ReportIssue_maindiv
{
    /*position: absolute;
    bottom: 0;
    left: 6vw;*/

    background: rgba(0, 0, 0, 0.5);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6));
    /*background: linear-gradient(30deg, rgba(0, 0, 0, 1), rgb(0, 0, 0, 0)) 0% 0% / cover,
     url(../../assets/images/admin.jpg) no-repeat;*/
    background-position: center center;
    background-size: cover;
    width: 88vw;
    height: auto;
    padding-top: 6rem;
    padding-bottom: 10rem;

    display: flex;
    flex-direction: column;
    margin:auto;
    margin-top: 4rem;
    border-radius: 1rem;
}

.innerBackgroundImg
{
    width: 100%;
    height: 90%;
    position: absolute;
    top: 0;
    display:none;
}

.ReportIssue_EmailOutside
{
    margin: auto;
    margin-top: 2rem;
    background: rgba(255, 255, 255, 0.2);
    padding: 0.8rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    color: white;
    border-radius: 9999px;

    font-size: 1.3rem;
}

.ReportIssue_EmailInput
{
    background: transparent;
    color: white;
    padding-left: 1rem;
    width: 20rem;
}

.ReportIssue_ContactOutside
{
    margin:auto;
    background: rgba(255, 255, 255, 0.2);
    padding: 0.8rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    color: white;
    border-radius: 9999px;

    font-size: 1.3rem;
    margin-top: 2rem;
}

.ReportIssue_ContactInput
{
    background: transparent;
    color: white;
    padding-left: 1rem;
    width: 20rem;
}

.ReportIssue_textarea
{
    margin: auto;
    margin-top: 2rem;
    background: rgba(255, 255, 255, 0.2);
    padding: 1.5rem;
    border-radius: 1.3rem;
    color: white;

    font-size: 1.3rem;
}

.ReportIssue_sbumitBtn
{
    cursor: pointer;
    background: rgb(255, 255, 255, 0.2);
    color: white;
    margin: auto;
    margin-top: 2rem;
    font-size: 1.7rem;
    padding: 1rem;
    padding-left: 8rem;
    padding-right: 8rem;

    border-radius: 9999px;
    transition: all var(--css-duration);
}

.ReportIssue_sbumitBtn:hover
{
    background: rgb(255, 255, 255, 0);
    box-shadow: 0 -80px 0 0 rgb(255, 255, 255, 0.4) inset;
}

.ReportIssue_alertPopup
{
    position: fixed;
    top: 40vh;
    left: 30vw;
    width: 40vw;
    height: 20vh;

    background: rgba(0, 0, 0, 0.5);
    color: white;
    z-index: 20;

    border-radius: 1rem;
    padding: 2rem;
}

.hideAlert
{
    display: none;
    opacity: 0;
}

.showAlert
{
    display: flex;
}

.ReportIssue_alertPopBg
{
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left:0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
}

.ReportIssue_closeBtn
{
    position: absolute;
    top: 2rem;
    right: 2rem;
    transition: all var(--css-duration);

    cursor: pointer;
}

.ReportIssue_closeBtn:hover
{
    transform: rotate(90deg);
}

.ReportIssue_warnIcon
{
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 3rem;
}

.ReportIssue_alertMsg
{
    font-size: 1.5rem;
    position: absolute;
    top: 6rem;
    width: 100%;
    text-align: center;
}