.small_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.FloorPlan_Widget_SummaryCard1_row
{
    width: 100%;
    max-width: 29rem;
    margin: auto;
    /* height: 40%; */
    /* background: rgb(255, 255, 255, .2); */
    display:flex;
    flex-direction: row;
    justify-content: space-between;

    padding-left: 4rem;
    padding-right: 2rem;
}

.FloorPlan_Widget_SummaryCard1_row2
{
    width: 100%;
    max-width: 29rem;
    margin: auto;
    /* height: 60%; */
    /* background: rgb(255, 255, 255, .2); */
    display:flex;
    flex-direction: column;
    justify-content: space-between;

    padding-left: 4rem;
    padding-right: 2rem;
}

.FloorPlan_Widget_SummaryCard1_rowitem
{
    margin:0;
    width: 50%;
    /* background: transparent; */
    display:flex;
    flex-direction: row;
    justify-content: space-between;

    font-size: 3rem;
}

.FloorPlan_widget_SummaryCard_icon
{
    /* font-size: 4rem; */
    margin: auto;

    padding-top: 0rem;

    filter: drop-shadow(0 6px 2px rgba(0, 0, 0, 0.15));
}

.FloorPlan_widget_SummaryCard_txt
{
    font-size: 3.5rem;
    display:flex;
    flex-direction: row;

    margin-left: 0.5rem;
}

.FloorPlan_widget_SummaryCard_txt_unit
{
    font-size: 1rem;
    display:flex;
    flex-direction: row;
    margin-top: 0.5rem;
}

.FloorPlan_widget_SummaryCard_txt_unit2
{
    font-size: 0.5rem;
}

.Summarybottomrow
{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.summarycardbottomunit
{
    margin-top: -0.5rem;
    /* color: var(--optiontext); */
}

.FloorPlan_widget_SummaryCard_iconBot
{
    font-size: 2.5rem;
    /* margin-top:0rem; */
    /* color: var(--optiontext); */
}
.FloorPlan_Widget_SummaryCard1_rowitem2
{
    margin:auto;
    width:auto;
    display:flex;
    flex-direction: row;

    font-size: 3rem;
}

.cardcolor-red
{
    color: #f30000;
    background: transparent;
}

.cardcolor-blue
{
    color:#00abff;
    background: transparent;

    /*margin-left: 2rem;
    padding-left: 1rem;*/
    margin-left: 0;
    padding-left: 0.5rem;
}

.cardcolor-brown
{
    color: rgb(206, 47, 206);
}

.cardcolor-green
{
    /* color:#008493; */
    color: var(--optiontext);
    opacity: .7;
    background: transparent;
}

.SummaryCard1_readinginnerDiv
{
    background: rgba(0, 128, 0, 0);
    /* margin:auto; */

    /* min-height: 10rem; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}

.FloorPlan_SummaryCard1_topItem {
    display: flex;
    flex-direction: column;
    align-self: center;

    min-width: 10rem;
    /* max-width: 10rem; */
    width: 50%;
}
.FloorPlan_Widget_SummaryCard1_title {
    font-size: 1rem;
    text-align: start;
    letter-spacing: 2px;
    color: var(--optiontext);
    opacity: .7;

    margin-top: -.5rem
}
.co2container {
    min-width: 10rem;
    /* max-width: 10rem; */
    width: 50%;
}

.HideRow {
    display: none;
}

/* Small size screen force resize css */
/* @media only screen and (max-height: 790px) {   
    .FloorPlan_Widget_SummaryCard1_row {
        width: 20rem;
        justify-content: space-between;
    }
    .FloorPlan_Widget_SummaryCard1_row2 {
        width: 20rem;
    }
    .FloorPlan_widget_SummaryCard_icon {
        font-size: 2.5rem;
    }
    .FloorPlan_widget_SummaryCard_txt {
        font-size: 3rem;
    }
    .FloorPlan_widget_SummaryCard_iconBot{
        font-size: 2rem;
    }
} */