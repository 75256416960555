.Lift_recordsContainer
{
    /* background: rgba(255, 255, 255, 0.4); */
    border-radius: 0.5rem;
    color: black;
    height: 100%;
    /* padding: 0.5rem; */

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    /* box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2); */
    background: linear-gradient(90deg,hsla(0,0%,100%,.329),hsla(0,0%,100%,.425));
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
}

.LiftRecord_displayFloorContainer
{
    width: 100%;
    height: 100%;
    align-self: center;
    /* background: linear-gradient(to bottom right, rgba(255, 255, 255, 0), 
    rgba(255, 255, 255, 0.5), rgb(255, 255, 255)); */
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    font-size: 5rem;
    color: rgba(0, 0, 0, 0.4);

    /* box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,.2); */
    background: linear-gradient(90deg,hsla(0,0%,100%,.329),hsla(0,0%,100%,.425));
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
}
.LiftRecord_DisplayIcon
{
    margin:auto;
}
.LiftRecord_displayFloorTxt
{
    margin:auto;
    font-size: 5rem;
}
.Lift_record_displayBG {
    background-image: url(./../../../../src/assets/images/loading_hk.jpg);
    background-position: center;
    background-size: cover;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    height: 55%;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,.2);
}
.Lift_recordFloorDisplay {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    backdrop-filter: blur(10px);
    color: white;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    height: 100%;
}
.Lift_record_companyIcon {
    background-image: url(./../../../../src/assets/images/floors//Lift/company.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 35%;
    margin: auto;
}

.LiftRecord_ListFloorContainer
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: flex-start;
    align-content: center;
    gap: 0.5rem;
    margin: auto;
}
.LiftRecord_ListFloormain {
    height: 100%;
    widows: 100%;
    border-radius: .5rem;
    /* background: linear-gradient(90deg,hsla(0,0%,100%,.329),hsla(0,0%,100%,.425)); */
    background: var(--cardbodybackground);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
}
.LiftRecord_LiftlevelItem
{
    color: var(--optiontext);
    border-width: 1px;
    border-color:rgba(0, 0, 0, 0.1);
    border-radius: 999rem;
    text-align: center;
    transition: all var(--css-duration);
    font-size: 1.5rem;
    padding-top: 0.5rem;
    width: 2.5rem;
    height:2.5rem;

    align-self: center;

    background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
}
.LiftRecord_LiftlevelItem * {
    /* text inside */
    margin-top: -.1rem;
}

.LiftCurrentLevelClass
{
    color: white;    
    border-color:var(--focuscardbackground);
    background: linear-gradient(to bottom, var(--focuscardbackground), var(--focuscardbackground));

    box-shadow: 0 0 10px 0 var(--focuscardbackground);
}





.Lift_bottomSiteName {
    color: white;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    align-self: flex-start;
    padding: 1rem;
    padding-left: 2rem;
    position: absolute;
    width: 100%;
    backdrop-filter: blur(5px);
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    text-align: center;
}
.Lift_bottomSiteDetail {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,.1);
}
.Lift_bottomPhotoContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.Lift_bottomImage_1 {
    width: 100%;
    background-size: cover;
    background-image: url(../../../assets/images/lift/IoTLift_1.jpg);
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.3);
}
.Lift_bottomImage_2 {
    width: 100%;
    background-size: cover;
    background-image: url(../../../assets/images/lift/IoTLift_2.jpg);
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.3);
}
.Lift_bottomSiteName {
    font-size: 1rem;
    letter-spacing: 2px;
}

/* show lift name */
.LiftRecord_LiftName {
    margin-left: 0;
    color: white;
    letter-spacing: 5px;
    /* transform: rotate(90deg); */
    height: 100%;
    /* padding: .5rem; */
    border-radius: .3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,.1);
}
.LiftName_SiteName {
    background: rgba(0, 0, 0, 0.2);
    text-align: center;
    backdrop-filter: blur(4px);
    padding: .5rem;
    font-size: 2rem;
}
.LiftName_SiteDesc {
    background: rgba(0, 0, 0, 0.5);
    padding: .5rem;
    text-align: center;
}
.LiftName_LiftName {
    font-size: 1.5rem;
    padding: .5rem;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    height: 100%;
}
.LiftName_LiftDesc {
    font-size: 1rem;
    background: rgba(0, 0, 0, 0.3);
    padding: .5rem;
    backdrop-filter: blur(4px);
    text-align: center;
}
/* end of lift name */

/* small screen size: */
@media only screen and (max-height: 790px) {
    .LiftRecord_ListFloorContainer {
        width: 25rem;
    }
}