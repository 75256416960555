.PRD_Table {
    margin-top: 1.5rem;
    border-radius: .5rem;
    padding: .5rem;
    background: hsla(0,0%,50.2%,.3);
    color: var(--optiontext);

    display: flex;
    flex-direction: row;
    align-items: center;
}
.PRD_Table .col1 {
    min-width: 8rem;
    max-width: 8rem;
    margin-left: .8rem;
    margin-right: .8rem; 
}
.PRD_Table .col2 {
    min-width: 12rem;
    max-width: 12rem;
    margin-left: .8rem;
    margin-right: .8rem;   
}
.PRD_Table .col3 {
    width: 100%;
}
