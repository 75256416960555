.Toilet_Em {
    background: rgb(236, 20, 90);
    color: white;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.4);

    width: 100%;
    height: 100%;
    width: 9rem;

    border-radius: 0.5rem;
    font-size: 4rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    cursor: pointer;
    transition: all 0.3s ease-out;
}
.Toilet_Em:hover {
    color: white;
    background: rgb(255, 153, 0);
}

.Toilet_Em:active {
    transform: scale(0.9);
}

.Toilet_Em_title {
    font-size: 1rem;
}




.Toilet_Em_bg {
    position: fixed;
    left: -3rem;
    top: -3rem;
    width: 35rem;
    height: 24rem;
    background: rgba(0,0,0,0);
    backdrop-filter: blur(5px);
    border-radius: 0.5rem;

    z-index: 10;
}
.Toilet_Em_popup {
    position: absolute;
    /* top: calc((100vh - 2.5vw - 1.4rem - 1.2rem - 0.5rem - 17rem)/2);
    left: calc((88vw - 1rem - 30rem)/2); */

    background: rgba(255, 255, 255, 0.95);
    color: rgba(0,0,0,0.7);
    filter: drop-shadow(0 5px 2px rgb(0, 0, 0, 0.3));
    width: 30rem;
    height: 17rem;
    padding: 1rem;
    border-radius: 0.5rem;

    animation: ToiletEm_popup 0.2s ease-in-out;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
}
@keyframes ToiletEm_popup {
    0% {
        opacity: 0;
        transform: translateX(-30%);
    }
    100% {
        opacity: 1;
    }
}

.Toilet_Em_popupclose {
    text-align: right;
    padding: 0.5rem;
    opacity: 0.7;

    cursor: pointer;
}

.Toilet_Em_popupIcon {
    color: rgb(236, 20, 90);
    font-size: 4rem;
    align-self: center;
}
.Toilet_Em_popupdesc {
    font-size: 1.5rem;
    opacity: 0.8;
}
.Toilet_Em_popupoptionList {
    height: 100%;
    width: 100%;
    margin-bottom: 5px;
    overflow-y: auto;
    flex-wrap: wrap;

    display: flex;
    gap: 10px;
}
.Toilet_Em_popupoptionList .option {
    font-size: 1.3rem;
    padding: 0.3rem;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    opacity: 0.5;
    border-radius: 0.3rem;

    text-align: center;
    letter-spacing: 1px;

    transition: all ease 0.5s;

    min-width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}
.Toilet_Em_popupoptionList .option:hover {
    background: var(--focuscardbackground);
}
.Toilet_Em_popupoptionList .option.selected {
    background: rgb(236, 20, 90);
    opacity: 1;
}
.Toilet_Em_popupbtns {
    display: flex;
    justify-content: space-between;
}
.Toilet_Em_popupbtn1,
.Toilet_Em_popupbtn2 {
    padding: 0.5rem;
    width: 12rem;
    border: 1px solid rgba(0,0,0,0.7);
    border-radius: 0.5rem;
    text-align: center;

    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.Toilet_Em_popupbtn1 {
    background: rgb(236, 20, 90);
    border: 1px solid rgb(236, 20, 90);
    color: white;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,0.2);
    opacity: 0.8;
}
.Toilet_Em_popupbtn1:hover {
    opacity: 1;
    background: rgb(236, 20, 90);
    border: 1px solid rgb(236, 20, 90);
}





.Toilet_Em_On {
    align-items: center;
    background: rgb(236, 20, 90);
    color: white;
}
.toilet_submitted {
    align-items: center;
    background: rgb(20, 132, 236);
    color: white;
}
.Toilet_Em_Off {
    background: white;
    color: rgb(20, 78, 236);
    width: 100%;
    padding: 0.5rem;
    font-size: 1.5rem;
    text-align: center;
    border-radius: 0.5rem;
    cursor: pointer;
}


.Toilet_Em_Log {
    background: black;
    color: white;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.441);
    position: absolute;
    left: 110%;
    top: 0;
    width: 30rem;
    border-radius: 0.5rem;

    max-height: 20rem;
    overflow-y: auto;
    overflow-x: hidden;
}
.Toilet_Em_Log_row {
    padding: 10px;

    animation: logAppear 0.5s ease-in;
}
@keyframes logAppear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}