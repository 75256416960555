.Toilet_ad_floorplanMain {
    width: 100%;
    height: 100%;
    background: white;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
    padding: 1.5rem;
    border-radius: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
}