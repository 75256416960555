/* .Cdbx_pop_warn {
    position: fixed;
    width: 50rem;
    height: 30rem;
    left: calc((100vw - 50rem)/2);
    top: calc((100vh - 30rem)/2);

    animation: GD_PopupAppear .6s ease-out 0s 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: center;
    padding: 1rem;
    z-index: 1000;
    backdrop-filter: contrast(40%);
}
.Cdbx_pop_warn * {
    margin: auto;
    color: #fff;
    font-size: 1.5rem;
    letter-spacing: 2px;
} */



.ColdboxIndi_popupMain .row {
    display: flex;
    flex-direction: column;
    gap: 5px;
}