.LoadingMain {
    height: 100%;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.2); */

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--focuscardbackground);
    font-size: 2rem;
}