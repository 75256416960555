.ColdboxSetpoint {
    width: calc(100% - 4rem);
    height: calc(100% - 5rem);
    transition: all var(--css-duration);
    padding: 1rem;
    border-radius: .3rem;
    margin: auto auto 0;
    /* background: linear-gradient(180deg,var(--maindiv-color2),var(--maindiv-color));
    -webkit-filter: drop-shadow(0 10px 15px rgba(0,0,0,.3));
    filter: drop-shadow(0 10px 15px rgba(0,0,0,.3)); */
    -webkit-animation: draglayoutOnload 0.5s ease-out;
    animation: draglayoutOnload 0.5s ease-out;
    display: flex;
    flex-direction: column;
}

.ColdboxSetPt_Table {
    height: 100%;
    background: rgba(0,0,0,.1);
    margin-top: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,.224);
    border-radius: .5rem;

    overflow-y: auto;
}

.CdbxSetPt_Unfindbox {
    height: calc(100% - 10rem);
    background: rgba(0,0,0,.1);

    display: flex;
    flex-direction: column;
    align-items: center;
}
.CdbxSetPt_Unfindbox * {
    margin: auto;
    font-size: 1.5rem;
}

.ColdboxSetPt_btnrow {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 43.5rem;
    margin-top: 1rem;
}
.ColdboxSetPt_btn {
    min-width: 8rem;
    margin-right: 1rem;
    padding: .3rem;
    border-radius: .3rem;
    text-align: center;
    background: rgba(128, 128, 128, 0.6);
    color: white;

    cursor: pointer;
    transition: all var(--css-duration);
}
.ColdboxSetPt_btn:hover {
    background: rgba(128, 128, 128, 1);
}


.ColdboxSetPt_Download {
    position: fixed;
    top: calc((100vh - 20rem)/2);
    left: calc((100vw - 10rem)/2);
    background: url(../../../assets/images/CPSL/bg1.jpg), radial-gradient(circle, rgb(104, 104, 104), rgb(0, 0, 0));
    background-position: center;
    background-size: cover;
    background-blend-mode: multiply;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.502);
    width: 20rem;
    height: 10rem;
    border-radius: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    animation: 0.5s opacityShow ease;
}
@keyframes opacityShow {
    0% {
        opacity: 0;
    }
}
.ColdboxSetPt_Download a {
    background: rgb(1, 101, 91, .7);
    backdrop-filter: blur(5px);
    padding: 1rem;
    border-radius: .3rem;
    transition: all var(--css-duration);
}
.ColdboxSetPt_Download a:hover {
    background: var(--focuscardbackground);
}
.ColdboxSetPt_DownloadClose {
    min-width: 8rem;
    margin-top: 1rem;
    padding: .3rem;
    border-radius: .3rem;
    text-align: center;
    background: rgba(128, 128, 128, 0.1);
    backdrop-filter: blur(5px);
    color: white;
    cursor: pointer;
    transition: all var(--css-duration);
}
.ColdboxSetPt_DownloadClose:hover {
    background: var(--focuscardbackground);
}
.ColdboxSetPt_DownloadBG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background: rgba(0,0,0,.224);
    animation: 0.5s opacityShow ease;
}