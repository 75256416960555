.LiftAlarmHeatmap
{
    width: 100%;
    height: 100%;
    /* background: linear-gradient(to right, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.3));   */
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.LiftAlarm_table1_Container {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: calc(100%);
    /* background: rgba(255, 0, 0, .3); */
}

.LiftStartup_search {
    font-size: .8rem;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    padding: .5rem;
    /* background: rgba(0, 0, 0, .15); */
    color: var(--optiontext);

    /* filter: drop-shadow(0 3px 5px black); */
}
.LiftStartup_searchTitle {
    align-self: center;
    color: var(--optiontext);
    opacity: .8;
    letter-spacing: 1px;
}

.LiftStartup_rowheader {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background: var(--focuscardbackground);
    color: white;
    padding: .5rem;
    border-radius: .3rem;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.18), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
    margin-left: 1rem;
    margin-right: 1rem; 
}
.LiftStartup_row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background: var(--cardbodybackground);
    padding: .5rem;
    color: var(--optiontext);
    margin-top: .3rem;
    border-radius: .3rem;
    transition: all .5s;
    filter: drop-shadow(0 3px 6px rgba(0, 0, 0, .2));
    margin-left: 1rem;
    margin-right: 1rem;
}
.LiftStartup_row:hover {    
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
    z-index: 100;
    /* transform: translateX(-3px); */
}
.LiftStartup_col {
    width: 33%;
    text-align: center;
}