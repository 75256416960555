.ToiletAdmin_chart {
    background: white;
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 30%);
    border-radius: 0.5rem;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ToiletAdmin_title {
    color: grey;
    font-size: 1.2rem;
    letter-spacing: 3px;

    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.ToiletAdmin_Reading {
    display: flex;
    gap: 0.5rem;
    font-weight: 100;
    letter-spacing: 0px;
}
.ToiletAdmin_Reading .readingtitle {
    font-size: 1rem;
    color: rgba(128, 128, 128, 0.7);
}
.ToiletAdmin_Reading .reading {
    font-size: 2.5rem;
    color: rgb(0, 193, 193);
    font-weight: 100;
}



.Toilet_HighchartWidget {
    height: 100%;
}

/* Select reading type */
.ToiletAdmin_tabs {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    height: 2rem;
}
.ToiletAdmin_tab {
    color: rgba(0, 0, 0, 0.7);
    width: 6rem;
    text-align: center;
    cursor: pointer;
    z-index: 2;
    
    transition: all 0.7s cubic-bezier(0.4,-0.18, 0.32, 1.43);
}
.ToiletAdmin_tab_ribbon {
    position: absolute;
    width: 6rem;
    height: 2rem;
    background: var(--focuscardbackground);
    z-index: 1;

    border-radius: 5px;
    transition: all 0.7s cubic-bezier(0.4,-0.18, 0.32, 1.43);
}


/* Loading Widget */
.ToiletAdminIAQChartLoading {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 2.5rem;
    color: grey;
}