.SiteWidget_div {
    width: 100%;
    height: 100%;
    border-radius: 0.4rem;
    transition: all 0.4s;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    z-index:0;
    color: var(--optiontext);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.18), 0 5px 5px 0 rgba(0, 0, 0, 0.1);

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.SiteWidget_header {
    width: 100%;
    height: 5rem;
    font-size: 1.5rem;
    text-align: center;

    color: white;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .6));
    padding: .5rem;
    backdrop-filter: blur(6px);

    border-radius: .3rem;

    transition: height 0.4s ease-out;
}
.SiteWidget_detail {
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4));
    backdrop-filter: blur(10px);
    /* font-size: 1rem; */
}
.SiteWidget_des {
    font-size: 1rem;
}
.SiteWidget_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin-bottom: .3rem; */
}
.SiteWidget_sitedisplay {
    letter-spacing: 2px;
    font-size: 1.2rem;
    width: 100%;
    text-align: start;
}
.SiteWidget_leveldisplay {
    font-size: 1rem;
    width: 100%;
    text-align: start;
}
.SiteWidget_sitePickBtn {
    font-size: 1rem;
    align-self: center;
    width: 4rem;
    padding: .3rem;
    border-radius: .3rem;
    background: var(--colortheme1);
    cursor: pointer;
    transition: all var(--css-duration);
}
.SiteWidget_sitePickBtn:hover {
    box-shadow: 0 -30px 0 0 var(--focuscardbackground) inset;
}
.SiteWidget_siteLevelBtn {
    font-size: 1rem;
    align-self: center;
    width: 4rem;
    padding: .3rem;
    margin-top: 2px;
    border-radius: .3rem;
    background: var(--colortheme1);
    cursor: pointer;
    transition: all var(--css-duration);
}
.SiteWidget_siteLevelBtn:hover {
    box-shadow: 0 -30px 0 0 var(--focuscardbackground) inset;
}

.SiteWidget_levelList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    margin: 1rem;

    animation: 0.5s Appear ease-out;
}
.SiteWidget_levelItem {
    padding: .5rem;
    background: rgba(0, 0, 0, .3);
    color: white;
    font-size: .8rem;

    border-radius: .3rem;

    cursor: pointer;
}
.SiteWidget_siteList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    animation: 0.5s Appear ease-out;
}

.pickedlevel {
    background: var(--focuscardbackground);
    width: 2rem;
    height: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
}
.SiteWidget_pickSiteTitle {
    width: 100%;
    margin-bottom: .5rem;
}
.SiteWidget_siteList {
    height: 12rem;
    overflow: auto;
}
.SiteWidget_siteItem {
    background: rgba(0, 0, 0, .4);
    width: 100%;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: .3rem;
    margin-bottom: 5px;
    align-self: center;

    cursor: pointer;
}
.pickedSite {
    background: var(--focuscardbackground);
}

.SiteWidget_cancelBtn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100%);
    /* background: rgba(0, 0, 0, .4); */
    border-bottom-left-radius: .3rem;
    border-bottom-right-radius: .3rem;

    cursor: pointer;

    animation: widgetCancelBtnOnload var(--css-duration) ease-out;
}
@keyframes widgetCancelBtnOnload {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
    }
}
@keyframes Appear {
    0% {
        opacity: 0;
    }
}


/* Responsive css */
@media only screen and (max-height: 790px) {
    .SiteWidget_sitedisplay {
        font-size: .8rem;
    }
    .SiteWidget_leveldisplay {
        font-size: .8rem;
    }
    .SiteWidget_sitePickBtn {
        font-size: .8rem;
    }
    .SiteWidget_siteLevelBtn {
        font-size: .8rem;
    }

    .SiteWidget_siteItem{
        font-size: 1rem;
        height: auto;
        padding-top: .3rem;
        padding-bottom: .3rem;
    }
}