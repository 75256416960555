/* this is BottomDsplayCards.css */

.BottomCardTitleLine
{

}

.BottomCardTitle
{
    font-size: 1rem;
    margin-bottom: 0.1rem;
}

.BottomCardReadingBody
{
    
}

.BottomCardReadingLine
{
    font-size: 2.8rem;
    /*transition-duration: var(--css-duration);*/
}

.BottomCardReadingSymbol
{
    font-size: 0.8rem;
    margin-top: 0.2rem;
}

.BottomCardReadingSymbolSmall
{
    font-size: 0.6rem;
}

.BottomCardIcon
{
    font-size: 0.6rem;
}

.card
{
    background: var(--cardbodybackground);
    /*color: var(--cardbodytext);*/
    /* padding x 5, divided to 6 cards */
    /* width: calc((85vw - 14.5vw - 3.6rem)/6); */
    width: calc((100% - 0.5rem * 5)/6 - 0rem);
    height: 10.5rem;
    margin-top: 0.5rem;
    margin-left: 0rem;
    transition-duration: var(--css-duration);
}

.card:hover
{
    /*box-shadow:
    0 0 10px var(--colortheme1),
    0 0 20px var(--colortheme1),
    0 0 30px var(--colortheme1),
    0 0 80px var(--colortheme1);*/
    /*text-shadow:
    0 0 10px var(--colortheme2),
    0 0 40px var(--colortheme2);*/
    /*position:relative;*/
    /*top: 5px;*/
    /*left: 0px;*/
    /*background: var(--focuscardbackground);*/
}

.listfocus
{
    background: var(--focuscardbackground);
    color: var(--focuscardtext);
    /*--css_duration: var(--css-duration);*/
    box-shadow:
    0 0 1px var(--focuscardbackground),
    0 0 5px var(--focuscardbackground);
}

.cardTitleText
{
    width: 95%;
}

.cardTitleIcon
{
    margin:auto;
}

.googlemap_buildingname
{
    padding: 0.5rem;
    font-size: 1.2rem;
    background: rgba(0, 0, 0, 0.3);
}

.googlemap_image
{
    width: 100%;
}