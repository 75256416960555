.peopleCountsize {
    width: 100%;
    margin: auto;
}
.people_header {
    text-align: center;
}
.peopleCount_row {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    margin-bottom: 0;
    margin-left: 1rem;
    margin-right: 1rem;
}
.peopleCountIcon {
    font-size: 2rem;
    align-self: center;
}
.peopleCount_title {
    font-size: 2rem;
    width: 4rem;
    align-self: center;
    margin-right: 0.5rem;
}
.peopleCount_reading {
    display: flex;
}
.peopleCount_Value {
    font-size: 2rem;
    width: 4.5rem;
}

.peopleCountInner {
    color: grey;
    color: var(--optiontext);
}
.PeopleOut {
    /* color: rgb(195, 0, 255); */
}
.PeopleIn {
    color: rgb(11, 181, 3);
}