.SetLangContainer
{
    display: flex;
    flex-direction: row;
    background: rgba(255, 255, 255, 0.8);
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    width: 20vw;
    padding:0.5rem;
}

.SetLangnTitle
{
    position: absolute;
    margin-top: 0.2rem;
}

.SetLangDisplay
{
    margin-left: 8rem;
    background: grey;
    color: white;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.2rem;
    border-radius: 0.3rem;

    width: 8rem;
    text-align: center;
}

.SetLangSelect
{
    margin-left: 1rem;
    background: rgba(0, 0, 0, 0.349);
    color: white;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.3rem;
    
    width: 8rem;
    text-align: center;
}