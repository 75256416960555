.dynamicCardPage {
    display: flex;
    width: 100%;
    height: 100%;

    .left,
    .right {
        width: 50%;
        display: flex;
        flex-direction: column;
    }
}