.OprOver_panel {
    background: var(--colortheme2);
    color: var(--optiontext);
    height: 100%;
    width: 100%;
    margin-bottom: 0.5rem;
    border-radius: 0.4rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
}
.PanelTitle {
    padding: 0.5rem;
    font-size: 1.5rem;
    letter-spacing: 2px;
}
.OpOr_Row {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.OpOr_icon {
    filter: drop-shadow(2px 5px 2px rgba(0, 0, 0, 0.3));
}
.OpOr_txt {
    font-size: 1rem;
    opacity: 0.7;
}
.OpOr_DecorateImg {
    height: 100%;
    background: url(../../../../assets/images/CPSL/bg1.jpg), linear-gradient(to bottom, var(--colortheme2) 20%, transparent);
    background-position: center;
    background-size:cover;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
    border-radius: 0 0 0.4rem 0.4rem;
}
.coldbox_OprIcon {
    width: 2rem;
    height: 2rem;
    border-radius: 5px;
    background: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* The Inner icon are copied from table.js */