.BinSummaryCardContainer {
    width: 100%;
    height: 100%;
    background-color: var(--cardbodybackground);
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    box-shadow: 0 15px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.BinSummaryCardHead {
    width: 100%;
    font-size: 1rem;
    background: var(--cardtitle);
    color: var(--cardtitletext);
    padding: 0.5rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;

    display: flex;
    flex-direction: row;
}
.BinSummaryIcon {
    font-size: 1rem;
    margin-left: 0.3rem;
}
.BinSummaryTitle {
    margin-left: 0.5rem;
    letter-spacing: 1px;
}

.BinDisplayInnerBody {
    height: calc(100% - 2rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.BinDisplayTitle {
    font-size: 1.5rem;
    color: black;
    letter-spacing: 1.5px;
    text-align: center;
}
.BinDisplayResult {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.BinResultIcon {
    color:orange;
    font-size: 2.5rem;
}
.BinResultReading {
    font-size: 2.5rem;
    display: flex;
    color: grey;
}
.BinResultReadingUnit {
    font-size: 1rem;
    margin-left: 0.3rem;
}
.NoDevice {
    font-size: 1.8rem;

}