.Setting_div
{

}

.setting_backwardBtn
{
  cursor: pointer;
  padding: 1rem;
  background: var(--colortheme1);
  color: white;
  position: absolute;
  left: 1rem;
  border-radius: 9999px;
  opacity:0.7;
  transition: all var(--css-duration);

  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.18), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.setting_backwardBtn:hover
{
  /*background: var(--colortheme2);*/
  opacity: 1;
  text-shadow:
        0 0 10px #fff,
        0 0 20px #CCC,
        0 0 30px #AAA,
        0 0 80px #888;
}

.setting_title
{
    color: var(--optiontext);
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
    margin: 1.5rem;
}

.setting_subtitle
{
    color: black;
    font-size: 1.5rem;
    margin: 1rem;
}

.setting_subcontrols
{
    color: black;
    font-size: 1rem;
    margin: 1rem;
    padding: 1rem;

    background: var(--maindiv-color);

    display:flex;
    flex-direction: row;
}

/* Setting radio style */
/* The container */
.container_radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    margin-right: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .container_radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .container_radio:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .container_radio input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container_radio input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .container_radio .checkmark:after {
       top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }