.googleMap_centerBtn {
	position: absolute;
	margin-top: 1rem;
	margin-right: 1rem;

	z-index: 20;
	border-width: 1px;
	border-color: var(--focuscardbackground);
	color: white;
	padding: 0.5rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	border-radius: 0.5rem;
	font-size: 1.2rem;

	display: flex;
	flex-direction: row;
	align-self: flex-end;

	cursor: pointer;
	transition: all var(--css-duration);
}

.googleMap_centerBtn:hover {
	border-color: transparent;
	box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}

.googleMap_centerBtn * {
	margin-right: 0.5rem;
}

.googlemap_viewedalarmdiv:hover {
	box-shadow: 0px -90px 0 0 var(--focuscardbackground) inset;
	color: white;
}

.SiteListDiv {
	margin-top: 1rem;
	color: white;
	background: #0066ff30;
	padding: 0.2rem;
}

.SiteListDiv:focus {
	outline: none;
	background: #0066ff45;
}

.googlemap_main_div {
	width: 100%;
	height: 100%;
	background: grey;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3), 0 5px 15px 0 rgba(0, 0, 0, 0.4);
}

.GoogleMap_Marker:hover {
	/*box-shadow:
	0 0 30px #AAA,
	0 0 80px #888;*/
	background: rgba(0, 0, 0, 0);
}

.googlemap_marker_title
{
	background: rgba(0, 0, 0, 0.3);
	padding: 0.5rem;
	border-radius: 0.3rem;
	
	background-color: var(--colortheme1);
	background: linear-gradient(to left top, rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.5));
}

.hideimage {
	display:none
}

.googleMap_alarmCount {
	background: linear-gradient(to bottom right, rgb(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4));
	margin-right: 1rem;
	margin-left: 1rem;
	border-radius: 0.5rem;
	/*color: white;
	width: 100%;
	height: 50%;*/

	display:flex;
	flex-direction: row;
	justify-content: center;
	height: 4rem;
	margin-top: 4rem;
}

.googleMap_CloseButton {
	background: red;
	border-top-right-radius: 1rem;
	padding:1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	position:absolute;
	right: 0;
	top: 0;

	cursor: pointer;
	transition: all var(--css-duration);
}

/*.googleMap_CloseButton:hover {
	/*background: #ffffff88;
	transform: rotate(90deg);
}*/

.googleMap_SelectBuilding {
	background: rgba(0, 0, 0, 0.5);
	color: white;
	padding:1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	cursor: pointer;
	transition: all var(--css-duration);
	border-bottom-left-radius: 0.5rem;
	border-top-left-radius:  0.5rem;

	width: 9rem;
	text-align: center;

	border-right-width: 1px;
	border-color: black;
	margin-bottom: 2rem;
}
.googleMap_SelectBuilding .fa
{
	margin-right: 0.5rem;
}
.googleMap_SelectBuilding2 .fa
{
	margin-right: 0.5rem;
}

.googleMap_SelectBuilding:hover {
	/*opacity: 1;*/
	box-shadow: 0px -50px 0 0 var(--focuscardbackground) inset;
}

.googleMap_SelectBuilding2 {
	/*background: var(--focuscardbackground);*/
	background: rgba(0, 0, 0, 0.5);
	color: white;
	padding:1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	cursor: pointer;
	transition: all var(--css-duration);
	/*opacity: 0.7;*/
	border-bottom-right-radius: 0.5rem;
	border-top-right-radius: 0.5rem;

	width: 9rem;
	text-align: center;
	margin-bottom: 2rem;
}

.googleMap_SelectBuilding2:hover {
	/*opacity: 1;*/
	box-shadow: 0px -50px 0 0 var(--focuscardbackground) inset;
}
.googleMap_SelectBuildingRow {
	margin-left: 1rem;
	margin-right: 1rem;
	margin-top: 0.5rem;
}
.googlemap_image {
	margin: 0;
	padding:0;

	object-fit: cover;  
	width: 15vw; /*flex does not read width if it is larger than parent width*/
	height: 25vh;
}

.googleMap_popupWindow {
	box-shadow: 0 4px 0px 0 rgb(0, 0, 0), 0 2px 10px 0 rgb(0, 0, 0);
	/*border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;*/
	border-radius: 1rem;
	width: 20rem;
	height: 25rem;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 2rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	transition: all 0.5s;
	transition-timing-function: ease;

	position: absolute;
	top: 2rem;
	left: 1rem;
}

.hideSiteDetailClass {
	opacity: 0;
	top: -30rem;
}

.googlemap_marker_title {
	font-size: 1.2rem;	
	margin:auto;
}

.googlemap_marker_newalarmcount {
	border-radius: 999px;
	width: 2rem;
	margin:auto;
	/*background: var(--focuscardbackground);*/
	background: linear-gradient(to bottom right, rgb(25, 155, 25), lime);
	color:white;	
	padding: 0.3rem;
	padding-left: 0.15rem;
	padding-right: 0.15rem;

	position: absolute;
	margin-top: 2.5rem;
}

.googlemap_marker_newalarmreading {
	margin:auto;
	font-size: 1.3rem;
	text-align: center;
}

/* .googlemap_marker_pointer {
	background: transparent;
	color: aquamarine;
	margin-right: 0rem;
	position: absolute;

	z-index: 10;
} */



.googlemap_buildingname {	
	/*border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;*/
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2));
	padding:0.5rem;

	margin-left: 0.5rem;
	margin-right: 0.5rem;
	margin-top: 1.5rem;
	text-align: center;

	border-radius: 0.4rem;
}

.googlemap_stylingdiv,
.googlemap_stylingdivBot {
	position: absolute;
	width: 100%;
	max-width: 110rem;
	min-width: 1024px;	
	z-index:10;
}
.googlemap_stylingdiv {
	height: 10vh;
	background: linear-gradient(to bottom, var(--colortheme1), transparent);
	opacity: 0.5;
}
.googlemap_stylingdivBot {
	height: 8vh;
	bottom: 0;
	background: linear-gradient(to top, rgb(0, 0, 0), rgba(46, 46, 46, 0));
	opacity: 1;
}

.googlemap_location_overlapDiv {
	position: absolute;
	top:-1rem;
	left:-1rem;
	height: 5rem;
	width:12rem;
	background: rgba(0, 0, 0, 0);
	border-radius: 1rem;
}

.googlemap_newalarmdiv
{
	/*min-width: 5rem;*/
	margin-top: 0rem;
	text-align: left;
	/*background: linear-gradient(to left, rgb(0, 0, 0, 0.9), transparent);*/
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-self: center;
	width: 6rem;

	color: red;
	font-size: 1.5rem;	
	padding:1rem;
	/*padding-left: 2rem;*/

	cursor: pointer;

	transition: all var(--css-duration);
}

.googlemap_newalarmdiv:hover
{
	box-shadow: 0px -90px 0 0 var(--focuscardbackground) inset;
	color: white;
}

.googlemap_viewedalarmdiv
{
	/*width: 40%;*/
	text-align: right;
	/*background: linear-gradient(to left, rgb(26, 26, 26, 0.5), rgb(26, 26, 26, 0.5));*/

	color: greenyellow;
	font-size: 1.5rem;
	padding:1rem;
	padding-right: 2rem;

	cursor: pointer;

	/*top: 0rem;
	right: 0;*/
	display: flex;
	flex-direction: row;
	transition: all var(--css-duration);

	justify-content: space-between;
	align-self: center;
	width: 6.5rem;
}




.googlemap_marker_pointer {
    background: var(--focuscardbackground);
    width: 1rem;
    height: 1rem;
    border-radius: 999px;
}
.pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: var(--focuscardbackground);
    margin: -20px 0 0 -20px;
    
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
    opacity: .5;
}
.pin:hover {
	opacity: 1;
}
    
.pulse {
    background: rgba(0,0,0,0.2);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
}
.pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 2s ease-out;
    animation-iteration-count: infinite;
    box-shadow: 0 0 1px 2px rgba(255, 255, 255, .7);
    animation-delay: 1.1s;
}

@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0.0;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

@keyframes bounce {
    0% {
        opacity: 0;
        transform: translateY(-200px) rotate(-45deg);
    }
    60% {
        opacity: .5;
        transform: translateY(30px) rotate(-45deg);
    }
    80% {
        transform: translateY(-10px) rotate(-45deg);
    }
    100% {
        transform: translateY(0) rotate(-45deg);
    }
}
.GoogleMap_Marker {
    cursor: pointer;
}

.google_map_detail {
	position: absolute;
	z-index: 10;

    color: var(--cardtitletext);
    width: 30rem;
    height: 20rem;
    text-align: center;
    margin-top: 3rem;
    margin-left: 2rem;
    border-radius: .5rem;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: contain;
    background-position: center;
	transition: var(--css-duration);
	
	animation: .5s DetailOnload ease-in-out;
	filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.4));
	box-shadow: 0 15px 40px 0 rgb(255, 255, 255, 0.4) inset;
}
@keyframes DetailOnload {
    0% {
        opacity: 0;
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0);
    }
}
.google_map_title {
	background: linear-gradient(to bottom, rgba(255, 255, 255, .2), rgb(0, 0, 0, .65));
    backdrop-filter: blur(6px);
    /* border-top-right-radius: 1rem;
    border-top-left-radius: 1rem; */
	padding: 1rem .5rem .5rem;
	text-align: start;

	display: flex;
	flex-direction: row;
	justify-content: start;
	font-size: 1.5rem;
	letter-spacing: 2px;
}
.dettail_locationIcon {
	margin-left: 1.5rem;
	margin-right: 1rem;
	color: var(--focuscardbackground);
}
.google_map_btmRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 1.1rem;
}

.google_map_btn_Statistics {
	background: var(--colortheme1);
	padding: .5rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
    width: 15rem;
    border-bottom-left-radius: .5rem;
    cursor: pointer;
    opacity: .85;
    transition: var(--css-duration);
	letter-spacing: 2px;
}
.google_map_btn_Dashboard {
	background: var(--focuscardbackground);
	padding: .5rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
    width: 15rem;
    border-bottom-right-radius: .5rem;
    cursor: pointer;
    opacity: 0.85;
    transition: var(--css-duration);
    letter-spacing: 2px;
}
.google_map_btn_Statistics:hover {
    opacity: 1;
    box-shadow: 0 -70px 0 0 rgba(255, 255, 255, 0.1) inset;
}
.google_map_btn_Dashboard:hover {
    opacity: 1;
    box-shadow: 0 -70px 0 0 rgba(255, 255, 255, 0.1) inset;
}

.highlight {
	opacity: 1;
}
.pulsehighlight {
	background: rgba(0, 0, 0, 0.3);
}


/* Small size screen force resize css */
@media only screen and (max-height: 900px) {
	.google_map_detail {
		width: 20rem;
		height: calc(20rem * 2 /3);
	}
	.overlay_div1 {
		width: 20rem;
		height: 4rem;
	}
	.newMainBottomLogo .left {
		width: 100%;
		flex-grow: 1;
		font-size: 1.1rem;
	}
	.newMainBottomLogo .right {
		flex-grow: 0;
	}
	.google_map_title {
		font-size: 1rem;
	}
	.google_map_btn_Statistics,
	.google_map_btn_Dashboard {
		font-size: 1rem;
		padding: 0.5rem;
		height: 2rem;
	}
}

@media only screen and (max-height: 500px) {
	.googlemap_stylingdivBot {
		display: none;
	}
}