.ColdboxStatus {
    background: var(--cardbodybackground);
    color: var(--optiontext);
    box-shadow: 0 5px 20px 0 rgba(0,0,0,.2);
    height: 100%;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.ColdboxStatus_header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 2rem;
    font-size: 1rem;
    background: var(--cardtitle);
    color: var(--cardtitletext);
    padding: 0.5rem;
    padding-left: 1rem;
    --css_duration: var(--css-duration);
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;

    box-shadow: 0 15px 30px 0 rgba(0,0,0,.11), 0 5px 15px 0 rgba(0,0,0,.08);
}
.ColdboxStatus_innerscroll {
    height: 100%;
    /* overflow: auto; */
    padding: 1rem;

    display: flex;
    flex-direction: column;
    justify-self: flex-start;
    overflow: scroll;
    position: relative;
}

fieldset {
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */

    /* border-width: 5px;
    border: solid;
    border-image: conic-gradient(rgba(128, 128, 128, 0.5) 79.5%, transparent 0, transparent 84%, rgba(128, 128, 128, 0.5) 84%) 1; */
    border-radius: 1rem;
    border-width: 2px;
    border-color: rgba(128, 128, 128, 0.5);
    /* box-shadow: 0 0 0 1px rgba(128, 128, 128, 0.5); */

    /* padding: .8rem; */
    /* margin-top: .5rem; */
}

legend {
    /* position: absolute;
    margin-top: -1.5rem; */
    margin-left: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.2rem;
    color: white;
    /* background: var(--colortheme1); */
    /* background-color:  rgb(0, 0, 0); */
    opacity: .5;
    border-radius: .3rem;
    /* height: 1.5rem; */
}

.ColdboxStatus_Statusbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    /* border-width: 5px;
    border: solid;
    border-image: conic-gradient(rgba(128, 128, 128, 0.5) 79.5%, transparent 0, transparent 84%, rgba(128, 128, 128, 0.5) 84%) 1; */
    /* border-radius: 1rem;
    box-shadow: 0 0 0 2px rgba(128, 128, 128, 0.5); */

    padding: .8rem;
    /* margin-top: .5rem; */
}
.ColdboxStatus_BoxTitle {
    position: absolute;
    margin-top: -1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.2rem;
    color: white;
    /* background: var(--colortheme1); */
    background-color:  rgb(0, 0, 0);
    opacity: .5;
    border-radius: .3rem;
    /* height: 1.5rem; */
}
.ColdboxStatus_Statusbox .Statusbox_Col {
    width: calc(50% - 1rem);
    /* margin-top: .5rem; */

    display: flex;
    flex-direction: column;
}
.ColdboxStatus_Statusbox .Statusbox_Col .Statusbox_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ColdboxStatus_Statusbox .AlarmSetPointbox_Col {
    width: calc(100% - 1rem);
    /* margin-top: .5rem; */

    display: flex;
    flex-direction: column;
}
.ColdboxStatus_Statusbox .AlarmSetPointbox_Col .Statusbox_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
/* .ColdboxStatus_Statusbox .Statusbox_Col .Statusbox_row .Statusbox_txt {

}
.ColdboxStatus_Statusbox .Statusbox_Col .Statusbox_row .Statusbox_Value {

} */