.SettingRangeControl
{
  margin: 1rem;
  padding: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 1rem;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0);
}

.rangeControl
{
  -webkit-appearance: none;
  width: 20vw;
  height: 2px;
  background: #000000;
  outline: none;
}
.rangeControl::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1rem;
  height: 2rem;
  border-radius: 9999px;
  background: rgb(120, 255, 221);
  box-shadow: 0 0 5px 0px rgb(126, 126, 126);
  cursor: pointer;
  transition: all var(--css-duration);
}
.rangeControl::-webkit-slider-thumb:hover {
  background: rgb(120, 255, 221);
  box-shadow: 0 0 10px 5px rgb(120, 255, 221);
}

.SetSoundRangeContainer
{
    background: rgba(255, 255, 255, 0.8);
    margin-top: 0.5rem;
    width: 25vw;
    border-radius: 1rem;
}
.SetSoundRangeTItle
{
    margin: 0.5rem;
    display:flex;
    flex-direction: row;
}

.SetSoundVolumeReading
{
    margin-left: 1.5rem;
}