.operOver_sysStatus {
    width: 100%;
    padding: .5rem;
    padding-right: 1.5rem;
    margin-top: .2rem;
    margin-bottom: .2rem;
    border-radius: .5rem;
    background: rgba(0, 0, 0, 0.7);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.18), 0 5px 5px 0 rgba(0,0,0,.1);
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.operOver_sysStatusItem {
    min-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-right: 1rem;
    border-radius: 999px;
    letter-spacing: 1px;
}
.operOver_sysStatusItem.health {
    color: rgb(0, 255, 0);
}
.operOver_sysStatusItem.warn {
    color: rgb(255, 208, 0);
}
.operOver_sysStatusItem.error {
    color: red;
}