.AddWidgetList {
    position: absolute;
    top: 0;
    right: 2rem;
    width: 30rem;
    height: 80vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-radius: .8rem;

    color: var(--optiontext);
    backdrop-filter: blur(30px);

    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.2);

    animation: addWidgetOnload .5s ease-in-out;
    transition: all .5s;
}
@keyframes addWidgetOnload {
    0% {
        opacity: 0;
        transform: translateY(-4rem);
    }
    100% {
        opacity: 1;
    }
}

.AddWidget_head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;

    /* background: rgba(255, 255, 255, 0.5); */
    color: var(--optiontext);;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}
.AddWidget_title {
}
.Add_Widget_closeBtn {
    cursor: pointer;
}

.AddWidget_WidgetList {
    height: 100%;

    overflow: auto;

    display: flex;
    flex-direction: column;
}
.AddWidget_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background: rgba(0, 0, 0, .2);
    color: white;

    font-size: 1.3rem;

    transition: all var(--css-duration);

    cursor: pointer;
}
.AddWidget_row_selected {
    /* background: var(--focuscardbackground); */
    box-shadow: 0 -80px 0 0 var(--focuscardbackground) inset, 0 0 10px 0 rgb(0, 0, 0, .4);
}
.AddWidget_widgetname {
   letter-spacing: 1px;
   font-weight: 100;
   width: 100%;
   padding: .3rem;
}
.AddWidget_row_addBtn {
    transition: all var(--css-duration);
    width: 3rem;
    text-align: center;
    opacity: .5;

    align-self: center;
}
.AddWidget_row_addBtn:hover {
   opacity: 1;
}

.AddWidget_Detail {
    /* background: rgba(0, 0, 0, .1); */
    padding: 1rem;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
}
.AddWidget_DetailRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-bottom: .5rem;
}
.AddWidget_DetailTitle {
    font-size: 1.3rem;
    letter-spacing: 1px;
}
.AddWidget_DetailPar {
    font-size: 1rem;
}