.getcoor.main {
    width: 100%;
    height: 100%;
    background-color: grey;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.getcoor .inputs {
    position: absolute;
    top: 2rem;
    display: flex;
    gap: 2rem;
}
.getcoor .inputs input {
    padding: 10px;
    font-size: 14px;

    background-color: rgba(0, 0, 0, 0.3);
    color: white;
}

.getcoor .cover {
    background-color: black;
    position: absolute;
}
.getcoor .cover .ptcontainer {
    position: absolute;
    top: 0;
    left: 0;
}
.getcoor .cover .ptcontainer .point {
    background: red;
    border-radius: 99px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3) inset;
}