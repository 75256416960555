.Lift_IAQ_main {
    /* background: rgba(255, 255, 255, .3); */
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2); */
    /* background: linear-gradient(90deg,hsla(0,0%,100%,.329),hsla(0,0%,100%,.425)); */
    background: var(--cardbodybackground);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
}
/* Lift IAQ Status Start */
.Lift_graphIAQContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-self: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.Lift_graphIAQReadingBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 3.5rem;

    height: 5rem;
}
.Lift_graphIAQReadingBoxIcon {
    margin: auto;
}
.Lift_graphIAQReadingBoxValue {
    margin-left: 0.5rem;
}
.Lift_graphIAQReadingBoxunit {
    font-size: 1.5rem;
    margin-left: 0rem;
    line-height: 3rem;
    align-self: start;
}
.IAQReadingBoxHumid {
    color: #00abff;
}
.IAQReadingBoxTemp {
    color: rgb(230, 0, 0);
}
/* Lift IAQ Status End */


/* small screen size: */
@media only screen and (max-height: 790px) {
    .IAQReadingBoxHumid {
        font-size: 2rem;
    }
    .IAQReadingBoxTemp {
        font-size: 2rem;
    }
}