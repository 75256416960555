.IEQV4 {
    width: 100%;
    height: 100%;
    box-shadow: 0 15px 10px 0 rgba(0, 0, 0, 0.05), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    background: var(--cardbodybackground);
    border-radius: 0.3rem;
    animation: floor4readingcardsOnload 1s ease-out;
    display: flex;
    flex-direction: column;

    .msg {
        width: 100%;
        height: 100%;
        font-size: 1.5rem;
        letter-spacing: 1px;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    .head {
        width: 100%;
        padding: 0.5rem;
        padding-left: 1rem;
        font-size: 1rem;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        letter-spacing: 0.5px;
        color: var(--cardtitletext);
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.174)); 
        background: var(--cardtitle);
    }
    .list {
        font-size: 21px;
        width: 100%;
        height: calc(100% - 1rem);
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        overflow-y: auto;
        background: transparent;

        .row {
            display: flex;
            justify-content: space-between;
            width: calc(33% - 1rem);
            margin: 0.5rem;
            margin-top: 0;
            margin-bottom: 0;
            background: transparent;
            .title {
                letter-spacing: 1px;
                font-weight: bold;
                opacity: 0.8;
            }
            .value,
            .unit {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-wrap: wrap;
                .displays {
                    margin-left: 5px;
                }
            }
            .unit {
                width: 53px;
                justify-content: flex-start;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .IEQV4 {
        .list {
            font-size: 15px;
            .row {
                .unit {
                    width: 25px;
                }
            }
        }
    }
}