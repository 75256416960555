.OccV2 {
    width: 100%;
    height: 100%;
    box-shadow: 0 15px 10px 0 rgb(0 0 0 / 5%), 0 5px 5px 0 rgb(0 0 0 / 10%);
    background: var(--cardbodybackground);
    border-radius: 0.3rem;
    animation: floor4readingcardsOnload 1s ease-out;
}
.OccV2 .head {
    height: 2rem;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    color: var(--cardtitletext);
    background: var(--cardtitle);
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.174));

    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.OccV2 .head .icon {
    margin-left: 1rem;
}
.OccV2 .head .txt {
    letter-spacing: 1px;
}

.OccV2 .inner {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
}
.OccV2 .inner .row {
    height: calc(50% - 2rem);
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.OccV2 .inner .row .icon {
    color: white;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
    width: 4rem;
    height: 3.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.OccV2 .inner .row .icon.male {
    background: rgb(81, 81, 255);
}
.OccV2 .inner .row .icon.female {
    background: red;
}
.OccV2 .inner .row .txt {
    font-size: 3.5rem;
    font-weight: 100;
    opacity: 0.7;
}