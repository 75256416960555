.Toilet_admin_schedule {
    color: white;
    width: 100%;
    height: 100%;
    padding: 1rem;
    border-radius: 0.5rem;

    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.4);

    display: flex;
    flex-direction: column;

}

.Toilet_admin_schedule .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-size: 1rem;
    letter-spacing: -1px;
    margin-bottom: 0.5rem;
}
.Toilet_admin_schedule .headline {
    height: 1px;
    width: 100%;
    background: white;
}
.Toilet_admin_schedule .table {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    overflow-y: auto;
}

.Toilet_admin_sch_col2,
.Toilet_admin_sch_col3 {
    width: calc((100% -9rem)/2);
    text-align: center;
}
.Toilet_admin_sch_col1 {
    min-width: 9rem;
}

.Toilet_admin_sch_row {
    display: flex;
    align-items: center;
    padding: 0.2rem;
}

.Toilet_admin_addbtn {
    font-size: 1rem;
    width: 3rem;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border-radius: 0.2rem;

    cursor: pointer;
}

.table .Toilet_admin_addbtn {
    font-size: 1rem;
    width: 2rem;
}