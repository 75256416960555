.WeekPickerClass .react-datepicker-wrapper .react-datepicker__input-container input {
    color: white;
}
.WeekPickerClass .react-datepicker {
    z-index: 100;
    
}

.LiftSummary_weekinput {

}