.showsitepopup
{
    /* opacity: 1; */
    animation: .5s popupup linear;
}
@keyframes popupup {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.PickSiteWidget_div
{
    position:fixed;
    z-index: 100;

    background: rgba(255, 255, 255, 0.2);
    padding:0rem;
    transition: all var(--css-duration);
    border-radius: 0.3rem;
}

.PickSiteWidget_div *
{
    margin-right: 0.5rem;
}

.PickSiteWidget_closebtn
{
    color: black;
    transition: all var(--css-duration);

    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    cursor: pointer;

    display: none;
}

.PickSiteWidget_closebtn:hover
{
    
}


.hidesitepopup
{
    position:absolute;
    top: -30vh;
    left: 0;
    display:none;

    z-index: -10;
}

.PickSiteWidget_ListContainer
{
    /*margin: 1rem;
    margin-top: 2rem;*/

    position: fixed;
    top: 0;
    left: 0rem;
    z-index: 100;
    background: rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
}

.PickSiteWIdget_item
{
    /* background: rgba(255, 255, 255, 0.356); */
    background: var(--divheader);
    color: white;
    /* color: var(--optiontext); */
    /* color: black; */
    padding: 0.5rem;
    margin-top: .3rem;
    margin-left: 0.5rem;
    cursor: pointer;
    transition: all var(--css-duration);
    border-radius: 0.5rem;
    opacity: 0.8;

    width: 10vw;
    text-align: center;
    z-index: 9999;
    /* backdrop-filter: blur(20px); */
    animation: siteitemonload .5s ease-out;
}
@keyframes siteitemonload {
    0% {
        opacity: 0;
        transform: translateY(-10px);
         /* scale(.7); */
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.PickSiteWIdget_item:hover
{
    box-shadow: 0px -40px 0 0 var(--focuscardbackground) inset;
    color: white;
}

.highlightSiteItem
{
    /*background: var(--focuscardbackground);*/
    /* opacity: 1; */
}

.PickSiteWidget_BgDiv
{
    position: fixed;
    width: 100%;
    height: 100%;
    /* background: rgba(255, 255, 255, .2); */
    background: var(--divheader);
    border-radius: .3rem;
    /* transition: all var(--css-duration); */
    transition: .3s;
    left: 0;
    top: 0;
    /* backdrop-filter: blur(20px); */
}