.LiftSummary_header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: var(--cardtitle);
    color: white;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.3rem;
    --css_duration: var(--css-duration);
    box-shadow: 0 15px 10px 0 rgba(0, 0, 0, 0.10), 0 5px 5px 0 rgba(0, 0, 0, 0.2);
}
.LiftSummary_title {
    margin-left: 1rem;
}