.NTUSummary {
    width: 100%;
    height: 100%;
    box-shadow: 0 15px 10px 0 rgb(0 0 0 / 5%), 0 5px 5px 0 rgb(0 0 0 / 10%);
    background: var(--cardbodybackground);
    color: var(--optiontext);
    border-radius: 0.3rem;
    animation: floor4readingcardsOnload 1s ease-out;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .head {
        width: 100%;
        padding: 0.5rem;
        padding-left: 1rem;
        font-size: 1rem;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        letter-spacing: 0.5px;
        color: var(--cardtitletext);
        background: var(--cardtitle);
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.174));

        display: flex;
        align-items: center;
        gap: 1rem;

        .guide {
            cursor: pointer;
        }
    }
    .inner {
        height: 100%;
        display: flex;
        justify-content: space-between;

        .setupBtn {
            margin: auto;
            padding: 10px;
            font-size: 2rem;
            border-radius: 10px;
            background: orange;
            box-shadow: 10px 10px 25px 0 rgba(0, 0, 0, 0.2);
            cursor: pointer;

            .sub {
                font-size: 1rem;
            }
        }

        .left {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            .name {
                color: rgba(0, 127, 247, 1);
                filter: drop-shadow(0 7px 4px rgba(0, 0, 0, 0.3));
                font-weight: 800;
                font-size: 3.5rem;
                letter-spacing: 5px;
            }
            .reading {
                font-size: 3.5rem;
                font-weight: 800;
                opacity: 0.7;
                color: var(--optiontext);
            }
        }
        .right {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5px;
            .name {
                font-size: 1.5rem;
                opacity: 0.7;
                color: var(--optiontext);
            }
            input {
                font-size: 3rem;
                text-align: center;
                width: 80%;
                box-shadow: 0 3px 10px 5px rgba(0, 0, 0, 0.15) inset;
                color: rgba(0, 0, 0, 0.7);
                border-radius: 10px;
            }
            .button {
                background: rgb(212, 191, 0);
                box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
                letter-spacing: 2px;
                cursor: pointer;
                transition: all 0.5s;
                &:active {
                    transform: scale(0.9);
                }
            }
        }
    }
}