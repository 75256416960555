@font-face {
	font-family: 'RobotoSlab';
	font-weight: normal;
	src: url('./font/RobotoSlab-Medium.ttf') format('truetype');
}
@font-face {
	font-family: 'RobotoSlab';
	font-weight: bold;
	src: url('./font/RobotoSlab-Black.ttf') format('truetype');
}
.centralBody {
    font-family: 'RobotoSlab';
	color: #000;
	width: 1280px;
	height: 800px;
	margin: auto;
	margin-top: 0;
	-webkit-text-size-adjust: none;
}

.cm-pv-grey {
	color: #999 !important;
}
.cm-pv-head {
	position: relative;
	height: 224px;
}
.cm-pv-head > * {
	position: absolute;
}
.cm-pv-head-logo {
	z-index: 30;
	height: 224px;
}
.cm-pv-head-banner {
	z-index: 10;
	height: 224px;
}
.cm-pv-head-time {
	z-index: 20;
	right: 10px;
	font-size: 70px;
	color: #fff;
	text-align: right;
	text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
	line-height: 80px;
}
.cm-pv-head-time > span {
	display: block;
}
.cm-pv-head-time > span:last-child {
	font-size: 95px;
}
.cm-pv-body {
	overflow: hidden;
}
.cm-pv-body > div {
	float: left;
	overflow: hidden;
	width: 432px;
}
.cm-pv-body > div > * {
	float: left;
	height: 576px;
	text-align: center;
}
.cm-pv-body > div > div {
	width: 144px;
	background-image: url(./image/bg.png);
	background-size: 144px;
}
.cm-pv-body > div > dl {
	width: 288px;
	border: 1px solid #ccc;
}
.cm-pv-body > div > dl > * {
	overflow: hidden;
}
.cm-pv-body > div > dl > * > div {
	float: left;
	width: 143px;
	height: 168px;
	line-height: 168px;
	font-size: 68px;
	border: 1px solid #ccc;
}
.cm-pv-body > div > dl > dt > div {
	height: 48px;
	font-size: 18px;
	line-height: 22px;
}
.cm-pv-body > div > dl > dt > div:nth-child(2) {
	color: #fff;
}
.cm-pv-body > div > dl > dd > div > span {
	font-size: 38px;
}
.cm-pv-body > div > dl > dd:last-child > div {
	height: 190px;
	line-height: 190px;
}
.cm-pv-body-male > div > img {
	width: 55px;
	margin-top: 172px;
}
.cm-pv-body-male > div,
.cm-pv-body-male > dl > dt > div:nth-child(2) {
	background-color: #48a9d5;
}
.cm-pv-body-male > dl > dd > div:nth-child(2) {
	color: #48a9d5;
}
.cm-pv-body-female > div > img {
	width: 90px;
	margin-top: 172px;
}
.cm-pv-body-female > div,
.cm-pv-body-female > dl > dt > div:nth-child(2) {
	background-color: #c8215d;
}
.cm-pv-body-female > dl > dd > div:nth-child(2) {
	color: #c8215d;
}
.cm-pv-body-ieq {
	width: 416px !important;
}
.cm-pv-body-ieq > div > img {
	width: 50px;
	margin-top: 152px;
}
.cm-pv-body-ieq > div,
.cm-pv-body-ieq > dl > dt > div:nth-child(2) {
	background-color: #3e7f4b;
}
.cm-pv-body-ieq > dl > dd > div:nth-child(2) {
	color: #3e7f4b;
}
.cm-pv-body-ieq > dl {
	width: 272px !important;
}
.cm-pv-body-ieq > dl > dd > div {
	width: 135px !important;
	height: 144px !important;
	line-height: 144px !important;
	font-size: 40px !important;
}
.cm-pv-body-ieq > dl > dd:last-child > div {
	height: 142px !important;
	line-height: 142px !important;
}
.cm-pv-body-ieq > dl > dd > div:nth-child(1) {
	font-weight: bold;
}
.cm-pv-body-ieq > dl > dd > div > img {
	width: 35px;
	margin-top: 40px;
}
.cm-pv-body-ieq > dl > dd > div > span {
	font-size: 22px !important;
}