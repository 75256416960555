 .OEQ {
    width: 100%;
    height: 100%;
    box-shadow: 0 15px 10px 0 rgb(0 0 0 / 5%), 0 5px 5px 0 rgb(0 0 0 / 10%);
    background: var(--cardbodybackground);
    color: var(--optiontext);
    border-radius: 0.3rem;
    animation: floor4readingcardsOnload 1s ease-out;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .head {
        width: 100%;
        padding: 0.5rem;
        padding-left: 1rem;
        font-size: 1rem;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        letter-spacing: 0.5px;
        color: var(--cardtitletext);
        background: var(--cardtitle);
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.174));
    }
    .msg {
        margin: auto;
        font-size: 1.5rem;
        letter-spacing: 2px;
        opacity: 0.8;
    }

    .row {
        font-size: 22px;
        padding: 0.5rem;
        width: 100%;
        opacity: 0.8;

        display: flex;
        justify-content: space-between;

        .title {
            letter-spacing: 1px;            
            font-weight: bold;
            opacity: 0.8;
        }
        .value {
            display: flex;
            justify-content: center;

            .unit {
                width: 52px;
            }
            .direction {
                margin-left: 5px;
                justify-content: flex-start;
                width: 100%;
            }
        }
    }
 }

 @media screen and (max-width: 1300px) {
    .OEQ {
        .row {
            font-size: 15px;
            padding: 5px;
        }
    }
}