.coldbox_Card {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: .5rem;
    color: var(--optiontext);
}

.coldbox_Card .inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.coldbox_Card .inner .lvl {
    font-size: 12px;
    opacity: .7;
}

.coldbox_Card .inner .status {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    color: #000;
    font-size: 1.5rem;
}
.coldbox_Card .inner .status .time {
    display: flex;
    align-items: flex-end;
}
.coldbox_Card .inner .status .time .value {
    font-size: 12px;
}
.coldbox_Card .inner .status .time .unit {
    font-size: 10px;
}
.coldbox_Card .inner .status .statusTxt {
    font-size: 12px;
}

.coldbox_Card .inner .temp {
    display: flex;
    gap: 10px;
}

.coldbox_Card .inner .temp .left,
.coldbox_Card .inner .temp .right {
    text-align: center;
    display: flex;
    flex-direction: column;
}
.coldbox_Card .inner .temp .left .top,
.coldbox_Card .inner .temp .right .top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 14px;
    color: red;
}
.coldbox_Card .inner .temp .left .top .value,
.coldbox_Card .inner .temp .right .top .value {
    display: flex;
}
.coldbox_Card .inner .temp .left .top .value .unit,
.coldbox_Card .inner .temp .right .top .value .unit {
    display: flex;
    font-size: 5px;
}
.coldbox_Card .inner .temp .left .top .value .unit .unit2,
.coldbox_Card .inner .temp .right .top .value .unit .unit2 {
    font-size: 5px;
}

.coldbox_Card .inner .temp .left .bottom,
.coldbox_Card .inner .temp .right .bottom {
    font-size: 8px;
}

.coldbox_Card .inner .humid {
    display: flex;
    font-size: 14px;
    color: #00d0ff;
}
.coldbox_Card .inner .humid .value {
    display: flex;
}
.coldbox_Card .inner .humid .value .unit {
    margin-right: 1rem;
    font-size: 8px;
}

.coldbox_Card .inner .battery,
.coldbox_Card .inner .updateTime {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 10px;
    margin-top: 0;
}