.Table_bottomdiv
{
    text-align: center;

    padding: 0.5rem;
    width: auto;

    margin-top: 2rem;

    color: var(--optiontext);
}

.Table_pagecontrol {
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.Table_maindiv
{
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    width: 100%;
    min-height: 75vh;
    background: linear-gradient(to bottom right, rgb(100,100,100,0), rgb(100,100,100,0));
    border-radius: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Table_tablediv
{
    width: 100%;
    border-radius: 0.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.Table_tableheader
{
    color: white;
}

.Table_tableheader td
{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    /*background: var(--colortheme1);*/
    background: rgba(128, 128, 128, 0.3);
    color: var(--optiontext);
}

.Table_tableheader .headerfirstitem
{
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius:  0.3rem;

    width: 7rem;
    padding-left: 0.5rem;
}
.Table_tableheader .headerlastitem
{
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius:  0.3rem;
}

.Table_rowItem {
    border-top-width: 0.5px;
    border-top-color: rgba(0, 0, 0, 0.1);

    color: black;
    color: var(--optiontext);

    height: 3.5rem;

    cursor: pointer;
    transition: all 0.5s;
    transition-timing-function: ease-out
}
.Table_rowItem:hover {
    box-shadow: 0px -70px 0 0 rgba(0, 0, 0, 0.1) inset;
}

@keyframes HoverListEffect {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  

.Table_rowItem td
{
    background: var(--cardbodybackground);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.Notification_firstpageBtn {
    padding:0.2rem;
    padding-left:  0.5rem;
    padding-right:  0.5rem;
    height: 2rem;
    border-radius: 0.5rem;
    border-width: 0px;
    border-color: var(--optiontext);
    color: var(--optiontext);

    cursor: pointer;
    transition: all var(--css-duration);
    margin-right: 1rem;

    align-self: center;
    display: flex;
    align-items: center;
}

.Notification_lastpageBtn
{
    padding:0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 2rem;
    width: 2rem;
    border-radius: 0.5rem;
    border-width: 0px;
    border-color: var(--optiontext);
    color: var(--optiontext);

    cursor: pointer;
    transition: all var(--css-duration);

    margin-left:1rem;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Notification_firstpageBtn:hover
{
    background: var(--colortheme1);
    border-color: var(--colortheme1);
    color: white;
}

.Notification_lastpageBtn:hover
{
    background: var(--colortheme1);
    border-color: var(--colortheme1);
    color: white;
}

.Notification_BackBtn
{
    cursor: pointer;
    transition: all var(--css-duration);
    padding:0.3rem;
    margin-right: 0.8rem;
}

.Notification_BackBtn:hover
{
    background: var(--colortheme1);
    border-radius: 0.3rem;
    color: white;
}

.Notification_ForwardBtn
{
    cursor: pointer;
    transition:  all var(--css-duration);
    padding:0.3rem;
    margin-left: 0.8rem;
}

.Notification_ForwardBtn:hover
{
    background: var(--colortheme1);
    border-radius: 0.3rem;
    color: white;
}
.TablePageBtn
{
    padding:0.3rem;
    padding-left:0.6rem;
    padding-right: 0.6rem;
    border-radius: 0.3rem;
    outline: 0;

    transition: all var(--css-duration);

    color: var(--optiontext);
}

.TablePageBtn:focus
{
    outline: 0;
}

.currentpage {
    background: var(--focuscardbackground);
    color: white;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, .3) inset;
}

.Table_pagenumber
{
    display:flex;
    flex-direction: row;
    justify-content: center;
    width: 13rem;
    overflow-x: auto;
}

.Not_DisabledAlarm {
    width: 35%;
    margin-left: 10%;
    background: linear-gradient(to bottom, rgb(143, 143, 143), rgb(187, 187, 187));
    color: rgba(56, 56, 56, 0.3);
    border-radius: 9999px;
    padding: 0.2rem;

    cursor: auto;
    outline: 0;
    transition: all var(--css-duration);
}

.Not_DisabledAlarm:focus
{
    outline: 0;
}

.Not_AbledAlarm
{
    width: 35%;
    margin-left: 10%;
    background: linear-gradient(to bottom, rgb(7, 228, 7), rgb(0, 145, 0));
    color: rgba(255, 255, 255, 0.8);
    border-radius: 9999px;
    padding: 0.2rem;

    transition: all var(--css-duration);
}

.Not_AbledAlarm:focus
{
    outline: 0;
}

.Not_AbledAlarm:hover
{
    background: linear-gradient(to bottom, rgb(39, 255, 39), rgb(16, 143, 16));
}

.Not_NewAlarm
{
    width: 5rem;
    margin-left: 10%;
    background: linear-gradient(to bottom, rgb(255, 41, 41, 0.9), rgb(167, 0, 0, 0.6));
    color: rgba(255, 255, 255, 0.8);
    border-radius: 9999px;
    padding: 0.2rem;

    transition: all var(--css-duration);
}

.Not_NewAlarm:hover
{
    background: linear-gradient(to bottom, rgb(255, 31, 31), rgb(219, 0, 0));
}

.Not_NewAlarm:focus
{
    outline: 0;
}

.Undefined_NewAlarm
{
    width: 5rem;
    margin-left: 10%;
    background: linear-gradient(to bottom, rgb(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2));
    color: rgba(255, 255, 255, 0.8);
    border-radius: 9999px;
    padding: 0.2rem;

    transition: all var(--css-duration);
}

*:focus
{
    outline:0;
}

.Not_icon {
    border-right-width: 0px;
    border-left-width: 0px;
    border-color: black;
    width: 2rem;
    text-align: center;
}

/* popup alarm detail window */
.popupalarm_backgroundDiv {
    position: fixed;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    min-width: 1024px;
    min-height: 768px;
    background: rgba(0, 0, 0, 0.2);

    z-index: 10;

    transition: all var(--css-duration);
}

.popupalarm_maindiv {
    position: fixed;
    top: calc((100vh - 45rem)/2);
    left: calc((100vw - 40rem)/2);
    width: 40rem;
    text-align: center;
    z-index: 11;
    background: rgba(0, 0, 0, .2);
    backdrop-filter: blur(20px);
    color: black;
    padding: 1rem;
    padding-bottom: 3rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    border-radius: 1rem;

    animation: .5s ease-out PopupAppear;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .3);
}
@keyframes PopupAppear {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
  }

.popupalarm_titleContainer{
    /* background: rgba(0, 0, 0, 0.3); */
    border-radius: 0.5rem;
    margin-top: 2rem;
    color: white;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
.popupalarm_titleSub {
    font-size: 1.2rem;
    text-align: left;
}

.popupalarm_closeBtn
{
    background: transparent;
    color: white;
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    padding: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.3rem;
    cursor: pointer;

    transition: all var(--css-duration);
}

.popupalarm_closeBtn:hover {
    transform: rotate(90deg);
}

.popupalarm_iconsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .1) inset;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    padding: 0.5rem;
    color: white;
}
.popupAlarm_iconItemTitle {
    text-align: center;
    margin-bottom: 0.5rem;
}
.popupAlarm_iconItemIcon {
    color: aquamarine;
    font-size: 1.3rem;
}

.popupalarm_detailContainer{
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    min-height: 15rem;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    color: white;
    background: rgb(0, 0, 0, 0.2);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .1) inset;
}
.popupalarm_detailRowItem {
    text-align: start;
    padding: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    width: 50%;
}
.popupalarm_detailTitle {
    font-size: 1rem;
    border-bottom-width: 1px;
    border-bottom-color: white;
    width: 10rem;
    margin-bottom: 0.5rem;
}

.popupalarm_icon
{
    margin-top: 6rem;
    font-size: 2rem;

    width: 100%;
}

.popupalarm_title
{
    font-size: 2rem;
    text-align: start;
    font-weight: 100;
}

.popupalarm_detailtxt
{
    font-size: 1.2rem;
    background: rgba(128, 128, 128, 0.493);
    border-radius: 9999px;
    padding: 0.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.popupalarm_ackIcon
{
    margin-top: 1.5rem;   
    font-size: 1.5rem;
}

.popupalarm_ackuser
{
    border-radius: 9999px;
    background: rgba(128, 128, 128, 0.4);
    width: 60%;
    padding: 0.5rem;
    margin:auto;
    margin-top: 0.5rem;
    font-size: 1.5rem;
}

.popupalarm_ackBtn {
    font-size: 1.3rem;
    letter-spacing: 2px;
    color: white;
    margin-top: 2rem;
    padding: 0.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background: grey;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;

    cursor: pointer;
    transition: all var(--css-duration);

    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, .3);
    text-align: center;
}
.popupalarm_ackBtn .fa
{
    margin-right: 0.5rem;
}
.popupalarm_ackBtn:hover {
    opacity: 1;
}
.popupalarm_ackBtn:active
{
    opacity: 1;
    box-shadow: 0 0px 100px 0px rgb(175, 175, 175) inset;
}

.popupalarm_acktimeTitle
{
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 0.8rem;
}

.showalarmpopup {
    opacity: 1;
}

.hidealarmpopup {
    opacity: 0;
    display: none;
}

.popupalarm_settledBtn
{
    font-size: 1.3rem;
    color: black;
    margin-top: 2rem;
    padding: 0.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1));
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.5s;
    opacity: 0.7;

    margin-left: 0.5rem;

    display:none;
}
.popupalarm_settledBtn:hover
{
    opacity: 1;
    box-shadow: 0 0px 8px 0px black;
}
.popupalarm_settledBtn:active
{
    box-shadow: 0 0px 0px 0px black;
}

.Table_tableNoRecord {
    width: 100%;
    text-align: center;
    letter-spacing: 4px;
    font-size: 1.3rem;
    font-weight: 100;
    color: rgba(0, 0, 0, 0.7);
}

.Table_loading {
    width: 100%;
    height: calc(100vh - 18rem);
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: var(--focuscardbackground);

    animation-duration: 15s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: rgba(246, 246, 246, 0);
    /* background: linear-gradient(to right, rgba(255, 255, 255, 0.2) 8%, rgb(255, 255, 255) 24%, rgba(255, 255, 255, 0.2) 40%); */
    background: linear-gradient(to right, rgba(255, 255, 255, .1) 8%, rgb(255, 255, 255, .3) 24%, rgba(255, 255, 255, .1) 40%);
    /* background-size: 800px 104px; */
    background-size: 160rem 104px;
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -4000px 0
    }
    100%{
        background-position: 4000px 0
    }
}

.popupalarm_redirectBtn {
    background: var(--focuscardbackground);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .3);
    padding: 5px;
    text-align: center;

    border-radius: 5px;
    cursor: pointer;
}

.popupAlarm_1
{
    background: linear-gradient(to right, rgb(255, 0, 0), rgb(167, 0, 0));
}
.popupAlarm_2
{
    background: linear-gradient(to right, rgb(0, 255, 34), rgba(14, 172, 0, 1));
}


.SeverityLow {
    color: aquamarine;
}
.SeverityNormal {
    color: orange;
}
.SeverityHigh {
    color: red;
}
.StatusNew {
    color: red;
}
.StatusClear {
    color: greenyellow;
}
.loadingColor {
    background: grey;
    color: transparent;
}

/* Small size screen force resize css */
@media only screen and (max-height: 790px) {
    .Notification_table {
        overflow-y: scroll;
        /* max-height: 65vh; */
    }
    .Notification_maindiv {
        margin-bottom: 0;
        padding-bottom: 0;
        height: 88vh;
    }
}

@media only screen and (max-height: 46rem) {
    .popupalarm_maindiv {
        top: 0;
        
        padding: 10px;
    }
    .popupalarm_titleContainer {
        padding: 0;
    }
    .popupalarm_ackBtn {
        margin-top: 0;
    }
}

.deviceItem {
    color: darkcyan;
}
.systemItem {
    color: firebrick;
}
.equipItem {
    color: mediumslateblue;
}
.otherItem {
    color: grey;
}