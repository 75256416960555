.LuxSummaryCardContainer {
    width: 100%;
    height: 100%;
    background-color: var(--cardbodybackground);
    border-radius: 0.3rem;
    box-shadow: 0 15px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.LuxSummaryCardHead {
    width: 100%;
    font-size: 1rem;
    background: var(--cardtitle);
    color: var(--cardtitletext);
    padding: 0.5rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;

    display: flex;
    flex-direction: row;
}
.LuxSummaryIcon {
    font-size: 1rem;
    margin-left: 0.3rem;
}
.LuxSummaryTitle {
    margin-left: 0.5rem;
    letter-spacing: 1px;
}
.LuxSummaryBox {
    height: calc(100% - 2rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.LuxDisplayTitle {
    font-size: 1.5rem;
    text-align: center;
    letter-spacing: 1.5px;
}
.LuxDisplayResult {
    background-color: rgb(161, 161, 161);
    font-size: 1.5rem;
    text-align: center;
    margin: 1rem;
    padding: 0.5rem;
    border-radius: 0.3rem;
    color: white;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.4);
}
.OnResult {
    background: rgb(0, 190, 0);
    color: white;
}