.ColdboxIndividual {
    width: calc(100% - 4rem);
    height: calc(100% - 5rem);
    transition: all var(--css-duration);
    padding: 1rem;
    border-radius: .3rem;
    margin: auto auto 0;
    /* background: linear-gradient(180deg,var(--maindiv-color2),var(--maindiv-color));
    -webkit-filter: drop-shadow(0 10px 15px rgba(0,0,0,.3));
    filter: drop-shadow(0 10px 15px rgba(0,0,0,.3)); */
    -webkit-animation: draglayoutOnload 0.5s ease-out;
    animation: draglayoutOnload 0.5s ease-out;
    display: flex;
    flex-direction: column;
}

.ColdboxIndi_table {
    height: 100%;
    background: var(--cardbodybackground);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.224);
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: .5rem;
}

.ColdboxIndi_bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


/* Popup */
.Coldboxindi_BG {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;

    background: rgba(0,0,0,.224);

    -webkit-animation: GDBG_PopupAppear .6s ease-out 0s 1;
    animation: GDBG_PopupAppear .6s ease-out 0s 1;
}
.ColdboxIndi_popupMain {
    position: fixed;
    width: 50rem;
    height: 30rem;
    left: calc((100vw - 50rem)/2);
    top: calc((100vh - 30rem)/2);
    border-radius: .5rem;
    animation: GD_PopupAppear .6s ease-out 0s 1;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    color: #fff;
    padding: 1rem;
    z-index: 1000;
}
.ColdboxIndi_popupTitle {
    font-size: 1.5rem;
    letter-spacing: 2px;
}
.ColdboxIndi_popupcloseBtn {
    position: absolute;
    right: .7rem;
    top: .7rem;
    cursor: pointer;
}
.ColdboxIndi_popupRow {
    display: flex;
}
.ColdboxIndi_popupInput {
    width: 18rem;
    margin-bottom: .5rem;
    font-size: 1.2rem;
    padding: .5rem .5rem .5rem 1rem;
    margin-right: 1rem;
    border-radius: .5rem;
    transition: all var(--css-duration);
    background: transparent;
    border-width: .5px;
    border-color: rgba(0,0,0,.4);
    background: linear-gradient(90deg,rgba(0,0,0,.1),rgba(0,0,0,.2),rgba(0,0,0,.1));
    color: #fff;
}
.ColdboxIndi_popupInput:focus {
    background: rgba(0,0,0,.6);
}
.ColdboxIndi_popupselect {
    width: 18rem;
    height: 3rem;
    margin-bottom: .5rem;
    font-size: 1.2rem;
    padding: .5rem .5rem .5rem 1rem;
    margin-right: 1rem;
    border-radius: .5rem;
    transition: all var(--css-duration);
    background: transparent;
    border-width: .5px;
    border-color: rgba(0,0,0,.4);
    background: rgba(0, 0, 0, 0.224);
    color: #fff;
}
.ColdboxIndi_popupBottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: flex-end;

    min-width: 22rem;
}
.ColdboxIndi_popupSubmit {
    width: 10rem;
    height: 2.3rem;
    margin-left: 1rem;
    border-radius: .5rem;
    border-width: 1px;
    border-color: hsla(0,0%,100%,.3);
    text-align: center;
    background: var(--colortheme1);
    color: #fff;
    font-size: 1.2rem;
    padding-top: .4rem;
    cursor: pointer;
    transition: all var(--css-duration);
}
.ColdboxIndi_popupSubmit:hover {
    box-shadow: 0 3px 3px 0 rgba(0,0,0,.2), 0 -50px 0 0 var(--focuscardbackground) inset;
}
.ColdboxIndi_popupCancel {
    width: 10rem;
    height: 2.3rem;
    margin-left: 1rem;
    border-radius: .5rem;
    text-align: center;
    background: transparent;
    border-width: .5px;
    border-color: hsla(0,0%,100%,.4);
    color: #fff;
    font-size: 1.2rem;
    padding-top: .4rem;
    cursor: pointer;
    transition: all var(--css-duration);
    margin-left: .5rem;
}
.ColdboxIndi_popupCancel:hover {
    box-shadow: 0 3px 3px 0 rgba(0,0,0,.2), 0 -50px 0 0 var(--focuscardbackground) inset;
}
.ColdboxIndi_popupDel {
    width: 10rem;
    height: 2.3rem;
    margin-left: 1rem;
    border-radius: .5rem;
    border-width: 1px;
    border-color: hsla(0,0%,100%,.3);
    text-align: center;
    background: linear-gradient(90deg,rgba(255,0,0,.3),rgba(255,0,0,.3));
    color: #fff;
    font-size: 1.2rem;
    padding-top: .4rem;
    cursor: pointer;
    transition: all var(--css-duration);
}
.ColdboxIndi_popupDel:hover {
    box-shadow: 0 3px 3px 0 rgba(0,0,0,.2), 0 -50px 0 0 var(--focuscardbackground) inset;
}