.PeopleCountSum {
    width: 100%;
    height: 100%;
    box-shadow: 0 15px 10px 0 rgb(0 0 0 / 5%), 0 5px 5px 0 rgb(0 0 0 / 10%);
    background: var(--cardbodybackground);
    color: var(--optiontext);
    border-radius: 0.3rem;
    animation: floor4readingcardsOnload 1s ease-out;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .head {
        width: 100%;
        padding: 0.5rem;
        padding-left: 1rem;
        font-size: 1rem;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        letter-spacing: 0.5px;
        color: var(--cardtitletext);
        background: var(--cardtitle);
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.174));

        display: flex;
        justify-content: space-between;

        .btn {
            width: 2rem;
            display: flex;
            justify-content: center;
            cursor: pointer;
            .icon {
                font-size: 10px;
                width: 10px;
            }
        }
    }
    .inner {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .row {
            height: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // padding: 0.5rem;
            .left {
                width: 50%;
                height: 100%;
                min-width: 5rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .img {
                    width: 5rem;
                    height: 3rem;
                    padding: 0.5rem 1rem;
                    border-radius: 0.5rem;
                    color: #fff;
                    -webkit-filter: drop-shadow(0 5px 10px rgba(0,0,0,.174));
                    filter: drop-shadow(0 5px 10px rgba(0,0,0,.174));
                    text-align: center;
                    font-size: 2rem;
                }
            }
            .right {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 7rem;
                .reading {
                    font-size: 2.5rem;
                    letter-spacing: 2px;
                    text-align: center;
                    opacity: 0.7;
                }
            }
        }
    }
}