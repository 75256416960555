.conStat {    
    width: 2rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    /* backdrop-filter: blur(10px); */
    /* background: white; */
}
.conStat.connected {
    color: rgb(0, 202, 27);
}
.conStat.disconnected {
    color: rgb(255, 0, 0);
}

.conStat .tips {
    display: none;
    position: absolute;
    top: 0;
    font-size: 14px;
    padding: 5px;
    border-radius: 5px;
    letter-spacing: 1px;
    min-width: 13rem;
    color: white;
    /* box-shadow: 5px 2px 10px 0 rgba(0, 0, 0, 0.3) inset; */
    align-items: center;
    justify-content: center;
}
.conStat:hover .tips {
    display: flex;
    transform: translate(-9rem, 1rem);
}

.conStat.connected .tips {
    /* background: rgb(0, 202, 27); */
    background: linear-gradient(to bottom right, rgb(0, 202, 27), rgb(0, 160, 21));
}
.conStat.disconnected .tips {
    background: rgb(255, 0, 0);
    display: flex;
    transform: translate(-8rem, 1rem);
}