.OperOver_head {
    width: 100%;
    padding: .5rem;
    padding-right: 1.5rem;
    margin-top: .2rem;
    margin-bottom: .2rem;
    border-radius: .5rem;
    background: var(--divheader);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.18), 0 5px 5px 0 rgba(0,0,0,.1);
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}
.OperOver_backBtn {
    padding: .3rem;
    width: 2rem;
    border-radius: 3rem;
    text-align: center;
    background: var(--colortheme1);
    box-shadow: 0 3px 3px 0 rgba(0,0,0,.4);
    cursor: pointer;
    transition: all var(--css-duration);
}
.OperOver_backBtn:hover {
    background: var(--focuscardbackground);
}
.OperOver_headTxt {
    letter-spacing: 1px;
}

.OperOver_headSelect {
    background: rgba(0,0,0,.4);
    color: white;
    padding: 0 0.7rem 0 0.7rem;
    border-radius: 0.3rem;

    cursor: pointer;
}