.coldboxfloor_outside {
    background: var(--cardbodybackground);
    color: var(--optiontext);
    box-shadow: 0 5px 20px 0 rgba(0,0,0,.2);
    height: 100%;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.coldboxfloor_inside {
    width: 100%;
    height: 100%;
    /* useless container level */
}
.coldboxFloorPlan_imageMain {
    height: calc(100% - 1em - 1rem);    /* 1em is the font of header, 1rem is padding of header */
    width: 100%;
}
#coldboxFloorPlan_imageInner {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;    /* control image inside by js function */
}
#coldbox_FloorImage {
    max-width: calc(100% - 1rem);
    max-height: calc(100% - 1rem);
}
#coldboxFloor_overlapp {
    position: absolute;
    width: 100%;
    height: calc(100% - 1rem - 1rem);

    /* background: repeating-linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,0.2) 9.9%, rgba(255, 255, 255, 1) 10.1%),
    repeating-linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.2) 9.9%, rgba(255, 255, 255, 1) 10.1%); //.. find coordinate x y usage */
}
#coldbox_polygonsvgbody {
/* control by js function */
    background: rgba(0, 0, 0, 0);
}

.coldboxfloor_boxID {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.coldboxfloor_boxIDtxt {
    margin-right: 1rem;
}
.coldboxfloor_boxIDSelect {
    padding-left: .5rem;
    padding-right: .5rem;

    background: rgba(0, 0, 0, 0.224);
    color: white;
}

.coldboxFloorPlan_opertoroverviewBtn {
    position: absolute;
    right: .5rem;
    bottom: 1rem;
    padding: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: .5rem;

    background: var(--colortheme1);
    color: rgb(109, 214, 255);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.224);

    transition: all var(--css-duration);
    cursor: pointer;
}
.coldboxFloorPlan_opertoroverviewBtn:hover {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.224), 0 -50px 0 0 var(--focuscardbackground) inset;
}


@keyframes blink {
	0% {
        stroke-width: 7px;
        stroke: rgb(230, 255, 7);
    }
	100% {
        stroke-width: 3rem;
        stroke: rgba(255, 0, 0, 0);
	}
}

/* panel item */
.cdbx_fr_pnl {
    align-self: center;
    width: 25rem;
    height: 1.3rem;
    margin-left: 1rem;

    display: flex;
    align-items: center;
    gap: 1rem;
}
.cdbx_fr_pnl .pnl_item {
    width: 7rem;
    display: flex;
    gap: 1rem;
    background: linear-gradient(to right, rgba(255,255,255,.2), transparent);
}
.cdbx_fr_pnl .pnl_item .color {
    width: 1rem;
    height: 1rem;
    border-radius: 999px;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,0.4);
}
.cdbx_fr_pnl .pnl_item .desc {
    color: white;
    letter-spacing: 1px;
    font-weight: 100;
}