.DragOptionBar {
    position: fixed;
    right: -5rem;
    top: 5rem;

    background: rgba(255, 255, 255, .4);
    color: grey;
    backdrop-filter: blur(20px);

    padding: .5rem;
    border-radius: .5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.DragOptionBar_AddWidget {
    width: 6rem;
    word-wrap: auto;
    padding: .5rem;
    border-radius: .5rem;

    background: var(--focuscardbackground);
    color: white;
    text-align: center;

    /* box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2), 0 3px 5px 0 rgba(0, 0, 0, .3); */
    filter: drop-shadow(0 3px 8px rgba(0, 0, 0, .2));

    cursor: pointer;
    transition: all .1s;
}
.DragOptionBar_AddWidget:active {
    transform: scale(.9);
}

.DragOptionBar_save {
    width: 6rem;
    word-wrap: auto;
    padding: .5rem;
    border-radius: .5rem;

    background: var(--colortheme1);
    color: white;
    text-align: center;

    /* box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .3); */
    filter: drop-shadow(0 3px 8px rgba(0, 0, 0, .2));

    cursor: pointer;
    transition: all .1s;

    margin-top: 1rem;
}
.DragOptionBar_save:hover {
    /* box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2), 0 3px 5px 0 rgba(0, 0, 0, .3); */
}
.DragOptionBar_save:active {
    transform: scale(.9);
    /* box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .3); */
}

.DragOptionBar_cancel {
    width: 6rem;
    word-wrap: auto;
    padding: .5rem;
    border-radius: .5rem;

    background: rgb(255, 255, 255);
    color: black;
    text-align: center;

    /* box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2), 0 3px 5px 0 rgba(0, 0, 0, .3); */
    filter: drop-shadow(0 3px 8px rgba(0, 0, 0, .2));

    cursor: pointer;
    transition: all .1s;

    margin-top: 1rem;
}
.DragOptionBar_cancel:active {
    transform: scale(.9);
}