.showlevelpopup
{
    position:absolute;
    top: 0;
    right: 0;
    opacity: 1;
}

.PickLevelWidget_div
{
    display: flex;
    flex-direction: column;
    background: rgb(213, 213, 213);
    padding:.5rem;
    border-radius: .3rem;
    color: black;
    margin-top: 0.3rem;
    transition: all var(--css-duration);
    z-index: 10;
    backdrop-filter: blur(5px);

    animation: 0.5s picklevelPopup linear;
}
@keyframes picklevelPopup {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.PickLevelWidget_closeBtn
{
    position: absolute;
    top:0.5rem;
    right: 1rem;

    cursor: pointer;
    transition: all var(--css-duration);
}
.PickLevelWidget_closeBtn:hover
{
    transform: scale(1.3);
}

.PickLevelWidget_txt
{
    margin-top: 1.2rem;
}

.PickLevelWidget_LevelContainer
{
    width: 22rem;
}

.PickLevelWidget_LevelContainer
{
    margin-top: 0.5rem;
    /*border-radius: 0.2rem;
    border-width: 1px;
    border-color: var(--colortheme1);*/
}

.PickLevelWidget_LevelBtn
{
    border-radius: 0.1rem;
    border-width: 1px;
    border-color: var(--colortheme1);
    padding: 0.2rem;
    margin: 0.1rem;
    width: 2rem;
    height: 2rem;
}

.PickLevelWidget_LevelBtn:hover
{
    color: white;
    background: var(--colortheme1);
}

.hidelevelpopup
{
    position:absolute;
    top: 0vh;
    right: 0;
    opacity: 0;

    z-index: -10;
    display:none;
}

.CurrentFloor
{
    background: var(--colortheme1);
    color:white;
}

.PickLevelWidget_BgDiv
{
    position: fixed;
    width: 100%;
    height: 100%;
    /* background: rgba(255, 255, 255, 0.2); */
    background: var(--divheader);
    border-radius: .3rem;
    /* backdrop-filter: blur(20px); */
    transition: all var(--css-duration);
}

.largeFlrBtn {
    width: auto;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}