.LiftAlarmList
{
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.3));  
    border-radius: 0.3rem; 

    display: flex;
    flex-direction: column;
}
.Lift3DLineChart_header {
    background: var(--divheader);
    display: flex;
    flex-direction: row;

    width: 100%;
    font-size: 1rem;
    background: var(--divheader);
    color: var(--divheadertext);
    padding: 0.5rem;
    height: 2rem;
    --css_duration: var(--css-duration);
    box-shadow: 0 15px 10px 0 rgba(0, 0, 0, 0.10), 0 5px 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem
}

.Lift3DLineChart_headerTitle {
    margin-left: 0.8rem;
}

.Lift3DLineChart_SearchHeader {
    margin-top: 0.5rem;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    border-radius: 0.3rem;
}
.Lift3DLineChart_SearchHeader * {
    color: white;
}
.Lift3DLineChart_SearchTxt {
    color: black;
    align-self: center;
}
.lift3DLineChart_header_searchBtn {
    background: rgba(0, 0, 0, 0.2);
    padding: 0.5rem;
    border-radius: 0.3rem;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-self: center;
    align-self: center;
    cursor: pointer;
    transition: all var(--css_duration);
    margin-left: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
.lift3DLineChart_header_searchBtn:hover {
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}

.Lift3DLineChart_ChartContainer {
    display: flex;
    justify-content: space-evenly;
    height: 100%;
}
.Lift3DLineChart_item {
    width: 100%;
    height: 95%;
    text-align: center;
    margin: 0.5rem;
    background: rgb(255, 255, 255, 0.1);
    color: black;
    align-self: center;
}
.Lift3DLineChart_itemFailTxt {
    color: black;
    margin: auto;
    text-align: center;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 2rem;
}
.lift3DLineChart_header_color1 {
    align-self: center;
    background: var(--focuscardbackground);
    padding: 0.3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0.5rem;
    border-radius: 0.3rem;
    cursor: pointer;

    transition: all var(--css_duration);
}
.lift3DLineChart_header_color2 {
    /* disabled item */

    box-shadow: 0 -100px 0 0 rgb(78, 78, 78) inset;
}


.lift3DLineChart_debugBtn {
    background: var(--focuscardbackground);
    padding: 0.5rem;
    border-radius: 0.3rem;
    margin-left: 0.5rem;
    cursor: pointer;
}

.LiftGraphSelect {
    background: var(--focuscardbackground);
    margin-left: 0.3rem;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: all var(--css-duration);
}
.LiftGraphSelect:hover {
    background: rgba(0, 0, 0, 0.288);
}