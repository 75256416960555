.Coldbox_S_header {
    width: 100%;
    padding: .5rem;
    padding-right: 1.5rem;
    margin-top: .2rem;
    margin-bottom: .2rem;
    border-radius: .5rem;

    background: var(--divheader);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.18), 0 5px 5px 0 rgba(0,0,0,.1);
    color: #fff;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.Coldbox_S_header .Left, .Coldbox_S_header .Right {
    display: flex;
    align-items: center;
}
.Coldbox_S_header .Right .Coldbox_BackBtn {
    padding: .3rem;
    width: 3rem;
    border-radius: .3rem;
    text-align: center;
    background: var(--colortheme1);
    box-shadow: 0 3px 3px 0 rgba(0,0,0,.4);
    margin-right: 1rem;

    cursor: pointer;
    opacity: .7;
    transition: all var(--css-duration);
}
.Coldbox_S_header .Right .Coldbox_BackBtn:hover {
    opacity: 1;
}
.Coldbox_S_header .Right .Coldbox_Tab {
    padding: .3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: .3rem;
    text-align: center;
    margin-right: .5rem;
    opacity: .5;

    background: var(--colortheme1);
    box-shadow: 0 3px 3px 0 rgba(0,0,0,.2);
    transition: all var(--css-duration);
    cursor: pointer;
}
.Coldbox_S_header .Right .Coldbox_Tab:hover {
    opacity: 1;
}
.Coldbox_S_header .Right .Coldbox_Tab.selected {
    opacity: 1;
    background: var(--focuscardbackground);
}

.Coldbox_S_serchBtn {
    background: var(--focuscardbackground);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.18), 0 5px 5px 0 rgba(0,0,0,.1);
    padding: .2rem;
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: .3rem;

    opacity: .7;
    transition: all var(--css-duration);
    cursor: pointer;
}
.Coldbox_S_serchBtn:hover {
    opacity: 1;
}