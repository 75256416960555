.menu_div_Statistic {
    background: red;
    position: fixed;
    z-index: 1000;
    left: 15rem;
    top: 0rem;
    font-size: 2rem;

    transition: all var(--css-duration);
}

.hideMenuLock {
    left: -2rem;
    opacity: 0;
}