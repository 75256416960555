.loginbody {
	height: 100vh;
	width: 100%;
	min-height: 768px;
	min-width: 1024px;
	background: linear-gradient(80deg,#190a0544,#000000ce),/*, #87000000),*/
	url(../../assets/images/hong-kong-night.jpg) no-repeat;
	/* background: linear-gradient(80deg,#ffffff00,#000000ce),
	/*, #87000000),
	url(../../assets/images/hong-kong-night.jpg) no-repeat; */
	background: linear-gradient(80deg,white,black), url(../../assets/images/hong-kong-night.jpg) no-repeat;
	background-size: cover;
	background-blend-mode: multiply;
	background-position: right;

	display: flex;
	justify-content: center;
	align-self: center;

	animation: 160s moveleftSlowly linear;
	transition: all .5s;
}
@keyframes moveleftSlowly {
	0% {
		background-position: left;
	}
	100% {
		background-position: right;
	}
}
.loginbody .Left,
.loginbody .Right {
	width: 50%;
	height: 100%;
	min-height: 768px;

	display: flex;
	flex-direction: column;
	justify-content: center;
}
.loginbody .Left {
	max-width: 40rem;
	align-content: flex-start;
}
.loginbody .Right {
	max-width: 60rem;
	align-content: flex-end;
}
.loginSlogan {
	width: 40vw;
	font-size: 3.5rem;
	color: white;

	letter-spacing: 2.5px;
	font-weight: 300;

	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.loginSloganbody {
	width: 40vw;
	font-size: 1.2rem;
	color: rgba(255, 255, 255, 0.7);
	text-shadow: 0 0 0px rgba(136, 136, 136, 0);
}

.loginform {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2rem;
	align-content: center;

	align-self: flex-end;
	width: 30rem;
	height: 30rem;
	background:linear-gradient(to bottom , rgba(255, 255, 255, .1), rgba(255, 255, 255, .2));
	border-radius: 0.5rem;
	margin-top: 4rem;
	padding-top: 1rem;

	backdrop-filter: blur(10px);
}
.formInnerRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-content: center;
	margin-left: 2rem;
	margin-right: 2rem;
}

.logininput {
	padding: 0;	
	margin-left: 2rem;
	margin-right: 2rem;
	border-radius: 1rem;
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, .1));
	border-width: 1px;
	border-color: rgb(255, 255, 255);
	transition: all var(--css-duration);
	caret-color: var(--focuscardtext);
	display: flex;
	flex-direction: row;
	height: 4rem;
}

.Loginuserimg {
	height: 2rem;
	width: auto;
	margin: auto;
	margin-left: 1rem;
}

.logininputtextbox
{
	background: transparent;
	color: rgba(255, 255, 255, 0.6);
	width: auto;
	padding-left: 1rem;
	font-size: 1.5rem;
	margin: auto;
	width: calc(100% - 4vh);
	/*padding-bottom: calc(1vh);*/
	transition: all var(--css-duration);
}

.logininputtextbox:hover
{
	outline:none;
}

.logininputtextbox:focus
{
	outline:none;
}

.loginbutton {
	align-self: center;
	background: #2e3830;
	color: #fff;
	width: 85%;
	border-radius: 0.5rem;
	height: 3.5rem;
	font-size: 1.4rem;
	outline: 0;
	padding-top: .8rem;
	padding-bottom: .8rem;
	transition: all 1.2s;

	box-shadow: 0 5px 10px 0 rgba(0,0,0, .2), 0 5px 20px 0 rgba(0,0,0,.1);
}

.loginbutton:hover {
	outline: none;
	box-shadow: 0 5px 8px 0 rgba(0,0,0,.2),
	0 5px 15px 0 rgba(0,0,0,.2),
	0 -100px 0 0 #c53211 inset;
}
.loginbutton:focus {
	outline: none;
	box-shadow: 0 -100px 0 0 rgba(255, 255, 255, 0.61) inset;
	background: transparent;
}
.loginbutton:active {
	outline: none;
}

.forgetpassword {
	color: white;
	margin-bottom: 0;
	cursor: pointer;
	transition: all var(--css-duration);
	align-self: center;
	padding: 0.5rem;
	border-radius: 0.3rem;
}

.forgetpassword:hover {
	box-shadow: 0 -40px 0 0 #2e3830 inset;
}

.login_image {
	position: absolute;
	top: 3rem;
	/* left: 10rem; */
	width: 9rem;
	height: auto;
}


/* Preload background, do something */
.preloading
{
	opacity: 0.1;
	background: #e6dbc9;
}

.remember_me_div {
	text-align: center;
	/* margin-left: 2rem;
	margin-bottom: 0;
	margin-top: 1rem; */
	color: var(--focuscardtext);
	font-size: 1.2rem;
	align-self: center;
}

.focusing {
	background: transparent;
	box-shadow: 0 -60px 0 0 rgba(255, 255, 255, 0.1) inset;
}

.focusing input {
	color: white;
}

.AutoLoginContainer {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: linear-gradient(to bottom, var(--colortheme1), var(--colortheme1));
	opacity: .5;
	top: 0;
	left: 0;

	font-size: 4rem;

	display: flex;
	flex-direction: column;
	justify-content: center;
}
  
.AutoLoginIcon {
	width: 10rem;
	height: 10rem;
	margin: auto;
	border-radius: 999px;
	animation: rotate 2s linear infinite;
	border: 4px solid #fff;
}
.AutoLoginIcon div {
	border-radius: 999px;
	background: white;
	margin: 0.75rem;
	width: 1rem;
	height: 1rem;
}
@keyframes rotate {
	0% {}
	100% {
		transform: rotate(360deg);
	}
}

.AutoLoginBackground {
	background: rgba(0, 0, 0, 1);
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
}

.hideIsAutoLogin {
	display: none;
}


/* Small size screen force resize css */
@media only screen and (max-width: 450px) {
	.loginbody {
		flex-direction: column;
		justify-content: flex-start;
		padding-top: 7rem;
	}
	.loginbody .Left {
		width: 100%;
		height: auto;
	}
	.loginbody .Right {
		width: 100%;
		height: 50%;
	}
	.login_image {
		left: 2rem;
	}
	.loginSlogan {
		width: 100%;
		text-align: center;
	}
	.loginSloganbody {
		width: 100%;
	}
	.loginform {
		align-self: center;
		max-width: calc(100% - 2rem);
		backdrop-filter: blur(0);
	}
}