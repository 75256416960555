.LoginAlertBox
{
    position:fixed;
    width: 50vw;
    height: 30vh;
    top: 35vh;
    left:25vw;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 0.5rem;
    color: white;
    box-shadow: 5px 10px 10px 0 rgba(0, 0, 0, 0.3), -5px 10px 10px 0 rgba(0, 0, 0, 0.3);
    z-index:20;
    transition: all var(--css-duration);
    backdrop-filter: blur(15px);

    animation: .5s loginfaileffect linear;

    font-size: 1.5rem;
}
@keyframes loginfaileffect {
    0%{
        backdrop-filter: blur(0px);
    }
    100% {
        backdrop-filter: blur(15px);
    }
}
.LoginAlertBox .center {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.LoginAlertBox .center .btn {
    background: var(--focuscardbackground);
    width: 10rem;
    height: 2.5rem;
    border-radius: 10px;
    letter-spacing: 2px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.login_close {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    cursor: pointer;
}

.login_alertmsg {
    font-size: 1.5rem;
    width: 100%;
    text-align: center;

    letter-spacing: 2px;
}

.login_alerticon {
    width: 100%;
    text-align:center;
    font-size: 4rem;
}

.loginalert_darkbg {
    position:fixed;
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    background:rgba(0, 0, 0, 0.2);
    z-index:10;

    transition: all var(--css-duration);
}