.OptionPopup_btn {
    background: var(--colortheme1);
    font-size: 1rem;
    align-self: center;
    color: white;
    padding: .5rem;
    width: 3rem;
    text-align: center;
    border-radius: .3rem;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.18));
    cursor: pointer;
    transition: all .5s;
}

.OptionPopup_popup {
    position: absolute;
    top: 0;
    background: var(--colortheme2);
    color: var(--optiontext);
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.18));
    width: 30rem;
    height: 15rem;
    font-size: 1.2rem;
    z-index: 100;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
}

.Opt_popup_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Opt_popup_headtxt {
    font-size: 1.5rem;
}
.Opt_popup_headclose {
    cursor: pointer;
}

.Opt_popup_bottom {
    height: 100%;
    display: flex;
    justify-content: space-between;
}
.Opt_popup_Legend {
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
    padding: 1rem;
}
.Opt_popup_Legend legend {
    margin: 0;
    padding: 0;
    font-size: 1rem;
}
.Opt_popup_Excel {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Opt_popup_Excel {
    background: var(--colortheme1);
    align-self: center;
    color: white;
    padding: .5rem;
    min-width: 8rem;
    font-size: 0.8rem;
    text-align: center;
    border-radius: .3rem;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.18));
    cursor: pointer;
    transition: all .5s;
}

.Opt_popup_LegendRow {
    width: 10rem;
    padding: .5rem;
    padding-left: 1rem;
    min-width: 8rem;
    font-size: 0.8rem;
    /* text-align: center; */
    border-radius: .3rem;
    background: var(--colortheme2);
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.18));
}
.Opt_popup_LegendRow.VHigh {
    background: red;
    color: white;
}
.Opt_popup_LegendRow.High {
    background: yellow;
    color: black;
}