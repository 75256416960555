.CBAlarm {
    width: calc(100% - 3rem);
    height: calc(100% - 4rem);
    transition: all var(--css-duration);
    padding: 1rem;
    border-radius: .3rem;
    margin: auto auto 0;

    -webkit-animation: draglayoutOnload 0.5s ease-out;
    animation: draglayoutOnload 0.5s ease-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.CBAlarm_table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;

    overflow: auto;
}

.CBAlarm_bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.coldboxAlarm_center {
    margin: auto;
    font-size: 2rem;
    letter-spacing: 2px;

    color: grey;
}