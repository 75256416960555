.wrap {
    display: flex;
    flex-direction: row;
    min-width: 10rem;
    max-width: 10rem;
}
.Planner_statusRow {
    padding: .5rem;
    color: var(--optiontext);
    transition: all var(--css-duration);

    display: flex;
    flex-direction: row;
    align-items: center;
}
.Planner_statusRow .col1 {
    min-width: 48rem;
    max-width: 50rem;
    text-align: right;
}
.Planner_statusRow .col2 {
    min-width: 8rem;
    max-width: 8rem;
    text-align: left;
}
.Planner_statusRow .col3 {
    width: 100%;
}

.Planner_statusRow .subScroll {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}
