.body {
	width: 100%;
	height: 100%;
	min-width: 1024px;
	min-height: calc(768px - 3.7rem);

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;

	overflow-y: hidden;
}

.icon_img {
	margin-left: 1.45rem;
	margin-top: 1.7rem;
	position: absolute;
	color: grey;
}

.middle_div {
	width: 100%;
	max-width: 110rem;
	min-height: calc(768px - 3.7rem);
	min-width: 1024px;
	height: calc(100vh - 2.5vw - 1.4rem - 1.2rem);
	border-radius: 0.5rem;

	display: flex;
}

.menu_div {
	position: absolute;
	width: 6%;
	margin-top: 1.5rem;
	font-size: 2rem;
}

.overlay_div1 {
	height: 8rem;
	width: 30rem;
	/* background: url('../assets/images/hong-kong-night.jpg'),linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.6),  rgba(255, 255, 255, 0.4)); */
	
	/* background: url('https://s3.envato.com/files/300989119/20190425_0007005.jpg'),linear-gradient(to bottom right,var(--colortheme1), var(--colortheme2),  rgba(255, 132, 0, 0.4)); */
	/* background-position: center;
	background-size: cover;
	background-blend-mode: multiply; */
	filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.3));
	border-radius: .5rem;
	backdrop-filter: blur(30px);
}

.overlay_div2 {
	height: 50vh;
	width: 27rem;
	background-color: var(--colortheme1);
	opacity: 0.75;
	margin-left: 0.6rem;
	box-shadow: 0 4px 8px 0 #888,
			0 2px 4px 0 #BBB;
	z-index: 20;
}

.overlay_body {
	position: absolute;
	bottom: 3rem;
	margin-left: 2rem;
	display: flex;
	align-self: flex-start;

	animation: companyContainer 1s ease-out;
}
@keyframes companyContainer {
	0% {
		opacity: 0;
		transform: translateY(-30px);
	}
	100% {
		opacity: 1;
	}
}

.overlap_title {
	color: white;
	margin-left: 1.5rem;
	margin-top: 1.8rem;
	font-size: 1.9rem;
	letter-spacing: 0rem;
	transition: all 0.5s;
	/*z-index: 1;*/
	/*text-shadow:
	0 0 2px #fff,
	0 0 5px #CCC,
	0 0 10px #AAA,
	0 0 20px #888;*/
	letter-spacing: 2px;
}

.oeverlap_paragraph {
	margin-left: 1rem;
	margin-top: 0.7rem;
	font-size: 0.8rem;
	outline: none;
	color: white;
	transition: all 0.5s;
	z-index: 1;
}

.oeverlap_paragraph:hover {
	text-shadow:
        0 0 5px #fff,
        0 0 20px #CCC;
	color: white;
}

.oeverlap_paragraph_icon {
	margin-left: 1.6rem;
	margin-top: 0.7rem;
	font-size: 0.9rem;
}

.everlay_div2_title {
	margin-top: 1.8rem;
	margin-left: 1.5rem;
	font-size: 0.95rem;
}

.everlay_div2_levels {
	margin-top: 0.6rem;
	margin-left: 1.33rem;
	width: 24rem;
	font-size: 0.95rem;
	font-weight: 1;
}

.menu_icon {
	font-size: 1.8rem;
	padding: 2px;
	transition: all 0.2s;
}

.menu_icon:hover {
	text-shadow:
        0 0 10px #fff,
        0 0 20px #CCC,
        0 0 30px #AAA,
        0 0 80px #888;
}

.menu_icon_image {
	width: 25%;
	height: auto;
	outline: none;
}

.menu_btn {
	margin-top: 0.78rem;
}

.menu_btn.focus {
	outline: 0;
	border-width: 0;
}

button:focus {outline:0;}	/* remove the focus border on all buttons */

.choose_level_title {
	/*font-family:'Courier New';*/
	font-weight:1;
	/*letter-spacing: -0.05rem;*/
	font-size: 0.98rem;
	margin-left: 4.5vw;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  padding-top: 5.5rem; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal {
	background-color: transparent;
	border-width: 0px;
}

.modal-content {
  background-color: transparent;
  margin-left: 3.8rem;
  margin-top: 0.68rem;
  border: 1px solid #888;
  width: 11.8vw;
  border-width: 0px;
}

.menu_list_item {
	width: 100%;
	padding-bottom: 0.6rem;
	padding-top: 0.6rem;
	padding-left: 1rem;
	margin: 0.5rem;
	background-color: white;
	color: black;
	font-size: 0.9rem;
	/*opacity: 0.9;*/
	/*font-family:'Courier New';*/
	font-weight:1px;
	letter-spacing: -0.1rem;
	transition: all 0.5s;
}

.menu_list_item:hover {
	background-color: red;
	color: white;
	opacity: 1;
	letter-spacing: -0.07rem;
}

.menu_list_item_inside {
	width: auto;
	padding-bottom: 0.2rem;
	padding-top: 0.2rem;
	padding-left: 1rem;
	margin: 0.2rem;
	margin-left: 2rem;
	background-color: white;
	color: black;
	font-size: 1rem;
	opacity: 0.9;
	/*font-family:'Courier New';*/
	font-weight:1px;
	letter-spacing: -0rem;
	display:none;
}

.menu_item_text {
	margin-left: 1rem;
}

.overlap_div1_background_color {
	position: absolute;
	/* background: linear-gradient(to left top, var(--focuscardbackground), rgba(121, 121, 121, .5), rgba(46, 46, 46, .5)); */
	background: linear-gradient(to bottom right, rgba(255, 255, 255, .2), rgba(255, 255, 255, .2), var(--focuscardbackground));
	width: calc(30rem - 0rem);
	height: calc(8rem);
	top: 0;
	left: 0;
	z-index: 0;

	border-radius: 0.5rem;
	transition: all .5s;
}

.overlap_subtitle {
	color: rgb(255, 255, 255, .7);
	margin-left: 1.5rem;
	margin-top: 1rem;
	font-size: 1.1rem;
	letter-spacing: 1px;
}


/* client company logo container */
.clientLogo {
	width: 80%;
	height: 100%;
}
.clientLogo .bg {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	background-position: center;
	background-repeat: no-repeat;
}


/* New designed bottom logo display */
.newMainBottomLogo {
	width: 100%;
	height: 100%;

	/* background: url(../assets/images/BlueCity123.jpg),linear-gradient(to right, var(--colortheme2) 20%, transparent); */
	background: rgba(255, 255, 255, 0.2);
	background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: overlay;

	background-size: cover;
	background-position: center;
	border-radius: 0.5rem;

	display: flex;
	justify-content: center;
}
.newMainBottomLogo .left {
	height: 100%;
	width: 50%;
	padding-left: 1rem;
	font-size: 1.3rem;
	background: var(--focuscardbackground);
	border-top-left-radius: 0.5rem;
	border-bottom-left-radius: 0.5rem;

	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 4px;
}
.newMainBottomLogo .right {
	width: 50%;
	background: linear-gradient(to right, var(--focuscardbackground), transparent);
}
.newMainBottomLogo .title,
.newMainBottomLogo .subtitle {
	color: rgba(255, 255, 255, 0.7);
	font-weight: 1000;
	letter-spacing: 0.5px;
	background: linear-gradient(to left, rgba(255, 255, 255, 0.5), white);
	background-clip: text;
	-webkit-text-fill-color: rgba(255, 255, 255, 0.1);
}



@media only screen and (max-width: 750px) {
	.body {
	  align-content: flex-start;
	}
}
/* //.. */