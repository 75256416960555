.ColdboxSetPt_head {
    width: 100%;
    padding: .5rem 1.5rem .5rem .5rem;
    margin-top: .2rem;
    margin-bottom: .2rem;
    border-radius: .5rem;
    background: var(--divheader);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.18), 0 5px 5px 0 rgba(0,0,0,.1);
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.ColdboxSetPt_headL, .ColdboxSetPt_headR {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.ColdboxSetPt_backBtn {
    padding: .3rem;
    width: 2rem;
    border-radius: 3rem;
    text-align: center;
    background: var(--colortheme1);
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 40%);
    margin-right: 1rem;
    cursor: pointer;
    transition: all var(--css-duration);
}
.ColdboxSetPt_backBtn:hover {
    background: var(--focuscardbackground);
}
.ColdboxSetPt_headTitle {
    margin-right: 1rem;
    letter-spacing: 1px;
}
.ColdboxSetPt_headInput {
    padding-left: .5rem;
    padding-right: .5rem;
    margin-right: 1rem;
    border-radius: 999px;
    background: rgba(0,0,0,.224);
    color: #fff;
}
.ColdboxSetPt_headselect {
    padding-left: .5rem;
    padding-right: .5rem;
    margin-right: 1rem;
    border-radius: 999px;
    background: rgba(0,0,0,.224);
    color: #fff;
}
.ColdboxSetPt_headselect option {
    background: rgba(0, 0, 0, 0.4);
}
.ColdboxSetPt_headreload {
    background: var(--focuscardbackground);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.18), 0 5px 5px 0 rgba(0,0,0,.1);
    padding: .2rem 1rem;
    border-radius: .3rem;
    opacity: .7;
    transition: all var(--css-duration);
    cursor: pointer;
}
.ColdboxSetPt_headreload:hover {
    opacity: 1;
}