.Heatmap_maindiv
{
    width: 100%;
    height: 100%;
}

.Heatmap_header
{
    width: 100%;
    padding: 0.5rem;
    /* background: var(--divheader); */
    background: var(--cardtitle);
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.18), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: var(--divheadertext);

    border-radius: 0.3rem;
}

.Heatmap_header *
{
    margin-right: 0.8rem;
}

.Heatmap_maindiv
{
    width: 100%;
    height: 95%;

    /* background: linear-gradient(to right, rgba(255, 255, 255, 0.329), rgba(255, 255, 255, 0.425)); */
    background: var(--cardbodybackground);
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.18), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
}

.HeapMapOption
{
    background: rgba(80, 80, 80, 0.486);
    color: white;
    position: absolute;
    top: 0.15rem;
    right: 0;
    padding: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.3rem;
}


/* No Permission style */
.NoPerm4_main {
    width: 100%;
    height: calc(100% - 1.5rem);

    background-image: url(../../../assets/images/BlueCity.jpg);
    background-position: center;
    background-size: cover;
    border-radius: .3rem;
}
.noPerm4_blur {
    backdrop-filter: blur(5px);
    background: rgb(0, 0, 0, .3);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: .3rem;
}
.NoPerm4_Txt {
    margin: auto;
    font-size: 1.5rem;
    color: white;
    letter-spacing: 3px;
}