.OccupancyTrue
{
    color: rgb(0, 255, 0);
}

.OccupancyFalse
{
    color: white;
}

.lbl_Occupancy
{
    font-size: 1.5rem;
    margin-top: 0.5rem;
    background: rgba(78, 78, 78, 0.534);
    padding: 0.5rem;
    padding-left: 2rem;
    padding-right: 2rem;

    border-radius: .5rem;
    filter:drop-shadow(0px 8px 10px rgb(0, 0, 0, .2));
}
.box2_level1
{
    background: rgba(0, 0, 0, 0.192);
    padding: 1rem;

    border-radius: .5rem;
    filter:drop-shadow(0px 8px 10px rgb(0, 0, 0, .2));

    font-size: 1.5rem;
}