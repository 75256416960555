.ToiletOcc_Summary {
    width: 100%;
    height: 100%;

    box-shadow: 0 15px 10px 0 rgba(0, 0, 0, 0.05), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    background: var(--cardbodybackground);

    border-radius: 0.3rem;

    animation: floor4readingcardsOnload 1s ease-out;
}
.ToiletOcc_header {
    display: flex;
    flex-direction: row;

    width: 100%;
    padding: .5rem;
    font-size: 1rem;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;

    color: var(--cardtitletext);
    background: var(--cardtitle);
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.174));
}
.ToiletOcc_header * {
    margin-left: .5rem;
}
.ToiletOcc_headertxt {
    letter-spacing: 0.5px;
}
.ToiletOcc_Inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    height: calc(100% - 2rem);
    padding: 2rem;
}
.ToiletOcc_Male {
    height: 50%;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-size: 2rem;
    letter-spacing: 2px;

    color: var(--optiontext);
}
.ToiletOcc_FMale {
    height: 50%;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-size: 2rem;
    letter-spacing: 2px;

    color: var(--optiontext);
}
.ToiletOcc_Txt {
    margin-top: auto;
    margin-bottom: auto;
    width: 4rem;
    padding: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: .5rem;

    color: white;
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.174));

    text-align: center;
}
.ToiletOcc_Reading {
    margin-top: auto;
    margin-bottom: auto;
}
.ToiletOcc_Male .ToiletOcc_Txt {
    background: rgb(0, 145, 255);
}
.ToiletOcc_FMale .ToiletOcc_Txt {
    background: red;
}