.Toilet_fb {
    width: 100%;
    height: 100%;
    background: rgb(255,238,197);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
    padding: 1rem;
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}
.Toilet_fb_title {
    text-align: center;
    font-size: 1.5rem;
    opacity: 0.7;
    letter-spacing: 2px;
}

.Toilet_fb_icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
}
.Toilet_fb_icon {
    cursor: pointer;
    transition: all 0.5s ease-out;
    width: 5rem;
    text-align: center;
    z-index: 100;
    color: white;
}
.Toilet_fb_icon.icon1 {
    color: rgb(240, 102, 129);
}
.Toilet_fb_icon.icon2 {
    color: rgb(73,191,181);
}
.Toilet_fb_icon.icon3 {
    color: rgb(109,206,245);
}

.Toilet_fb_improve {
    width: calc(100% - 2rem);
    font-size: 1rem;
    opacity: 0.7;
    letter-spacing: 2px;
}
.Toilet_fb_textarea {
    width: calc(100% - 2rem);
    padding: 1rem;
    color: grey;
    border-radius: 0.5rem;
}

.Toilet_fb_btn {
    background: rgb(73,191,181);
    color: white;
    box-shadow: 0 5px 5px 0 rgba(0, 128, 128, 0.4);

    width: 10rem;
    padding: 0.5rem;
    border-radius: 999px;
    text-align: center;

    cursor: pointer;
    transition: all 0.5s ease-out;
}
.Toilet_fb_btn:hover {
    box-shadow: 0 5px 5px 0 rgba(0, 128, 128, 0.4), 0 -50px 0 0 rgb(156,209,155) inset;
}
.Toilet_fb_btn:active {
    transform: scale(0.9);
}

.AnimationIcon {
    position: absolute;
    width: 5rem;
    height: 5rem;
    border-radius: 0.5rem;

    transition: all 0.7s cubic-bezier(0.4,-0.18, 0.32, 1.43);
}
.fb_icon_1 {
    margin-left: 10rem;
    background-color: rgb(240, 102, 129);
}
.fb_icon_2 {
    background: rgb(73,191,181);
}
.fb_icon_3 {
    margin-right: 10rem;
    background: rgb(109,206,245);
}



.Toilet_fb_popupmsg {
    z-index: 101;
    position: absolute;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    border: solid rgba(255, 255, 255, 0.4) 5px;
    box-shadow: 0 5px 5px 0 rgb(0 0 0 / 18%), 0 5px 5px 0 rgb(0 0 0 / 10%) inset;
    color: grey;
    height: 20rem;
    width: 30rem;
    border-radius: 0.5rem;
    font-size: 3rem;
    letter-spacing: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    animation: BlurFeedbackMsg 1s ease-in-out;
}
@keyframes BlurFeedbackMsg {
    0% {
        opacity: 0;
        transform: translateY(-100px);
    }
    100% {
        opacity: 1;
    }
}