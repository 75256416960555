/*.button
{
    border-radius: 0.5rem;
    background: transparent;
    color: white;
    border-width: 1px;
    padding:0.4rem;
    margin: 0.2rem;
}*/

/*button:hover 
{
    background: white;
    color: black;
}*/

.drawmaindiv
{
    /*max-width: 1200px;*/
    background: linear-gradient(to bottom, rgb(184, 0, 0), rgb(230, 230, 230));    
    padding: 2rem;
    /*border-radius: 1.5rem;*/
}

.ng-isolate-scope
{
    width: 100%;
}

.buttonfirst
{
    margin-left: 3rem;
}