.Lift_graphContainer
{
    background: rgba(0, 0, 0, 0);
    height: 100%;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 0.3rem;
}

/* .Lift_graphLeftRightContainer
{
    display: flex;
    flex-direction: column;
    justify-content: start;
    background: rgba(0, 0, 0, 0);
    background: red;
    border-radius: 0.5rem;
    font-size: 1.3rem;
    color: black;
} */
.Lift_graphDirection2
{
    background: rgba(255, 255, 255, 0.3);
    /*position: absolute;
    top: 0;
    left: calc(75% - 10% + 1rem);*/
    width: 34%;
    height:100%;
    border-radius: 0.5rem;
    padding: 0.5rem;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}
.Lift_graphDirection2 *
{
    margin: 0.5rem;
    margin-top: 1rem;
}
/* .Lift_graghArrowIcon
{
    text-align: center;
    width: 2rem;
    font-size: 1.5rem;
    color: var(--colortheme1);
    transition: all var(--css-duration);
    background: rgba(0, 0, 0, 0);
    padding: 0.2rem;
}
.Lift_graphDirectionNameTxt
{
    font-size: 1.3rem;
    color: black;
} */
/* 
.Lift_graphDoorCloseContainer
{
    color:black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.Lift_graphDoorCloseRow
{
    display: flex;
    flex-direction: row;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
} */
.Lift_graphTitle
{
    color: black;
    font-size: 1.4rem;
}
/* .Lift_graphDoorOpenTxt
{
    margin: auto;
} */
/* .Lift_graphDoorclosedIcon1
{
    margin:auto;
    padding: 0.5rem;
} */
/* .Lift_graphDoorclosedIcon2
{
    margin: auto;
    padding: 0.5rem;
} */

/* .vibrationColor1
{
    border-width: 1px;
    border-color: red;
    color: red;
} */