.Planner_tableHead {
    margin-top: 1.5rem;
    border-radius: .5rem;
    padding: .5rem;
    background: hsla(0,0%,50.2%,.3);
    color: var(--optiontext);

    display: flex;
    flex-direction: row;
    align-items: center;
}
.Planner_tableHead .col1 {
    min-width: 10rem;
    max-width: 10rem;
}
.Planner_tableHead .col2 {
    min-width: 8rem;
    max-width: 8rem;
}
.Planner_tableHead .col3 {
    width: 100%;
}
