.Coldbox_SPage {
    width: calc(100% - 4rem);
    height: calc(100% - 5rem);
    transition: all var(--css-duration);
    padding: 1rem;
    border-radius: .3rem;
    margin: auto auto 0;

    background: linear-gradient(180deg,var(--maindiv-color2),var(--maindiv-color));
    -webkit-filter: drop-shadow(0 10px 15px rgba(0,0,0,.3));
    filter: drop-shadow(0 10px 15px rgba(0,0,0,.3));
    -webkit-animation: draglayoutOnload 0.5s ease-out;
    animation: draglayoutOnload 0.5s ease-out;

    display: flex;
    flex-direction: column;
}

.Coldbox_S_title {
    letter-spacing: 2px;
    margin-right: .5rem;
}
.Coldbox_S_droplist {
    padding-left: .5rem;
    padding-right: .5rem;
    margin-right: 1rem;
    border-radius: 999px;
    background: rgba(0, 0, 0, 0.224);
    color: white;
}



fieldset { 
    
    margin-top: 0.5rem;
    border-radius: 1rem;
    border-width: 2px;
    border-color: rgba(128, 128, 128, 0.5); 
}

legend { 
    margin-left: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.2rem;
    color: white; 
    opacity: .5;
    border-radius: .3rem; 
}

.Coldbox_S_tablemain {
    width: 100%;
    max-height: calc(100% - 4rem);
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: .3rem;

    color: var(--optiontext);
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.224);

    --css_duration: var(--css-duration);
    /* align-items: center; */
}
.Coldbox_S_tablemainTitle {
    font-size: 1.5rem;
    letter-spacing: 2px;
    width: 100%;
}
.Coldbox_S_tableList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: space-between;

    max-height: calc(100%);
}
.Coldbox_S_tableList .list_col {
    width: calc(33% - 0rem);
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
}
.Coldbox_S_table {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    /* box-shadow: 0 0 0 2px rgba(147, 147, 147, 0.5); */
    max-width: calc(100% - 0rem);
    width: calc(100% - 0rem);
    min-height: 15rem;
    border-radius: 1rem;
    padding: .5rem;
    /* margin-top: 1.5rem; */
}
.Coldbox_S_tableTitle {
    position: absolute;
    margin-top: -1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.2rem;
    color: white;
    background: var(--colortheme1);
    opacity: .5;
    border-radius: .3rem;
}
.Coldbox_S_table .col_half {
    width: 100%;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
}
.Coldbox_S_table .col_alarm_set_point {
    width: calc(90% - 1rem);
    /* margin-top: 0.5rem; */
    display: flex;
    flex-direction: column;
}
.Coldbox_S_table .col_alarm_set_point .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.Coldbox_S_table .col_half .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.Coldbox_S_table .col_half .row.lastUpdateTime {
    /* width: 100%; */
    margin-top: 10px;
    /* flex-direction: row; */
    align-items: flex-start;
}
.Coldbox_S_table .col_half .row .readingTitle {

}
.Coldbox_S_table .col_half .row .reading {
    display: flex;
    flex-direction: row;
}
.Coldbox_S_table .col_half .row .reading .readingUnit {

}