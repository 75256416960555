.Lift_graph_main {
    /* background: rgba(255, 255, 255, .3); */
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2); */
    /* background: linear-gradient(90deg,hsla(0,0%,100%,.329),hsla(0,0%,100%,.425)); */
    background: var(--cardbodybackground);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
}

/* Lift Have People Start */
.Lift_graphHavePeople {
    width: 90%;
    height: 3rem;
    background: grey;
    margin: auto;
    border-radius: 0.5rem;
    text-align: center;
    font-size: 1.3rem;
    transition: all var(--css-duration);
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
    color: rgb(197, 197, 197, 1);
    opacity: 0.5;
}
.Lift_graphHavePeople * {
    margin-top: .8rem;
}
.LiftGraph_HavePeopleStatus {
    opacity: 1;
    color: rgb(255, 255, 255);
    background: var(--focuscardbackground);
    box-shadow: 0 10px 25px -10px var(--focuscardbackground);
}
/* Lift Have People ENd */