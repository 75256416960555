.ColdboxGp_Manage {
    width: calc(100% - 4rem);
    height: calc(100% - 5rem);
    transition: all var(--css-duration);
    padding: 1rem;
    border-radius: .3rem;
    margin: auto auto 0;
    /* background: linear-gradient(180deg,var(--maindiv-color2),var(--maindiv-color));
    -webkit-filter: drop-shadow(0 10px 15px rgba(0,0,0,.3));
    filter: drop-shadow(0 10px 15px rgba(0,0,0,.3)); */
    -webkit-animation: draglayoutOnload 0.5s ease-out;
    animation: draglayoutOnload 0.5s ease-out;
    display: flex;
    flex-direction: column;
}
.ColdboxGp_Manage_title {
    font-size: 1rem;
    letter-spacing: 1.5px;
}
.Coldbox_Manage_table {
    height: 100%;
    margin-bottom: .5rem;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,.11), 0 5px 15px 0 rgba(0,0,0,.08);
}



/* Popup Style */
.Coldbox_group_PopupBG {
    position: absolute;
    background: rgba(0,0,0,.3);
    width: 100vw;
    height: 100vh;
    -webkit-animation: GDBG_PopupAppear .6s ease-out 0s 1;
    animation: GDBG_PopupAppear .6s ease-out 0s 1;
}
.Coldbox_group_PopupMain {
    position: fixed;
    width: 50rem;
    height: 30rem;
    left: calc((100vw - 50rem)/2);
    top: calc((100vh - 30rem)/2);
    border-radius: .5rem;
    -webkit-animation: GD_PopupAppear .6s ease-out 0s 1;
    animation: GD_PopupAppear .6s ease-out 0s 1;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    color: #fff;
    padding: 1rem;
    z-index: 1000;
}
.Coldbox_group_PopupTitle {
    font-size: 1.5rem;
    letter-spacing: 2px;
}
.Coldbox_group_PopupcloseBtn {
    position: absolute;
    right: .7rem;
    top: .7rem;
    cursor: pointer;
}
.Coldbox_group_Popup_input {
    width: 30rem;
    margin-bottom: .5rem;
    font-size: 1.2rem;
    padding: .5rem .5rem .5rem 1rem;
    border-radius: .5rem;
    transition: all var(--css-duration);
    background: transparent;
    border-width: .5px;
    border-color: rgba(0,0,0,.4);
    background: linear-gradient(90deg,rgba(0,0,0,.1),rgba(0,0,0,.2),rgba(0,0,0,.1));
    color: #fff;
}
.Coldbox_group_Popup_input:focus {
    background: rgba(0,0,0,.6);
}
.Coldbox_group_Popup_Btns {
    display: flex;
    justify-content: space-between;
    align-self: flex-end;

    min-width: 20rem;
}
.Coldbox_group_Popup_DelBtns {
    width: 10rem;
    height: 2.3rem;
    margin-left: 1rem;
    border-radius: .5rem;
    border-width: 1px;
    border-color: hsla(0,0%,100%,.3);
    text-align: center;
    background: linear-gradient(90deg,rgba(255,0,0,.3),rgba(255,0,0,.3));
    color: #fff;
    font-size: 1.2rem;
    padding-top: .4rem;
    cursor: pointer;
    transition: all var(--css-duration);
}
.Coldbox_group_Popup_DelBtns:hover {
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;    
}
.Coldbox_group_Popup_SubmitBtns {
    width: 10rem;
    height: 2.3rem;
    margin-left: 1rem;
    border-radius: .5rem;
    border-width: 1px;
    border-color: hsla(0,0%,100%,.3);
    text-align: center;
    background: var(--colortheme1);
    color: #fff;
    font-size: 1.2rem;
    padding-top: .4rem;
    cursor: pointer;
    transition: all var(--css-duration);
}
.Coldbox_group_Popup_SubmitBtns:hover {
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}
.Coldbox_group_Popup_CancelBtns {
    width: 10rem;
    height: 2.3rem;
    margin-left: 1rem;
    border-radius: .5rem;
    text-align: center;
    background: transparent;
    border-width: .5px;
    border-color: hsla(0,0%,100%,.4);
    color: #fff;
    font-size: 1.2rem;
    padding-top: .4rem;
    cursor: pointer;
    transition: all var(--css-duration);
    margin-left: .5rem;
}
.Coldbox_group_Popup_CancelBtns:hover {
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}

.Coldbox_group_Popup_grouptable {
    display: flex;
    justify-content: space-between;
    height: 100%;
}
.Coldbox_group_Popup_grouptable .colL, .Coldbox_group_Popup_grouptable .ColR {
    width: 40%;
    height: calc(100% - 2rem);
    margin: .5rem;
    text-align: center;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Coldbox_group_Popup_grouptable .ColM {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.Coldbox_group_Popup_grouptable .ColM .addBtn, .Coldbox_group_Popup_grouptable .ColM .removeBtn {
    margin-bottom: 1rem;
    width: 3rem;
    padding: .3rem;
    text-align: center;

    background: rgba(0, 0, 0, 0.2);
    border-width: 2px;
    border-color: rgba(255, 255, 255, .2);
    border-radius: .5rem;

    cursor: pointer;
    transition: all var(--css-duration);
}
.Coldbox_group_Popup_grouptable .ColM .addBtn:hover, .Coldbox_group_Popup_grouptable .ColM .removeBtn:hover {
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}
.grouplist_container {
    background: rgba(0, 0, 0, 0.2);
    height: 100%;
    font-size: 1.3rem;
    color: #fff;
    padding: 1rem;
    border-radius: 1rem;

    overflow-y: auto;
}
.grouplist_container .groupItem {
    margin-bottom: .5rem;
    transition: all var(--css-duration);
}
.grouplist_container .groupItem:active {
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}