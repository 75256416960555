.Install_Main {
    // background: url(../../assets/images/hong-kong-night.jpg), linear-gradient(to right, rgba(0, 33, 83, 0.8), rgba(0, 0, 0, 0.6) 100%);
    background: linear-gradient(to bottom left, #170e13, #7a7adb);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply;

    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
        color: white;
        width: 50%;
        max-width: 25rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 1rem;
        margin: auto;

        .title {
            font-size: 3rem;
        }
        .subtitle {
            font-size: 1rem;
        }
        .btns {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .btn {
                width: 8rem;
                display: flex;
                justify-content: space-between;
                padding: 10px;

                border: 1px solid;
                cursor: pointer;
            }
        }

        .info {
            width: 8rem;
            padding: 10px;
            cursor: pointer;
        }
    }
    .right {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .phone {
            background: rgb(54, 54, 54);
            box-shadow: 0 1px 8px 0 rgba(255, 255, 255, 0.308) inset,
            5px 10px 25px 0 rgb(0, 34, 49);
            padding: 10px;
            border-radius: 2rem;

            .screen {
                width: 16rem;
                aspect-ratio: 0.5;
                border-radius: 1.2rem;
    
                background: url(./appscreenshoot.jpeg);
                background-position: center;
                background-size: cover;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .Install_Main {
        position: fixed;
        top: 3.5rem;
        left: 0;
        width: 100%;
        height: 100%;

        flex-direction: column;
        justify-content: flex-start;
        gap: 2rem;

        .left {
            margin: 0;
            margin-top: 2rem;
        }
    }
}