.Weather {
    width: 100%;
    height: 100%;
    background: lightblue;
    color: white;
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 30%);
    border-radius: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.WeatherEmptySpace {
    height: 100%;
}
.Weather_loc {
    font-size: 2rem;
    width: 100%;
    padding-left: 2rem;
    text-shadow: 0px 8px 4px rgba(0, 0, 0, 0.2);
}

.Weather_botRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 1rem;
    padding-left: 2rem;
}
.Weather_temp {
    font-size: 3.5rem;
    text-shadow: 0px 8px 4px rgba(0, 0, 0, 0.2);
}
.Weather_card {
    color: black;
    background: white;
    border: solid white 2px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3) inset;
    width: 5rem;
    height: 4rem;
    border-radius: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;
}
.Weather_card_top {
    font-size: 0.8rem;
    opacity: 0.5;
}
.Weather_card_bot {
    font-size: 1.5rem;
    opacity: 0.7;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Weather_card_bot sub {
    font-size: 0.8rem;
}


.Weather.Sunny {
    background: rgb(252, 139, 0);
}
.Weather.Cloudy {
    background: rgb(103, 197, 255);
}
.Weather.Rainny {
    background: rgb(0, 108, 176);
}
.Weather.wind {
    background: rgb(128, 167, 88);
}
.Weather.dark {
    background: rgb(0, 19, 67);
}