.CBEvent {
    width: calc(100% - 3rem);
    height: calc(100% - 4rem);
    transition: all var(--css-duration);
    padding: 1rem;
    border-radius: .3rem;
    margin: auto auto 0;
    /* background: linear-gradient(180deg,var(--maindiv-color2),var(--maindiv-color));
    -webkit-filter: drop-shadow(0 10px 15px rgba(0,0,0,.3));
    filter: drop-shadow(0 10px 15px rgba(0,0,0,.3)); */
    -webkit-animation: draglayoutOnload 0.5s ease-out;
    animation: draglayoutOnload 0.5s ease-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.CBEvent_table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;

    overflow: auto;
}

.CBEvent_bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.CBE_norecord {
    margin: auto;
    font-size: 2rem;
    letter-spacing: 2px;

    color: grey;
}