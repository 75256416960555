.Toilet_select {
    background: white;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    padding: 1rem;
    border-radius: 0.5rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.Toilet_select_btn {
    filter: drop-shadow(2px 3px 0px rgba(0, 0, 0, 0.2));
    min-width: 5rem;
    min-height: 5rem;
    font-size: 3rem;

    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    transition: all 0.5s ease-in-out;
    z-index: 100;
}

.Toilet_select_btn.highlight {
    background: transparent;
    color: white;
}
.Toilet_select_btn.male {
    color: rgb(73, 191, 181);
}
.Toilet_select_btn.female {
    color: rgb(246, 159, 146);
}
.Toilet_select_btn.disable {
    color: rgb(109, 206, 245);
}

.Toilet_select_bg {
    min-width: 5rem;
    min-height: 5rem;
    border-radius: 0.3rem;

    position: absolute;
    transition: all 0.7s cubic-bezier(0.4,-0.18, 0.32, 1.43);
}
.Toilet_select_bg.item1 {
    /* margin-right: 12rem; */
    transform: translateX(calc(-5rem - 1rem));
}
/* .Toilet_select_bg.item2 {} */
.Toilet_select_bg.item3 {
    /* margin-left: 12rem; */
    transform: translateX(calc(5rem + 1rem));
}

#Toilet_select_disable {
    height: 5rem;
    width: 5rem;

    background: url(../../../assets/images/toilet/disable.png);
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.5s ease-in-out;
    filter: invert(0.5);
}
.Toilet_select_btn.highlight #Toilet_select_disable {
    filter: invert(1);
}