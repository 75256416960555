.cardContainer {
    width: 100%;
    height: 100%;
    box-shadow: 0 15px 10px 0 rgb(0 0 0 / 5%), 0 5px 5px 0 rgb(0 0 0 / 10%);
    background: var(--cardbodybackground);
    border-radius: .3rem;
    animation: floor4readingcardsOnload 1s ease-out;

    display: flex;
    flex-direction: column;
}
.cardContainer .head {
    height: 2rem;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    color: var(--cardtitletext);
    background: var(--cardtitle);
    box-shadow: 0 15px 10px 0 rgb(0 0 0 / 5%), 0 5px 5px 0 rgb(0 0 0 / 10%);
    display: flex;
    align-items: center;
    grid-gap: .5rem;
    gap: .5rem;
}
.cardContainer .head .icon {
    margin-left: 1rem;
}
.cardContainer .head .txt {
    letter-spacing: 2px;
}
.cardContainer .body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    min-width: 0;
    min-height: 0;
}