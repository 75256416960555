.Coldbox_GDPage {
    width: calc(100% - 4rem);
    height: calc(100% - 5rem);
    transition: all var(--css-duration);
    padding: 1rem;
    border-radius: .3rem;
    margin: auto auto 0;

    background: linear-gradient(180deg,var(--maindiv-color2),var(--maindiv-color));
    -webkit-filter: drop-shadow(0 10px 15px rgba(0,0,0,.3));
    filter: drop-shadow(0 10px 15px rgba(0,0,0,.3));
    -webkit-animation: draglayoutOnload 0.5s ease-out;
    animation: draglayoutOnload 0.5s ease-out;

    display: flex;
    flex-direction: column;
}

.Coldbox_GD_TableMain {
    height: 100%;
    max-height: calc(100vh - 15rem);
    margin-top: 1rem;
    border-radius: .5rem;
    
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.224);
    background: var(--cardbodybackground);
}