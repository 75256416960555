
.caption {
    padding: .5rem .5rem .5rem 1rem;
    letter-spacing: 1px;
    /*background: hsla(0,0%,50.2%,.3);*/
    background-image: linear-gradient(white, gray);
    color: var(--optiontext);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.main_board{
    width: calc(100%);
    height: calc(100% - 4rem);
}
.caption .right_top {
    margin-left: 42rem;
}

.dashboard {
    display: flex;
    flex-direction: row;
    background-color: rgb(224, 219, 213);
    flex-wrap: nowrap;
}

.dashboard .left_panel {
    flex: 0 0 5%;
    /*background-color: rgb(219, 180, 213);*/
    /*background-color: rgb(99, 89, 89);*/
    display: flex;
}

.dashboard .right_panel {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.tableTitle{
    margin-top: 1.5rem;
    padding: .5rem;
    border-radius: .5rem;
    display: flex;
    flex-direction: row;
    background-color: rgb(224, 219, 213);
    flex-wrap: nowrap;
}

.ColdboxIndi_bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem;
}