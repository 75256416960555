.AT_popupCancelBtn
{
    opacity: 0.7;
}
.AT_popupCancelBtn:hover
{
    opacity: 1;
}

.AT_checkboxRowContainer
{
    display: flex;
    flex-direction: row;
}

.AT_popupAlarmMsg
{
    background: rgba(0, 0, 0, 0.2);
    color: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    width: 100%;
    height: 6rem;
    transition: all 0.5s;
}
.AT_popupAlarmMsg:focus
{
    background: rgb(255, 255, 255, 0.3);
}

.AT_tableDeleteBtn
{
    background: rgba(255, 0, 0, 0.4);
    color: white;
    width: 100%;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    text-align: center;
    border-radius: 0.3rem;

    transition: all var(--css-duration);
    cursor: pointer;
}
.AT_tableDeleteBtn:hover
{
    box-shadow: 0 -50px 0 0 rgba(0, 0, 0, 0.322) inset;
    background: transparent;
}

.AT_popupInput_priorityTxt {
    align-self: center;
}

.AT_popupSendOptionContainer {
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0.5rem;
    border-radius: 0.5rem;
    color: white;
}
.AT_popupSendEmailTxt {
    align-self: center;
}
.AT_popupSendSMSTxt {
    align-self: center;
}
.AT_popupSendEmailContainer {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-self: center;
}
.AT_popupSendSMSContainer {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-self: center;
}
.AT_popupSendEmailItem {
    padding: 0.5rem;
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all var(--css-duration);
    border-radius: 0.3rem;
}
.AT_popupSendEmailItem:hover {
    box-shadow: 0px -35px 0 0 rgb(255, 255, 255, 0.3) inset;
}
.AT_popupSendSMSItem {
    padding: 0.5rem;
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all var(--css-duration);
    border-radius: 0.3rem;
}
.AT_popupSendSMSItem:hover{
    box-shadow: 0px -35px 0 0 rgb(255, 255, 255, 0.3) inset;
}




.SendOptionOn {
    /* box-shadow: 0px 5px 10px 0px var(--focuscardbackground); */
    background: var(--focuscardbackground);
}