.Planner {
    width: calc(100% - 4rem);
    height: calc(100% - 5rem);
    transition: all var(--css-duration);
    padding: 1rem;
    border-radius: .3rem;
    margin: auto auto 0;
    /* background: linear-gradient(180deg,var(--maindiv-color2),var(--maindiv-color));
    -webkit-filter: drop-shadow(0 10px 15px rgba(0,0,0,.3));
    filter: drop-shadow(0 10px 15px rgba(0,0,0,.3)); */
    -webkit-animation: draglayoutOnload 0.5s ease-out;
    animation: draglayoutOnload 0.5s ease-out;
    overflow: scroll;
}

.Planner_table {
    display: inline-block;
    /* height: 100%; */
    /* width: auto; */
    width: 100%;
}

.Planner_bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
