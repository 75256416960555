/* active icon highlight css */
.navbarContainer {
    width: 100%;
}
.active {
    background-color: #4CAF5000;
    /*box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff;
    border-radius: 50%;*/
      
    outline: none;
}
  
/* icon sizing */
.nav_icons {
    height: 1.4rem;
    width: auto;
}
  
.icon_img {
    margin-left: 1.45rem;
    margin-top: 1.7rem;
    position: absolute;
    color: grey;
}
  
.icon_image_atal {
    width: 4.8rem;
    padding-top: 0.65rem;
    padding-bottom: 0.65rem;
    margin-left: 4rem;
}
  
.user_image_atal {
    margin: 0 auto;
    height: 100%;
    width: 100%;
    margin-top: 0rem;
    margin-left: 0rem;
}
.user_image_atal.noImage {
    background: linear-gradient(to bottom, rgba(0,0,0,0.05), rgba(0,0,0,0.15));
    display: flex;
    justify-content: center;
    align-items: center;
}
.user_image_atal.noImage .fa {
    color: var(--focuscardbackground);
}
.icon_image_atalcoldbox {
    width: auto;
    height: 4rem;
    margin-left: 4rem;
}
  
.user_image_div {
    width: 1.6rem;
    height: 1.6rem;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    background-color:white;
    border-width: 1px;
    border-color: white;
    margin-left: 1rem;
    margin-right: 1rem;

    cursor: pointer;
    /*transition: all var(--css-duration);*/
}
  
.highlightNav {
    border-radius: 0;
    /*border-color: var(--focuscardbackground);*/
    box-shadow: 0px 0px 0 calc((1vw + 1.25rem)/2) var(--colortheme2);
}

.username_p {
    /*padding-left: 0.5rem;*/
    font-size: 1rem;
    /*margin-top: 0.25rem;*/
    padding: 0.2rem;
    margin-left: 1rem;
    cursor: pointer;
}
  
.date_p {
    margin-top: 0.19rem;
    letter-spacing: 0rem;
    font-size: 1.5rem;
    margin-left: 3rem;
}
  
.time_p {
    /*padding-top: 0.1rem;*/
    margin-left: 0.8rem;
    margin-right: 1rem;
    /*font-family:'Courier New';*/
    font-weight:1px;
    letter-spacing: 0rem;
    font-size: 1.5rem;
}
  
.nav_icon {
    /*padding-top: 1.1vw*1.1vw;*/
    margin-left: 1.5vw;
    font-size: 1.2rem;
    /*font: normal normal normal 1.5rem/1 FontAwesome;*/
    margin-left: 1.3rem;
    margin-right: 1.3rem;
    /* remove active or focus blue outline */
    outline: none;

    color: white;
}
  
.menu_div {
    position: absolute;
    width: 6%;
    margin-top: 1.5rem;
    font-size: 2rem;
}
  
.nav_list {
    /*margin-right: 2rem;*/
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.25rem;
}
  
/* table level popup */
#overlay_div2 {
    position: absolute;
}
  
.chooselevelclosebtn {
    color: white;
    position: relative;
    margin-right: 0.5rem;
    /*right: 0.5rem;
    top: 0.5rem;*/
    border-width: 0px;
    padding: 0.3rem;
    border-radius: 0.3rem;
    cursor: pointer;
}
  
.chooselevelclosebtn:hover {
    background: transparent;
}
  
.choose_level_title {
    cursor: pointer;
}
  
.overlap_title {
    /*font-family: 'Exo', Thin;*/
    /*margin-left: 1.5rem;
    margin-top: 1.8rem;
    font-size: 1.9rem;
    letter-spacing: 0rem;
    transition: all 0.5s;
    z-index: 100;
    color: white;
    background-color: transparent;*/
}
  
.overlap_title:hover {
    /*text-shadow:
        0 0 10px #fff,
        0 0 20px #CCC,
        0 0 30px #AAA,
        0 0 80px #888;*/
}
  
  .oeverlap_paragraph
  {
      margin-left: 1rem;
      margin-top: 0.7rem;
      font-size: 0.8rem;
      outline: none;
      color: white;
      transition: all 0.5s;
      z-index: 1;
  }
  
  .oeverlap_paragraph:hover
  {
      text-shadow:
          0 0 5px #fff,
          0 0 20px #CCC;
      color: white;
  }
  
  .oeverlap_paragraph_icon
  {
      margin-left: 1.6rem;
      margin-top: 0.7rem;
      font-size: 0.9rem;
  }
  
  .everlay_div2_title
  {
      margin-top: 1.8rem;
      margin-left: 1.5rem;
      font-size: 0.95rem;
  }
  
  .everlay_div2_levels
  {
      margin-top: 0.6rem;
      margin-left: 1.33rem;
      width: 24rem;
      font-size: 0.95rem;
      font-weight: 1;
  }
  
  .overlay_div2 {      
    transition: all 0.5s ease-in;
  }

  /* alert box style */
  .alertbox_outside 
  {
      background: transparent;
      width: 40rem;
      height: auto;
      position: absolute;
      left: calc(50vw - 20rem);
      top: calc(50vh - 15rem);
      box-shadow: 1px 1px 15px grey;
      display: none;
  }
  .alertbox_icon 
  {
      text-align: center;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      background: white;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      color: white;
      font-size: 2rem;
  }
  .alertlogo
  {
      border-radius: 50%;
      background: orange;
      width: 6rem;
      height: 6rem;
      margin: auto;
      font-size: 5rem;;
  }
  .headmessage
  {
      padding: 1rem;
      background: white;
      color: black;
      font-weight: 700;
      font-size: 2.5rem;
      text-align: center;
  }
  .alertbox_msg
  {
      text-align: left;
      font-size: 1.5rem;
      background: white;
      color: red;
      padding: 3rem;
  }
  .alertbox_bottom
  {
      padding: 1rem;
      background: white;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
  }
  .confirm_btn
  {
      padding: 0.5rem;
      padding-left: 2rem;
      padding-right: 2rem;
      background: white;
      color:red;
      margin: 0.1rem;
      margin-left: 1rem;
      margin-right: 1rem;
      border-radius: 0.5rem;
      border-width: 1px;
      border-color: red;
      font-size: 1.5rem;
      outline: 0;
  }
  .confirm_btn:hover
  {
      background: red;
      color: white;
  }
  
  .cancel_btn
  {
      padding: 0.5rem;
      padding-left: 2rem;
      padding-right: 2rem;
      background: transparent;
      color:black;
      border-width: 1px;
      border-color: black;
      margin: 0.1rem;
      margin-left: 1rem;
      margin-right: 1rem;
      border-radius: 0.5rem;
      font-size: 1.5rem;
      outline: 0;
  }
  
  .cancel_btn:hover
  {
      color: white;
      background: black;
  }
  
  
  .Ok_btn
  {
      padding: 0.5rem;
      padding-left: 2rem;
      padding-right: 2rem;
      background: transparent;
      color:black;
      border-width: 1px;
      border-color: black;
      margin: 0.1rem;
      margin-left: 1rem;
      margin-right: 1rem;
      border-radius: 0.5rem;
      font-size: 1.5rem;
      outline: 0;
  }
  
  .Ok_btn:hover
  {
      color: white;
      background: black;
  }

  .nav-bar
  {
      background: var(--colortheme1);
      box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.7);
      transition: all var(--css-duration);
      animation: navbarOnload 1s ease-out;
  }
  .nav-bar:hover {
    box-shadow: 0 0px 30px 0 rgba(0, 0, 0, 1);
  }
  @keyframes navbarOnload {
    0% {
        transform: translateY(-10px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
  }

  .showlevel
  {
      /*display:block;*/
      top: 20vh;
      left: 0vw;
  }

  .hidelevel
  {
      /*display:none;*/
      top: 20vh;
      left: -30vw;
  }

  .navbar_droplist_div
  {
      position: absolute;
      background: var(--colortheme1);
      color: var(--focuscardtext);
      z-index: 10;
      width: auto;

      display:flex;
      flex-direction: column;
  }

  .navbar_droplist_hide
  {
      display:none;
  }

  .navbar_outside_droplist_div
  {
      background: transparent;
  }

  .navbar_droplist_cal_div
  {
      visibility: hidden;
  }

  .navbar_droplist_item
  {
      color: var(--focuscardtext);
      background:red;
      cursor: pointer;
  }

  .UserDroplist_select {
    font-family: 'FontAwesome', "Segoe UI";
    color: white;
    background: var(--colortheme1);
    cursor: pointer;
  }

  .UserDroplist_select:focus {
      outline: 0;
  }

  .numbercountbackgroundicon {
    position: absolute;
    font-size: 1em;
    color: red;
    margin-left: 0rem;
  }
  .NotiBGEffect {
    width: 3rem;
    height: 3rem;
    background: red;
    border-radius: 9999px;
    position: absolute;
    top: 0.2rem;
    margin-left: 1rem;
    animation: 3s ease-out blinkAlarmAnimate infinite;
  }
  @keyframes blinkAlarmAnimate {
      0% {
        transform: scale(0);
        opacity: 0.8;
      }
      100% {
        transform: scale(2);
        opacity: 0;
      }
  }
  span.num {
    position: absolute;
    font-size: 0.9em;
    color: #fff;
    margin-left: 0.25rem;
  }

  /* short drop down notification list */
  .navbar_notification_div {
    cursor: pointer;
    transition: all var(--css-duration);
  }
  .navbar_notification_div:hover {
      /*box-shadow: 0 -80px 0px 0px var(--focuscardbackground) inset;*/
      opacity: 0.8;
  }

  .navbar_popup_notification {
    width: 23.8rem;

    background: var(--colortheme2);
    position: fixed;
    right: 0;

    z-index: 10;
    transition: all var(--css-duration);
  }

  .navbar_popup_notification_item {
    /*padding-left: 0;*/
    background: rgba(255, 0, 1, 0);
    margin-top: 0.3rem;
    margin-right: 2px;
    transition: all var(--css-duration);

    border-radius: 0.3rem;

    box-shadow: 2px 1px 5px 0 rgba(0, 0, 0, .4);
  }

  .navbar_notification_viewbutton {
      color: white;
      background: rgba(0, 0, 0, 0.3);
      padding: 0.5rem;
      width: 100%;
      border-radius:0.5rem;
      margin-top: 1rem;
      padding-top:0.8rem;
      padding-bottom: 0.8rem;

      transition: all 0.5s;
      cursor: pointer;
  }

  .navbar_notification_viewbutton:hover {
    background: rgba(0, 0, 0, 0);
    box-shadow: 0 -50px 0 0 rgba(0, 0, 0, 0.5) inset;
  }

  .navbar_notification_clearbutton {
    color: black;
    background: rgba(0, 0, 0, 0);
    padding: 0.5rem;
    width: 100%;
    margin-top: 0.5rem;
    border-width: 1px;
    border-color: black;
    border-radius: 0.5rem;

    transition: all 0.5s;
    cursor: pointer;
  }

  .navbar_notification_clearbutton:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  .nav_alarm_icon {
    background:var(--colortheme1);
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
    /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.2)); */
    /* border-radius: 0; */
    min-width: 2.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .nav_alarm_icon * {
      font-size: 1rem;
  }

  .hide_notilist {
    right: 5vw;
    transform: rotateX(60deg);
    transform: scaleY(0);
    opacity: 0;
  }

  .show_notilist {
    right: 5vw;
    transform: rotateX(0deg);
    opacity: 1;
    transform: scaleY(1);
  }

  .nav_alarm_2rowbody {
    color: white;
    margin:0.3rem;
    display:flex;
    flex-direction: column;
    /*width: 100%;*/
  }

  .navbar_alarm_name {
    font-size: 1.1rem;
  }
  .navbar_alarm_location
  {
      font-size: 0.8rem;
  }

  .navbar_alarm_datetime {
    font-size: 0.7rem;
    letter-spacing: 1px;
  }

  /* react-modal style animation */
.ReactModal__Overlay {
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
    transform: translateX(0);
}

.ReactModal__Overlay--before-close{
    opacity: 1;
    transform: translateX(-50%);
}

.atal_logo {
    cursor: pointer;
}

.highlightNav2 {
    background: var(--colortheme2);
    color: grey;
    box-shadow: 0px 0px 0 calc((1vw + 1.25rem)/2) var(--colortheme2);
    height: 100%;
}

.NavbarMenuIcon {
    color: white;
    background: var(--focuscardbackground);
    position: relative;
    left: 0;
    height: 3.7rem;
    font-size: 1.2rem;
    padding-left: 1.4rem;
    padding-right: 1.4rem;

    transition: all var(--css-duration);
    box-shadow: 0 0px 10px 0px var(--focuscardbackground), 0 0px 20px 0px var(--focuscardbackground);
}

.NavbarMenuIcon:hover {
    /*background: var(--colortheme1);*/
    box-shadow: 0 0px 20px 0px var(--focuscardbackground), 0 0px 50px 0px var(--focuscardbackground);
}
.NavbarMenuIcon:focus {
    outline: none;
}

.HideAlarmCount
{
    display: none;
    opacity: 0;
}
.resizeBackgroundcircle
{
    margin-left: 0.25rem;
    transform: scale(1.5);
}

.AlarmList1 {
    color: rgb(216, 216, 216);
    background: linear-gradient(to bottom, rgb(255, 0, 0, .7), rgba(255, 0, 0, .6));
    border-radius: .5rem;

    transition: all 0.5s;
}

.AlarmList1:hover {   
    color: white;
    opacity: 0.8;
    /* background: linear-gradient(to bottom, rgb(255, 0, 0), rgba(255, 0, 0, .7)); */
}

.AlarmList2
{
    color: rgb(255, 255, 255);
    background:rgb(211, 137, 0);
}

.AlarmList2:hover
{   
    color:rgb(255, 166, 0);
    background:rgb(122, 122, 122);
}

.AlarmList3
{
    color: rgb(73, 255, 73);
    background:rgb(2, 187, 2);
    background: linear-gradient(to bottom, rgb(0, 200, 0), rgba(0, 170, 0, 1));
}

.AlarmList3:hover
{   
    color: rgb(73, 255, 73);
    background:rgb(122, 122, 122);
}

.AlarmList_default
{
    color: rgb(182, 182, 182);
    background:rgb(182, 182, 182);
}