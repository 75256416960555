.Toilet_admin_occ {
    width: 100%;
    height: 100%;
    background: white;
    box-shadow: 0 5px 10px 0px rgba(0,0,0,0.3);
    padding: 1rem;
    border-radius: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.Toilet_admin_occ .head {
    font-size: 2rem;
    letter-spacing: 1px;
    opacity: 0.4;
}


.Toilet_admin_occ .chartitem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    height: 100%;
}

.Toilet_admin_occ .chartitem .title {
    text-align: center;
    opacity: 0.7;
    letter-spacing: 2px;
}