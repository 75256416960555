.Lift_graphDirection
{
    /* background: rgba(255, 255, 255, .3); */
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2); */
    /* background: linear-gradient(90deg,hsla(0,0%,100%,.329),hsla(0,0%,100%,.425)); */
    background: var(--cardbodybackground);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
}
.Lift_graphDirection *
{
    margin: 0.5rem;
}
.Lift_graphTitle {
    color: var(--optiontext);
}

/* Lift Range detect css */
.Lift_graph_rangeMoveContainer {
    /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)); */
    height: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
/* .Lift_graph_rangeMoveLiftItem {
    background: url('./../../../assets/images/LiftBody.jpg');
    background-size: cover;
    height: 15rem;
    width: 10rem;
    transition: all var(--css-duration);

    margin-top: auto;
    margin-bottom: auto;

    display: none;
} */
.Lift_graph_rangeStatusDisplay {
    font-size: 1.5rem;
    color: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    width: calc(100% - 1rem);
    text-align: center;

    background: rgba(0, 0, 0, 0.2);
    transition: all var(--css-duration);
    /* box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3); */
    box-shadow: 0 10px 25px -10px  rgba(0, 0, 0, 0.5);
}
.LiftRangeHighlight {
    background: var(--focuscardbackground);
    box-shadow: 0 10px 25px -10px var(--focuscardbackground);
}
.LiftRangeHighlightRed {
    background: rgba(255, 0, 0, 0.7);
    box-shadow: 0 10px 25px -10px rgba(255, 0, 0, 0.7);
}

.LiftRangeBotOutRange {
    /*margin-top: calc(11rem);*/
    /*box-shadow: 0 0 10px 0px var(--focuscardbackground);*/
    box-shadow: 0 -3rem 0 0 rgba(255, 0, 0, 0.7) inset;
}
.LiftRangeTopOutRange {
    /*margin-top: 6.5rem;*/
    /*box-shadow: 0 0 10px 0px var(--focuscardbackground);*/
    box-shadow: 0 3rem 0 0 rgba(255, 0, 0, 0.7) inset;
}
/* Lift Range detect end */