.LiftDrStat_main {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
}
.LiftDrStat_search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;

    padding: 0.5rem;
}
.LiftDrStat_searchTitle {
    font-size: 1rem;
}

.LiftDrStat_tab {
    background: var(--colortheme1);
    color: white;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
    padding: 0.3rem;
    cursor: pointer;
    transition: all var(--css-duration);
}
.LiftDrStat_tab.highlight {
    background: var(--focuscardbackground);
    color: white;
}

.LiftDrStat_main .CloseBtn {
    position: absolute;
    right: 10px;
    font-size: 1.5rem;

    z-index: 500;
    cursor: pointer;
}