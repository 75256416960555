.ResetPwd_Container
{
    background: rgb(243, 219, 219);

    position: fixed;
    bottom: 0vh;
    width: 100vw;
    height: 100vh;
    left: 0vw;

    padding: 1rem;
    border-radius: 0rem;

    background: linear-gradient(80deg,#190a0544,#000000ce),/*, #87000000),*/
    url(./assets/images/hong-kong-night.jpg) no-repeat;
    background-size: cover;
    transition: all 1s;


    font-size: 2rem;
    color: white;
    text-align: center;
    display:flex;
    flex-direction: column;
    padding-top: 4rem;
}
.ResetPwd_innerContainer
{
    background: rgb(255, 255, 255, 0.3);
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: 1rem;
    width: 50vw;
    margin:auto;
    margin-top: 20vh;
}
.ResetPwd_Input
{
    background: rgba(100, 100, 100, 0.4);
    color: white;
    width: 30rem;
    padding: 1rem;
    padding-left: 2rem;
    font-size: 2rem;

    border-radius: 999rem;
    border-width: 1px;
    border-color: white;

    margin:auto;
    margin-bottom: 1.5rem;
    transition: all var(--css-duration);
}
.ResetPwd_Input:focus
{
    box-shadow: 0 -150px 0 0 rgb(255, 255, 255, 0.1) inset;
    background: transparent;
}

.ResetPwd_submitBtn
{
    background: var(--focuscardbackground);
    transition: all 1s;
    width: 30rem;
    padding: 1rem;
    border-radius: 999rem;
    font-size: 2rem;
    margin:auto;
}
.ResetPwd_submitBtn:hover
{
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 -80px 0 0 rgba(255, 255, 255, 0.4) inset;
    cursor: pointer;
}

.ResetePwd_backBtn
{
    background: rgb(255, 255, 255);
    color: black;
    font-size: 1rem;
    padding: 1rem;
    border-radius: 50%;

    position: absolute;
    top: 2rem;
    left: 2rem;

    cursor: pointer;
    transition: all var(--css-duration);
}

.ResetePwd_backBtn:hover
{
    box-shadow: 0 -80px 0 0 var(--focuscardbackground) inset;
    color: white;
}

.ResetPwd_PopupBG
{
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all var(--css-duration);
}

.ResetPwd_PopupContainer
{
    position: absolute;
    height: 30vh;
    width: 50vw;
    top: 30vh;
    left: 25vw;
    background: rgba(0, 0, 0, 0.8);
    padding: 1rem;
    border-radius: 1rem;
    transition: all var(--css-duration);
}
.ResetPwd_PopupMsg
{
    text-align: center;
    font-size: 2rem;
    margin-top: 2rem;
    color: white;
}
.ResetPwd_PopupCloseBtn
{
    font-size: 1.5rem;
    background: grey;
    color: white;
    width: 10rem;
    text-align: center;
    padding: 0.3rem;
    border-radius: 0.3rem;
    position: absolute;
    bottom: 2rem;
    left: calc(25vw - 5rem);
    cursor: pointer;
    transition: all var(--css-duration);
}
.ResetPwd_PopupCloseBtn:hover
{
    box-shadow: 0 -50px 0 0 var(--focuscardbackground) inset;
}

.hideResetPopup
{
    display:none;
    top: -10vh;
}