.Coldbox_delete_group_PopupMain {
    position: fixed;
    width: 25rem;
    height: 15rem;
    left: calc((100vw - 25rem)/2);
    top: calc((100vh - 15rem)/2);
    border-radius: .5rem;
    -webkit-animation: GD_PopupAppear .3s ease-out 0s 1;
    animation: GD_PopupAppear .3s ease-out 0s 1;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    color: #fff;
    padding: 1rem;
    z-index: 1000;
}

.Coldbox_group_PopupTitle {
    font-size: 1.5rem;
    letter-spacing: 2px;
}
.Coldbox_group_PopupcloseBtn {
    position: absolute;
    right: .7rem;
    top: .7rem;
    cursor: pointer;
}
.Coldbox_delete_group_Popup_input {
    width: 20rem;
    margin-bottom: .5rem;
    font-size: 1.2rem;
    padding: .5rem .5rem .5rem 1rem;
    border-radius: .5rem;
    transition: all var(--css-duration);
    background: transparent;
    border-width: .5px;
    border-color: rgba(0,0,0,.4);
    background: linear-gradient(90deg,rgba(0,0,0,.1),rgba(0,0,0,.2),rgba(0,0,0,.1));
    color: #fff;
}
.Coldbox_delete_group_Popup_input:focus {
    background: rgba(0,0,0,.6);
}
.Coldbox_group_Popup_Btns {
    display: flex;
    justify-content: space-between;
    align-self: flex-end;

    min-width: 20rem;
}