.MainWithImage_Container {
    /* width: 100%;
    height: 100%;
    background: grey; */
    width: 88%;
    height: calc(100vh - 2.5vw - 1.4rem - 1.2rem);;
    position: absolute;

    background-position: center;
    background-size: cover;
    /* filter: blur(4px); */

    display:flex;
    flex-direction: row;
    justify-content: space-between;
    transition: var(--css-duration);

    background: linear-gradient(to bottom, transparent, transparent, rgba(0, 0, 0, 1));
    border-radius: 1rem;
}
.MainWithImage_overlapBG {
    width: 88%;
    height: calc(100vh - 2.5vw - 1.4rem - 1.2rem);;
    position: absolute;

    box-shadow: 0 5px 25px 0px rgba(0, 0, 0, 0.6);
    border-radius: 1rem;
    
    transition: background-image 1.5s ease-in-out;
    background-position: bottom;
    /* onload animate */
    animation: 1.5s ease-out fadeInWithYMove;
}
@keyframes fadeInWithYMove {
    0% {
      opacity: 0;
      transform: translateY(5px);
    }
    100% {
      opacity: 1;
    }
}

.MainWithImage_CurrentSiteDisplay {
    background: var(--colortheme1);
    color: var(--cardtitletext);
    width: 25rem;
    height: 20rem;
    text-align: center;
    margin-top: 3rem;
    margin-left: 2rem;
    border-radius: 1rem;

    box-shadow: 0px 3px 20px 0px rgba(255, 255, 255, 0.7);
    font-size: 1.5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: cover;
    background-position: center;
    transition: var(--css-duration);

    transition: background-image .5s ease-in-out;
    /* onload animate */
    animation: .5s ease-out fadeInFromLeft;
}
@keyframes fadeInFromLeft {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
    }
}
.MainWithImage_CurrentSiteTitle {
    background: rgba(0, 0, 0, 0.2);
    /* padding: 0.3rem;
    border-radius: 0.5rem;
    margin: auto; */
    
    /* margin-top: 0.5rem; */
    backdrop-filter: blur(7px);
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    padding: 0.5rem;
    padding-top: 1rem;
}
.MainWithImage_BtnRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.1rem;
}
.MainWithImage_BtnStatistic {
    background: var(--colortheme1);
    padding: 0.5rem;
    width: 12.5rem;
    border-bottom-left-radius: 1rem;
    cursor: pointer;
    opacity: 0.8;
    transition: var(--css-duration);
    letter-spacing: 2px;
}
.MainWithImage_BtnStatistic:hover {
    opacity: 1;
    box-shadow: 0 -40px 0 0 rgba(255, 255, 255, 0.2) inset;
}
.MainWithImage_BtnDashboard {
    background: var(--focuscardbackground);
    padding: 0.5rem;
    width: 12.5rem;
    border-bottom-right-radius: 1rem;
    cursor: pointer;
    opacity: 0.8;
    transition: var(--css-duration);
    letter-spacing: 2px;
}
.MainWithImage_BtnDashboard:hover {
    opacity: 1;
    box-shadow: 0 -40px 0 0 rgba(255, 255, 255, 0.2) inset;
}
/* right items */
.MainWithImage_SiteList {
    /* background: rgba(255, 255, 255, 0.3); */
    min-width: 40rem;
    margin: 2rem;
    padding: 1rem;
    border-radius: 1rem;
    /* box-shadow: 0px 3px 20px -5px rgba(0, 0, 0, 0.9); */

    color: white;

    display: flex;
    flex-direction: column;
    justify-content: start;

    overflow-y: auto;
    transition: var(--css-duration);
}
.MainWithImage_SiteItem {
    width: 100%;
    /* background: linear-gradient(to right bottom, rgb(0, 0, 0, 0.4), var(--focuscardbackground)); */
    border-radius: 0.5rem;
    /* padding: 1.5rem;
    padding-left: 1.5rem; */
    align-self: center;
    text-align: start;

    margin-bottom: 1rem;
    background-size: contain;
    font-size: 2rem;
    font-weight: 100;
    letter-spacing: 0.3rem;
    cursor: pointer;
    transition: var(--css-duration);

    display: flex;
    flex-direction: row;
    justify-content: space-between;    
    
    /* onload animate */
    animation: 1s ease-out fadeIn;
}
.MainWithImage_SiteItem:hover {
    /* background: linear-gradient(to right bottom, rgb(0, 0, 0, 0.4), var(--focuscardbackground)); */
    /* opacity: 1;
    letter-spacing: 0.5rem; */
}
@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

.MainWithImage_ListImage {
    background: linear-gradient(to bottom right, rgba(150, 150, 150, .3), rgba(0, 0, 0, 0.4));
    backdrop-filter: blur(19px);
    height: 100%;
    padding: 1.5rem;
    padding-left: 2rem;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    display: flex;
    transition: var(--css-duration);
    opacity: 0.8;
}
.MainWithImage_ListInner {
    /* background:linear-gradient(to right, var(--colortheme1), transparent); */
    background: rgba(255, 255, 255, 0.1);
    width: 100%;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding: 1.5rem;

    backdrop-filter: blur(25px);
    /* backdrop-filter: hue-rotate(120deg); */
    opacity: 0.9;
}


.currentPicked {
    box-shadow: -10rem 0px 0 0 var(--focuscardbackground) inset;
    /* opacity: 1; */
}

.nopermission {
    background: grey;
    cursor: auto;
    opacity: .6;
}
.nopermission:hover {
    box-shadow: 0 0 0 0 black;
    opacity: .7;
}