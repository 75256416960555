.Guage_maindiv
{
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.18), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.Guage_header
{
    width: 100%;
    padding: 0.5rem;
    background: var(--cardtitle);
    color: var(--divheadertext);

    border-radius: 0.3rem;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.18), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.Guage_header *
{
    margin-right: 0.8rem;
}

.Guage_maindiv
{
    width: 100%;
    height: 100%;

    /* background: linear-gradient(to right, rgba(255, 255, 255, 0.329), rgba(255, 255, 255, 0.425)); */
    background: var(--cardbodybackground);
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}


/* No Permission style */
.NoPerm2_main {
    width: 100%;
    height: calc(100%);

    background-image: url(../../../assets/images/BlueCity.jpg);
    background-position: center;
    background-size: cover;
    border-radius: .3rem;
}
.noPerm2_blur {
    backdrop-filter: blur(5px);
    background: rgb(0, 0, 0, .3);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: .3rem;
}
.NoPerm2_Txt {
    margin: auto;
    font-size: 1.5rem;
    color: white;
    letter-spacing: 3px;
}