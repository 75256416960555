.Toilet_admin_task {
    background: white;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    padding: 1rem;
    border-radius: 0.5rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.Toilet_admin_task_title {
    font-size: 1.5rem;
    position: absolute;
    text-align: end;
    letter-spacing: 2px;
    opacity: 0.4;
    width: calc(100% - 2rem);
}

.Toilet_admin_task .cardList {
    /* height: 33%; */
    display: flex;
    flex-direction: column;
    z-index: 1;
}
.Toilet_admin_task .cardList.tasklist_expanded {
    height: 100%;
}
.tasklist_hide {
    max-height: 3rem;
}
.Toilet_admin_task .cardhead {
    color: rgba(255, 255, 255, 0.8);
    width: 10rem;
    text-align: center;
    letter-spacing: 2px;
    font-size: 1rem;
    padding: 0.2rem;
    border-radius: 0.5rem 0.5rem 0 0;

    display: flex;
    justify-content: center;
    align-items: flex-end;

    cursor: pointer;
}

.Toilet_admin_task .cardList .innerlist {
    height: 100%;
    padding: 1rem;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    box-shadow: 0 1px 10px 0 rgba(0,0,0,0.2) inset;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;

    overflow-y: auto;
}
.Toilet_admin_task .MostImportant .innerlist,
.Toilet_admin_task .MostImportant .cardhead {
    background: rgb(227, 1, 1);
    color: white;
}
.Toilet_admin_task .Important .innerlist,
.Toilet_admin_task .Important .cardhead {
    background: rgb(246, 148, 90);
    color: white;
}
.Toilet_admin_task .Normal .innerlist,
.Toilet_admin_task .Normal .cardhead {
    background: rgb(240, 239, 239);
    color: rgba(0, 0, 0, 0.8);
}

.Toilet_admin_taskItem {
    padding: 1rem;
    min-width: 15rem;
    min-height: 3rem;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.4);
    background: linear-gradient(to right, purple 10px, white 10px);
    color: rgb(0, 0, 0, 0.8);
    border-radius: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Toilet_admin_taskItem .loc {
    font-size: 1.2rem;
    letter-spacing: 2px;
}
.Toilet_admin_taskItem .desc {
    font-size: 0.8rem;
    letter-spacing: 1px;
}