.Lift_cctvContainer
{
    background: rgba(255, 255, 255, 0.4);
    width: 100%;
    height: 100%;
    color: black;
    border-radius: 0.5rem;

    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.4);
}

.Lift_cctvPlayer
{
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    background: rgba(0, 0, 0, 1);
}

.Lift_cctvPlayerSample {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    /* background: url('./../../../assets/images/demoCCTV.png'); */
    /* background-image: url(./../../../../src/assets/images/floors//Lift/default.jpg); */
    background-size: cover;
    background-position: center;
}
.Lift_cctcPlayerSampleInner {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 1));
    /* backdrop-filter: blur(15px); */
    width: 100%;
    height: 100%;
    color: white;
    text-align: center;
    font-size: 2.5rem;
    /* padding-top: 6rem; */
    border-radius: 0.5rem;

    
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.LiftLogDisplay {
    color: white;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    text-align: start;

    max-width: 20rem;
    margin: auto;
    margin-top: 2rem;
}