.workOrderList{
    width: calc(100% - 3rem);
    height: calc(100% - 4rem);
    transition: all var(--css-duration);
    padding: 1rem;
    border-radius: .3rem;
    margin: auto auto 0;

    -webkit-animation: draglayoutOnload 0.5s ease-out;
    animation: draglayoutOnload 0.5s ease-out;
    display: flex;
    flex-direction: column;
}
.tableTitle{
    margin-top: 1.5rem;
    padding: .5rem;
    border-radius: .5rem;
    display: flex;
    flex-direction: row;
    background-color: rgb(224, 219, 213);
    flex-wrap: nowrap;
}

.ColdboxIndi_bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1rem;
}